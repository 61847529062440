<template class="main-container">
  <v-card>
    <v-img :src="imageUrl" contain></v-img>
  </v-card>
</template>

<script>
/* eslint-disable no-debugger */

import API from '../../api';

export default {
  name: 'EnlargedImage',
  props: {
    uuid: {
      type: [String],
    },
  },

  data: () => ({
    imageUrl: '',
  }),
  created: async function() {
    this.loadData(this.uuid);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData(to.params.uuid);
    next();
  },
  mounted() {
    //document.title = 'Chat with:  ' + this.username;
  },
  beforeDestroy() {},
  methods: {
    async loadData(uuid) {
      let res = await API.get('/file/load?uuid=' + encodeURI(uuid));
      if (!res) {
        return;
      }
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        this.error = null;
        this.imageUrl = res.data.file.url;
      }
    },
  },
};
</script>

<style>
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.avatarMember {
  width: 140px !important;
  height: 140px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
h1 {
  font-size: 15px;
  font-weight: 600;
  border-bottom: solid 2px #eee;
  padding-bottom: 4px;
  line-height: 22px;
}
</style>
