<template class="main-container">
  <v-container class="" max-width="1000">
    <v-row class="elevation-4">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive v-if="activeChat">
          <v-card flat class="d-flex flex-column">
            <v-card-title>
              <a :href="'/user/' + username">{{ name }}</a>
            </v-card-title>
            <hr />
            <v-card-text
              style="height:calc(100vh - 300px);overflow-y: scroll"
              id="container"
            >
              <template
                v-for="(msg, i) in messages"
                style="height:800px;overflow-y: scroll"
              >
                <div :class="{ 'd-flex flex-row-reverse': me(msg) }" :key="i">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-hover v-slot:default="{ hover }">
                        <div>
                          <v-badge
                            v-if="msg.new"
                            bordered
                            bottom
                            color="deep-purple accent-4"
                            dot
                            offset-x="10"
                            offset-y="10"
                          >
                            <v-chip
                              :color="me(msg) ? 'primary' : ''"
                              dark
                              style="height:auto;white-space: normal;"
                              class="pa-4 mb-2"
                              v-on="on"
                            >
                              <v-img
                                v-if="msg.thumbnailUrl"
                                :src="msg.thumbnailUrl"
                                @click.stop="loadImage(msg.fileUuid)"
                              />
                              {{ msg.text }}
                              <sub class="ml-2" style="font-size: 0.5rem;">{{
                                formatDate(msg.createdAt)
                              }}</sub>
                              <v-icon v-if="hover" small>
                                mdi-expand_more
                              </v-icon>
                            </v-chip>
                          </v-badge>
                          <v-chip
                            v-if="!msg.new"
                            :color="me(msg) ? 'primary' : ''"
                            dark
                            style="height:auto;white-space: normal;"
                            class="pa-4 mb-2"
                            v-on="on"
                          >
                            <v-img
                              v-if="msg.thumbnailUrl"
                              :src="msg.thumbnailUrl"
                              @click.stop="loadImage(msg.fileUuid)"
                            />
                            {{ msg.text }}
                            <sub class="ml-2" style="font-size: 0.5rem;">{{
                              formatDate(msg.createdAt)
                            }}</sub>
                            <v-icon v-if="hover" small>
                              mdi-expand_more
                            </v-icon>
                          </v-chip>
                        </div>
                      </v-hover>
                    </template>
                    <v-list v-if="me(msg)">
                      <v-list-item @click="deleteMessageOrBlockUser(msg)">
                        <v-list-item-title>{{
                          me(msg) ? 'Delete message' : 'Block user'
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>
              <v-alert v-if="error" type="error">
                {{ error }}
              </v-alert>
            </v-card-text>
            <v-card-text class="flex-shrink-1">
              <v-text-field
                v-model="message"
                label="message"
                type="text"
                no-details
                outlined
                append-icon="mdi-send"
                append-outer-icon="mdi-image"
                @keyup.enter="sendMessage()"
                @click:append="sendMessage()"
                @click:append-outer="sendImage()"
                hide-details
              />
            </v-card-text>
            <v-dialog v-if="dialog" v-model="dialog" max-width="400">
              <EnlargedImage
                v-if="dialog"
                :uuid="currentImageUuid"
                @close="(dialog = false), (currentImageUuid = null)"
            /></v-dialog>
          </v-card>
          <input
            ref="backgroundImage"
            type="file"
            style="display: none;"
            accept="image/*"
            @change="onBackgroundImagePicked"
          />
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */

import API from '../../api';
import moment from 'moment';
import Socket from '@/sockets/socket';
import { formatName } from '@/util/stringUtils';
import axios from 'axios';
import EnlargedImage from '../image/EnlargedImage';
import notificationStore from '@/sockets/notificationStore';
import loginStore from '@/util/loginStore';

export default {
  name: 'Profile',
  props: {
    username: {
      type: [String],
    },
  },
  components: {
    EnlargedImage,
  },

  data: () => ({
    activeChat: 1,
    messages: [],
    message: '',
    name: '',
    error: '',
    userId: null,
    listener: null,
    chatId: null,
    imageName: '',
    imageFile: '',
    imageUrl: '',
    dialog: false,
    currentImageUuid: '',
  }),
  created: async function() {
    if (this.listener) {
      Socket.removeListener(this.listener);
    }
    this.listener = (type, payload) => {
      if (type === Socket.CHAT) {
        if (payload.chatId == this.chatId) {
          this.messages.push(payload);
          payload.new = true;
          Socket.sendSocketNotification(Socket.NOTIFICATIONS_READ, {
            parentObjectId: this.chatId,
            notificationType: Socket.CHAT,
          });
          setTimeout(() => {
            var container = this.$el.querySelector('#container');
            container.scrollTop = container.scrollHeight;
          }, 100);
        }
      }
      if (type === Socket.CHAT_MESSAGE_DELETE) {
        this.deleteMessage(payload.uuid);
      }
    };
    Socket.addListener(this.listener);

    this.loadData(this.username);
    this.userId = loginStore.userId;
    if (this.userId) {
      this.userId = parseInt(this.userId);
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData(to.params.username);
    next();
  },
  mounted() {
    document.title = 'Chat with:  ' + this.username;
  },
  beforeDestroy() {
    if (this.listener) {
      Socket.removeListener(this.listener);
    }
  },
  methods: {
    async loadData(username) {
      let res = await API.get('chat?username=' + encodeURI(username));
      if (!res) {
        return;
      }
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        this.error = null;
        this.name = formatName(res.data.name);
        this.chatId = res.data.chatId;
        let notificationKeys = {};
        for (
          let index = 0;
          index < notificationStore.notifications.length;
          index++
        ) {
          const notification = notificationStore.notifications[index];
          if (notification.notificationType == 'chat') {
            notificationKeys[notification.objectId] = true;
          }
        }
        this.messages = res.data.messages;
        for (let index = 0; index < this.messages.length; index++) {
          const message = this.messages[index];
          if (notificationKeys[message.id]) {
            message.new = true;
          }
        }
        Socket.sendSocketNotification(Socket.NOTIFICATIONS_READ, {
          parentObjectId: this.chatId,
          notificationType: Socket.CHAT,
        });
        setTimeout(() => {
          var container = this.$el.querySelector('#container');
          container.scrollTop = container.scrollHeight;
        }, 100);
      }
    },
    me(message) {
      return message.userId === this.userId;
    },
    onBackgroundImagePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageFile = files[0];
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf('.') <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load', async () => {
          this.imageUrl = fr.result;
          this.imageFile = files[0]; // this is an image file that can be sent to server...

          const formData = new FormData();
          formData.append('file', this.imageFile, this.imageFile.name);
          formData.set('fileType', 'chat');
          // the request itself
          let image = await axios({
            method: 'post',
            url: `${API.backendUrl}/file/upload`,
            data: formData,
          });
          if (image.data && image.data.data) {
            await this.sendPhotoMessage(image.data.data);
          }
          this.imageName = '';
          this.imageFile = '';
          this.imageUrl = '';
        });
      } else {
        this.imageName = '';
        this.imageFile = '';
        this.imageUrl = '';
      }
    },
    async sendPhotoMessage(image) {
      let res = await API.post('chat', {
        username: this.username,
        fileUuid: image.uuid,
        thumbnailUrl: image.thumbnailUrl,
      });
      if (!res) {
        return;
      }
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        this.error = null;
        this.messages.push({
          text: this.message,
          userId: this.userId,
          fileUuid: image.uuid,
          uuid: res.data.uuid,
          thumbnailUrl: image.thumbnailUrl,
          createdAt: new Date(),
        });
        setTimeout(() => {
          var container = this.$el.querySelector('#container');
          container.scrollTop = container.scrollHeight;
        }, 100);
        this.message = '';
      }
    },
    async deleteMessageOrBlockUser(msg) {
      let res = await API.delete('chat', { uuid: msg.uuid });

      if (!res) {
        return;
      }
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        this.deleteMessage(msg.uuid);
      }
    },
    async sendMessage() {
      if (this.message != '') {
        let res = await API.post('chat', {
          username: this.username,
          text: this.message,
        });
        if (!res) {
          return;
        }
        if (res.errors && res.errors.length > 0) {
          this.error = res.errors[0].msg;
        } else {
          this.error = null;
          this.messages.push({
            text: this.message,
            userId: this.userId,
            uuid: res.data.uuid,
            createdAt: new Date(),
          });
          setTimeout(() => {
            var container = this.$el.querySelector('#container');
            container.scrollTop = container.scrollHeight;
          }, 100);
          this.message = '';
        }
      }
    },
    async deleteMessage(messageUuid) {
      let messages = [];
      for (let index = 0; index < this.messages.length; index++) {
        const message = this.messages[index];
        if (message.uuid !== messageUuid) {
          messages.push(message);
        }
      }
      this.messages = messages;
    },
    async sendImage() {
      this.$refs.backgroundImage.click();
    },
    formatDate(date) {
      return moment(date).format('LLL');
    },
    loadImage(uuid) {
      this.currentImageUuid = uuid;
      this.dialog = true;
    },
  },
};
</script>

<style>
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.avatarMember {
  width: 140px !important;
  height: 140px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
h1 {
  font-size: 15px;
  font-weight: 600;
  border-bottom: solid 2px #eee;
  padding-bottom: 4px;
  line-height: 22px;
}
</style>
