<template>
  <v-container>
    <v-row>
      <v-col>
        <v-tabs show-arrows v-model="tab">
          <v-tab to="/admin/users">
            Users
          </v-tab>
          <v-tab to="/admin/products">
            Products
          </v-tab>
          <v-tab to="/admin/categories">
            Categories
          </v-tab>
          <v-tab to="/admin/tags">
            Tags
          </v-tab>
          <v-tab to="/admin/questions">
            Questions
          </v-tab>
          <v-tab to="/admin/subscription-types">
            Subscription types
          </v-tab>
        </v-tabs>
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// Import Vue FilePond

/* eslint-disable no-debugger */

/* eslint-disable-next-line  */

export default {
  name: 'AdminDashboard',
  props: {},

  data: () => ({
    tab: '',
  }),
  //LOAD
};
</script>
<style scoped>
.v-carousel .v-window-item {
  position: absolute;
  top: 0;
  width: 100%;
}

.v-carousel-item {
  height: auto;
}
</style>
