export default {
  EDIT_YOUR_INFORMATION: 0,
  ABOUT_YOUR_PRACTICE: 1,
  FOR_IN_PERSON_MEETINGS: 2,
  SOCIAL_MEDIA: 3,
  PROOF_OF_CERTIFICSATION: 4,
  APPOINTMENT_INFORMATION: 5,
  CATEGORIES_AND_TAGS: 6,
  MEMBER: 7,
};
