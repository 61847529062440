<template>
  <div>
    <v-card class="mx-auto">
      <v-card-text>
        <v-btn color="success" class="mr-4" @click="createTag()">
          Create
        </v-btn>
        <v-list three-line>
          <template v-for="item in info.tags">
            <v-list-item :key="item.id" :to="'/admin/tag/' + item.id">
              <v-list-item-content>
                <v-list-item-title v-html="item.text"></v-list-item-title>
                <v-list-item-subtitle>Id: {{ item.id }}</v-list-item-subtitle>
                <v-list-item-subtitle
                  >Hidden: {{ item.hidden }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-html="item.priority"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-html="tagCategoriesText(item)"
                ></v-list-item-subtitle>
                <div
                  v-for="(object, index) in answersForTag(item)"
                  :key="'aaa' + index"
                >
                  {{ object.question.title }}:{{ object.answer.text }}
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// Import Vue FilePond

/* eslint-disable no-debugger */

import API from '../../api';

/* eslint-disable-next-line  */

export default {
  name: 'Profile',
  props: {},

  data: () => ({
    error: null,
    info: [],
    tags: {},
    categories: {},
    questions: [],
    tab: '',
  }),
  mounted() {
    document.title = 'Admin Tags';
  },
  //LOAD
  created: async function() {
    this.error = null;
    let res = await API.get('admin/info');
    if (!res) {
      return;
    }
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      this.info = res.data.info;
      for (let index = 0; index < res.data.info.tags.length; index++) {
        const tag = res.data.info.tags[index];
        this.tags[tag.id] = tag;
      }
      for (let index = 0; index < res.data.info.categories.length; index++) {
        const category = res.data.info.categories[index];
        this.categories[category.id] = category;
      }
      this.questions = res.data.info.questions;
    }
  },
  computed: {},
  methods: {
    tagCategoriesText(tag) {
      let res = tag.TagCategories;
      if (!res) {
        return '';
      }

      res = res.map((category) =>
        this.categories[category.categoryId]
          ? this.categories[category.categoryId].name
          : ''
      );
      return res.join(', ');
    },
    answersForTag(tag) {
      let res = [];

      for (let index = 0; index < this.questions.length; index++) {
        const question = this.questions[index];
        for (let index2 = 0; index2 < question.answers.length; index2++) {
          const answer = question.answers[index2];
          if (answer.tag.includes(tag.id)) {
            res.push({ question, answer });
          }
        }
      }
      return res;
      /*      let res = question.tag;
      if (!res) {
        return '';
      }

      res = res.map((tag) => (this.tags[tag] ? this.tags[tag].text : ''));
      return res.join(', ');
*/
    },
    createTag() {
      this.$router.push('/admin/tag/new');
    },
    createQuestion() {
      this.$router.push('/admin/question/new');
    },
  },
};
</script>

<style></style>
