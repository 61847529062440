<template>
  <v-card outlined style="border-color:green" v-if="users && users.length > 0">
    <v-card-text class="text-h5 pb-0">
      <v-btn outlined rounded color="primary" dark>{{ category.name }}</v-btn>
      <v-spacer></v-spacer>
    </v-card-text>

    <v-list light>
      <template v-for="(item, index) in limitedUsers()">
        <v-list-item :key="item.username" :to="'user/' + item.username">
          <v-list-item-content style="border-color:green">
            <v-card outlined style="border-color:green">
              <div class="d-flex flex-no-wrap main-profession">
                <div style="display: flex; width: 100%">
                  <div>
                    <v-avatar
                      class="ma-2 pb-0"
                      :size="avatarSize"
                      style="border: 1px solid;"
                    >
                      <v-icon x-large v-if="!item.profileImageUrl">
                        mdi-account-circle
                      </v-icon>
                      <v-img
                        v-if="item.profileImageUrl"
                        :src="item.profileImageUrl"
                      >
                      </v-img>
                    </v-avatar>
                  </div>
                  <div style="width: 100%">
                    <v-card-text class="pl-0 pb-0">
                      <div class="main-profession">
                        <div style="width: 100%">
                          <div
                            style="margin-right: 30px; display: flex; justify-content: space-between;; width: 100%"
                          >
                            <div style="display: flex">
                              <b>
                                {{ shorterString(formatName(item.name)) }}
                              </b>
                            </div>
                            <b
                              v-if="item.priority"
                              :style="{
                                'padding-left': '16px',
                                color: percentColor(item),
                                display: 'flex',
                              }"
                            >
                              {{ percents(item) }}%
                            </b>
                          </div>
                          <div v-html="shorterString(occupation(item))"></div>
                          <i
                            v-html="shorterString(approvedTagsByCategory(item))"
                          ></i>
                          <!--div v-html="priceLabel(item)"></div-->
                          <v-rating
                            v-if="item.ratingFloat"
                            dense
                            v-model="item.ratingFloat"
                            readonly
                            half-increments
                            empty-icon="$vuetify.icons.prayerGrayscalled"
                            full-icon="$vuetify.icons.prayer"
                            half-icon="$vuetify.icons.prayerHalfGrayscalled"
                          >
                          </v-rating>
                          <div><a>click for full profile</a></div>
                        </div>
                      </div>
                    </v-card-text>
                  </div>
                </div>
                <!--div style="padding: 15px; line-height: 1.5;">
                  <div style="font-weight: bold; font-size: 16px">
                    Profession appears here:
                  </div>
                  <div style="display: inline">
                    <span style="margin-right: 20px; font-weight: bold">
                      I Specialise in:
                    </span>
                    <span>Goes here</span>
                  </div>
                  <div>Three tags</div>
                </div-->
              </div>
            </v-card>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="index < limitedUsers().length - 1"
          :key="index"
        ></v-divider>
      </template>
    </v-list>

    <v-card-text class="text-h5 pt-0">
      <v-btn
        outlined
        rounded
        color="primary"
        dark
        @click="show = !show"
        v-if="!show && users && users.length > 0"
        ><span style="font-size: 12px">
          See more match results in this category
        </span>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable no-debugger */
import { formatName } from '@/util/stringUtils';
import { shorterString } from '@/util/stringUtils';

export default {
  name: 'Profile',
  props: {
    users: {
      type: Array,
    },
    category: {
      type: Object,
    },
    tags: {
      type: Array,
    },
    maxPriority: {
      type: Number,
    },
  },
  data: () => ({
    //    tags: [],
    error: null,
    show: false,
  }),
  computed: {
    avatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 80;
        default:
          return 125;
      }
    },
  },
  async created() {},
  methods: {
    occupation(user) {
      if (user.categoryTitle) {
        return user.categoryTitle[this.category.id + ''];
      } else {
        return null;
      }
    },
    priceLabel(user) {
      let res = '';
      if (user.appointmentRate) {
        res = '$' + user.appointmentRate + ' per session';
      }
      if (user.typesOfPaymentsAccepted) {
        if (res == '') {
          res = user.typesOfPaymentsAccepted.join(', ');
        } else {
          res = res + ' ' + user.typesOfPaymentsAccepted.join(', ');
        }
      }
      return res;
    },
    specialization(user) {
      if (user.specialization) {
        let specialization = user.specialization[this.category.id + ''];
        if (specialization) {
          return specialization;
        } else {
          return user.mainPractice;
        }
      } else {
        return null;
      }
    },
    shorterString(string) {
      let length = 120;
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          length = 60;
          break;
        case 'sm':
          length = 80;
          break;
        case 'md':
          length = 120;
          break;
        case 'lg':
          length = 120;
          break;
        case 'xl':
          length = 120;
          break;
      }
      return shorterString(string, length);
    },
    formatName(name) {
      return formatName(name);
    },
    limitedUsers() {
      if (this.show) {
        return this.users;
      } else {
        let res = [];
        if (this.users && this.users.length > 0) {
          res.push(this.users[0]);
        }
        if (this.users && this.users.length > 1) {
          res.push(this.users[1]);
        }
        if (this.users && this.users.length > 2) {
          res.push(this.users[2]);
        }
        if (this.users && this.users.length > 3) {
          res.push(this.users[3]);
        }
        if (this.users && this.users.length > 4) {
          res.push(this.users[4]);
        }
        return res;
      }
    },
    approvedTagsByCategory(user) {
      if (user.selectedGoalTags) {
        return user.selectedGoalTags[this.category.id + ''];
      } else {
        return null;
      }
    },
    percents(item) {
      if (!this.maxPriority) {
        return '';
      }
      return Math.round((100 * item.priority) / this.maxPriority);
    },
    percentColor(item) {
      let perc = this.percents(item);
      if (perc <= 64) {
        return '#280DF9';
      }
      if (perc <= 79) {
        return '#E69138';
      }
      return '#CC0614';
    },
  },
};
</script>

<style>
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.v-text-field >>> button {
  font-size: 0.8em;
}
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
.profession {
  display: flex;
  flex-direction: column;
  padding: 15px;
  line-height: 1.5;
}
.main-profession {
  display: flex;
}
@media (max-width: 700px) {
  .main-profession {
    flex-direction: column !important;
  }
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
