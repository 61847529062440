<template class="main-container">
  <v-container>
    <v-card class="main-container">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12">
            <v-card flat>
              <v-card-text>
                <h2 class="ml-3">
                  {{
                    formatted(appointment.start) +
                      '-' +
                      formatted2(appointment.end)
                  }}
                </h2>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card
              class="mx-auto"
              max-width="1000"
              v-if="appointment && appointment.Member"
            >
              <v-card-title>
                Referral card
              </v-card-title>
              <v-card-text>
                <div>
                  <h2 class="ml-3">
                    {{ formatName(appointment.Member.name) }}
                  </h2>
                  <p class="ml-3">
                    {{ appointment.Member.description }}
                  </p>
                </div>
              </v-card-text>
              <v-card-text>
                <div
                  v-if="
                    appointment &&
                      appointment.Member &&
                      appointment.Member.other &&
                      appointment.Member.other.questionsAnswers &&
                      appointment.Member.other.questionsAnswers.length > 0
                  "
                >
                  <h1>
                    Goals:
                  </h1>
                  <br />
                  <div
                    v-for="(item, index) in questionsAnswersPrepared"
                    :key="'qa' + index"
                  >
                    <b>{{ item.question.title }} </b> <br />
                    <div
                      v-for="(item, index) in item.answers"
                      :key="'dd' + index"
                    >
                      {{ item }}
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
                <div
                  v-if="
                    appointment &&
                      appointment.Member &&
                      appointment.Member.personalInfo &&
                      appointment.Member.personalInfo.age
                  "
                >
                  <h1>Age</h1>
                  <br />
                  <div>{{ appointment.Member.personalInfo.age }}</div>
                  <br />
                  <br />
                </div>
                <div
                  v-if="
                    appointment &&
                      appointment.Member &&
                      appointment.Member &&
                      appointment.Member.myGender
                  "
                >
                  <h1>Gender</h1>
                  <br />
                  <div>{{ appointment.Member.myGender }}</div>
                  <br />
                  <br />
                </div>
                <div>
                  <h1>Email</h1>
                  <br />
                  <div>
                    {{ appointment.Member.email }}
                  </div>
                  <br />
                  <br />
                </div>
                <div
                  v-if="
                    appointment &&
                      appointment.Member &&
                      appointment.Member.personalInfo &&
                      appointment.Member.personalInfo.issuesAndConcerns
                  "
                >
                  <h1>Issues and Concerns</h1>
                  <br />
                  <div>
                    {{ appointment.Member.personalInfo.issuesAndConcerns }}
                  </div>
                  <br />
                  <br />
                </div>

                <div>
                  <h1>Insurance</h1>
                  <br />
                  <div>
                    {{ appointment.Member.insuranceAccepted }}
                  </div>
                  <br />
                  <br />
                </div>

                <div
                  v-if="
                    appointment &&
                      appointment.Member &&
                      appointment.Member.personalInfo
                  "
                >
                  <h1>Free 15 call:</h1>
                  <br />
                  <div>
                    {{
                      appointment.Member.personalInfo.expectedFree15
                        ? 'Yes'
                        : 'No'
                    }}
                  </div>
                  <br />
                  <br />
                </div>

                <div
                  v-if="
                    appointment &&
                      appointment.Member &&
                      appointment.Member.personalInfo
                  "
                >
                  <h1>Weekend Service:</h1>
                  <br />
                  <div>
                    {{
                      appointment.Member.personalInfo.expectedWorkWeekends
                        ? 'Yes'
                        : 'No'
                    }}
                  </div>
                  <br />
                  <br />
                </div>
                <v-btn
                  class="mr-4 main-btn success-green"
                  text
                  v-if="appointment.Member.username != getUsername()"
                  @click="approveAppointment()"
                  :to="
                    appointment.Member.username == getUsername()
                      ? '/chat/' + appointment.Professional.username
                      : '/chat/' + appointment.Member.username
                  "
                >
                  Send a message
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card
              class="mx-auto"
              max-width="1000"
              v-if="appointment && appointment.Professional"
            >
              <v-card-title>
                Professional
              </v-card-title>
              <v-card-text>
                <a :href="'/user/' + appointment.Professional.username">
                  <div>
                    <h2 class="ml-3">
                      {{ formatName(appointment.Professional.name) }}
                      {{
                        appointment.Professional.other &&
                        appointment.Professional.other.pni
                          ? appointment.Professional.other.pni + ' '
                          : ''
                      }}
                    </h2>
                    <p class="ml-3">
                      {{ appointment.Professional.description }}
                    </p>
                  </div>
                  <v-avatar size="140" class="ml-3">
                    <img :src="appointment.Professional.profileImageUrl" />
                  </v-avatar>
                </a>
                <div>
                  <v-btn
                    class="mr-4 main-btn success-green"
                    text
                    v-if="appointment.Member.username == getUsername()"
                    @click="approveAppointment()"
                    :to="
                      appointment.Member.username == getUsername()
                        ? '/chat/' + appointment.Professional.username
                        : '/chat/' + appointment.Member.username
                    "
                  >
                    Send a message
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" v-if="review && review.rating">
            <v-card class="mx-auto" max-width="1000">
              <v-card-title>
                Review
              </v-card-title>
              <v-card-text>
                {{ review.text }}
                <v-rating v-model="review.rating" readonly>
                  <template v-slot:item="props">
                    <v-icon @click="props.click">
                      {{
                        props.isFilled
                          ? '$vuetify.icons.prayer'
                          : '$vuetify.icons.prayerGrayscalled'
                      }}</v-icon
                    >
                  </template>
                </v-rating>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-alert v-if="error" type="error">
          {{ error }}
        </v-alert>
        <v-card-text>
          <h2 class="ml-3">status: {{ statusText() }}</h2>
        </v-card-text>

        <v-card-text>
          <v-btn
            v-if="canBeApproved()"
            class="mr-4 main-btn success-green"
            text
            @click="approveAppointment()"
          >
            Confirm Appointment
          </v-btn>

          <v-btn
            v-if="canBeApproved()"
            class="mr-4 main-btn"
            outlined
            color="gray"
            text
            @click="rejectAppointment()"
          >
            Reject Appointment
          </v-btn>

          <v-btn
            v-if="canBeRescheduled()"
            class="mr-4 main-btn success-green"
            text
            :to="'/appointment/reschedule/' + appointment.uuid"
          >
            Reschedule Appointment
          </v-btn>

          <v-btn
            v-if="canReview()"
            class="mr-4 main-btn success-green"
            text
            @click="reviewAppointment()"
          >
            Rate
          </v-btn>

          <v-btn
            v-if="canBeCanceled()"
            class="mr-4 main-btn"
            outlined
            color="gray"
            text
            @click="cancelAppointment()"
          >
            Cancel Appointment
          </v-btn>
        </v-card-text>
      </v-container>
    </v-card>
    <v-dialog v-if="reviewDialog" v-model="reviewDialog" max-width="400">
      <review
        @close="closeReview"
        :appointmentUuid="appointment.uuid"
        :review="review"
      />
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */

import API from '../../api';
import moment from 'moment';
import { formatName } from '@/util/stringUtils';
import Review from '../review/Review.vue';
import loginStore from '@/util/loginStore';
import questionsStore from '@/components/member/questionsStore';

export default {
  components: { Review },
  name: 'Appointment',
  props: {
    appointmentUuid: {
      type: [String],
    },
  },
  data: () => ({
    //    tags: [],
    error: null,
    appointment: {},
    reviewDialog: false,
    review: {},
    questionsAnswersPrepared: [],
  }),
  created: async function() {
    await questionsStore.created();

    if (this.$route.query.rate) {
      this.reviewDialog = true;
    }
    this.loadData(this.appointmentUuid);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData(to.params.appointmentUuid);
    next();
  },
  mounted() {
    document.title = 'Appointment';
  },
  methods: {
    formatName(name) {
      return formatName(name);
    },
    async loadData(appointmentUuid) {
      let res = await API.get(
        'appointment' + '?appointmentUuid=' + encodeURI(appointmentUuid)
      );
      if (!res) {
        return;
      }
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        this.error = null;
        this.appointment = res.data.appointment;
        this.review = res.data.review;
      }
      if (
        this.appointment &&
        this.appointment.Member &&
        this.appointment.Member.other &&
        this.appointment.Member.other.questionsAnswers
      ) {
        this.questionsAnswersPrepared = await questionsStore.questionsAnswersPrepared(
          this.appointment.Member.other.questionsAnswers
        );
      } else {
        this.questionsAnswersPrepared = [];
      }
    },
    statusText() {
      if (this.appointment) {
        if (this.appointment.status == 'Pending') {
          return 'Pending professional approval';
        }
        if (this.appointment.status == 'Rejected') {
          return 'Rejected by professional';
        }
        if (this.appointment.status == 'Scheduled') {
          return 'Confirmed by professional';
        }
        if (this.appointment.status == 'Cancelled') {
          return 'Canceled';
        }
        if (this.appointment.status == 'Finished') {
          return 'Finished';
        }
      }
      return '';
    },
    canBeApproved() {
      return (
        this.appointment &&
        this.appointment.status == 'Pending' &&
        this.appointment.Professional &&
        this.appointment.Professional.username == loginStore.username
      );
    },
    canReview() {
      return (
        this.appointment &&
        this.appointment.status == 'Finished' &&
        this.appointment.Member &&
        this.appointment.Member.username == loginStore.username
      );
    },
    canBeRescheduled() {
      return false;
      /*      return (
        this.appointment &&
        this.appointment.status == 'Scheduled' &&
        this.appointment.Professional &&
        this.appointment.Professional.username == loginStore.username
      );*/
    },
    canBeCanceled() {
      return (
        this.appointment &&
        this.appointment.status == 'Scheduled' &&
        ((this.appointment.Professional &&
          this.appointment.Professional.username == loginStore.username) ||
          (this.appointment.Member &&
            this.appointment.Member.username == loginStore.username))
      );
    },
    canFinish() {
      return (
        this.appointment &&
        this.appointment.status == 'Scheduled' &&
        this.appointment.Professional &&
        this.appointment.Professional.username == loginStore.username
      );
    },
    async rescheduleAppointment() {
      let res = await API.post('appointment/approve', {
        appointmentUuid: this.appointmentUuid,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        return;
      }
      this.loadData(this.appointmentUuid);
      this.dialog = false;
    },
    async approveAppointment() {
      let res = await API.post('appointment/approve', {
        appointmentUuid: this.appointmentUuid,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        return;
      }
      this.loadData(this.appointmentUuid);
      this.dialog = false;
    },
    async rejectAppointment() {
      let res = await API.post('appointment/reject', {
        appointmentUuid: this.appointmentUuid,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        return;
      }
      this.loadData(this.appointmentUuid);
      this.dialog = false;
    },
    async reviewAppointment() {
      this.reviewDialog = true;
    },
    async finishAppointment() {
      let res = await API.post('appointment/finish', {
        appointmentUuid: this.appointmentUuid,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        return;
      }
      this.loadData(this.appointmentUuid);
      this.dialog = false;
    },
    async cancelAppointment() {
      let res = await API.post('appointment/cancel', {
        appointmentUuid: this.appointmentUuid,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        return;
      }
      this.loadData(this.appointmentUuid);
      this.dialog = false;
    },
    getUsername() {
      return loginStore.username;
    },
    formatted(date) {
      return moment(date).format('LLL');
    },
    formatted2(date) {
      return moment(date).format('LT');
    },
    closeReview() {
      this.reviewDialog = false;
      this.loadData(this.appointmentUuid);
    },
  },
  computed: {},
};
</script>

<style>
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.avatarMember {
  width: 140px !important;
  height: 140px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
h1 {
  font-size: 15px;
  font-weight: 600;
  border-bottom: solid 2px #eee;
  padding-bottom: 4px;
  line-height: 22px;
}
</style>
