<template>
  <div>
    <v-dialog v-model="photoSelectedDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> </v-card-title>

        <v-card-text>
          You need to upload your profile photo to be able to select category
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="photoSelectedDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="specialityDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> </v-card-title>

        <v-card-text>
          Please specify occupations and goals you help people achive for all
          requested categories
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="specialityDialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template v-if="currentSection == sections().EDIT_YOUR_INFORMATION">
      <v-row align="center" class="spacer" no-gutters>
        <v-col xs="12" sm="12" md="12" cols="12">
          <v-img
            @click="pickBackgroundFile"
            class="d-flex align-center"
            :src="imageUrl ? imageUrl : '/cover-image.png'"
          >
            <v-card-title class="d-flex justify-center img">
              <div
                style="display: flex; flex-direction: column; align-items: center"
              >
                <div>
                  <v-avatar>
                    <!--v-icon class="outlined" x-large> mdi-camera</v-icon-->
                    <v-icon class="outlined" x-large>mdi-camera-image</v-icon>
                  </v-avatar>
                </div>
                <div class="text-image">
                  Change your cover photo
                </div>
              </div>
            </v-card-title>
          </v-img>
          <input
            ref="backgroundImage"
            type="file"
            style="display: none;"
            accept="image/*"
            @change="onBackgroundImagePicked"
          />
        </v-col>
        <v-col xs="12" sm="4" md="3" cols="2">
          <div style="position:relative;">
            <v-avatar
              style="position:relative"
              align="center"
              :class="profile.isProfessional ? 'avatar' : 'avatar2'"
              size="140"
            >
              <img
                :src="
                  profileImageUrl ? profileImageUrl : '/profile-picture.png'
                "
              />
            </v-avatar>
            <v-avatar
              @click="pickProfileFile"
              style="position:absolute; background: rgba(0,0,0,.5);"
              :class="profile.isProfessional ? 'avatar' : 'avatar2'"
              size="140"
            >
              <v-icon class="outlined" x-large> mdi-camera-image</v-icon>
            </v-avatar>
          </div>
          <input
            ref="profileImage"
            type="file"
            style="display: none;"
            accept="image/*"
            @change="onProfileImagePicked"
          />
        </v-col>
      </v-row>
      <div style="color:gray">
        <div>
          Add a <b class="bolder">Profile Picture</b> and a
          <span class="bolder">Cover Image</span>.
        </div>
        <div>
          Your Profile Picture should be a jpg 300px wide a square image works
          best.
        </div>
        <div>
          Your Cover Image should be a jpg at about 1000px wide by 400px high.
        </div>
        <br />
      </div>
      <div style="color:gray">
        <div>
          Your profile info will be available only to you and professional you
          scheduled appointment with.
        </div>
      </div>
    </template>
    <template v-if="currentSection == sections().PROOF_OF_CERTIFICATION">
      <br />
      <h1 class="title-text">
        Please upload your certification below.
      </h1>
      <br />
      <Proof />
    </template>
    <template v-if="currentSection == sections().ABOUT_YOUR_PRACTICE">
      <br />
      <h1 class="title-text">How many years have you provided this service?</h1>
      <v-textarea
        v-model="description"
        placeholder="Example: I have been a certified Professional for 11 years and I love it!"
        required
      ></v-textarea>
      <br />
      <br />

      <br />
      <h1 class="title-text">Post Nominal Initials</h1>
      <v-text-field
        v-model="pni"
        placeholder="MS, ATC, CSCS, NCPT"
      ></v-text-field>
      <br />
      <br />

      <h1 class="title-text">My Website</h1>
      <v-text-field v-model="myWebsite" placeholder="My Website"></v-text-field>
      <br />
      <br />

      <h1 class="title-text">Gender</h1>
      <v-select :items="genders()" v-model="myGender">
        <template v-slot:append>
          <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
        </template></v-select
      >
      <br />
      <br />

      <!--h1 class="title-text">Member's gender</h1>
      <v-select :items="genders()" v-model="expectedGender">
                <template v-slot:append>
          <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
        </template>
      </v-select>
      <br />
      <br /-->

      <h1 class="title-text">Insurance Accepted?</h1>
      <v-radio-group v-model="insuranceAccepted">
        <v-radio label="No" value="No"></v-radio>
        <v-radio label="Yes" value="Yes"></v-radio>
        <v-radio label="Any" value="Any"></v-radio>
      </v-radio-group>
      <br />
      <br />

      <div v-if="insuranceAccepted == 'Yes'">
        <h1 class="title-text">Insurance Carriers</h1>
        <v-textarea
          v-model="insuranceCarriers"
          placeholder="Insurance Carriers"
        ></v-textarea>
        <br />
        <br />
      </div>

      <h1 class="title-text">My Age Range</h1>
      <v-select
        v-model="ageRange"
        :items="ages()"
        item-text="name"
        item-value="abbreviation"
        placeholder="My Age Range"
      >
        <template v-slot:append>
          <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
        </template></v-select
      >
      <br />
      <br />
      <div style="color:gray">
        <div>
          Do you have an inspiring story about you or your practice? Can you
          tell about a time you really helped someone achieve a goal? What sort
          of information can you offer that would help people select you as a
          practitioner?
        </div>
      </div>
      <br />
      <h1 class="title-text">My Story</h1>
      <v-textarea
        v-model="myStory"
        placeholder="My Story"
        required
      ></v-textarea>
    </template>

    <template v-if="currentSection == sections().FOR_IN_PERSON_MEETINGS">
      <br />
      <h1 class="title-text">Country</h1>
      <v-autocomplete
        v-model="selectedCountry"
        :items="countries()"
        item-text="name"
        item-value="code"
        placeholder="Country"
      >
        <template v-slot:append>
          <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
        </template>
      </v-autocomplete>
      <br />
      <br />
      <div v-if="selectedCountry == 'US'">
        <h1 class="title-text">State</h1>
        <v-autocomplete
          v-model="selectedState"
          :items="states()"
          item-text="name"
          item-value="abbreviation"
          placeholder="State"
        >
          <template v-slot:append>
            <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
          </template>
        </v-autocomplete>
        <br />
        <br />
      </div>

      <h1 class="title-text">Zip Code</h1>
      <v-text-field
        v-model="zipcode"
        placeholder="Please enter your zip code"
      ></v-text-field>
      <br />
      <br />

      <h1 class="title-text">Address</h1>
      <v-textarea v-model="address" placeholder="Address"></v-textarea>
      <br />
      <br />
    </template>

    <template v-if="currentSection == sections().SOCIAL_MEDIA">
      <h1 class="title-text">Instagram</h1>
      <v-text-field
        v-model="instagramLink"
        placeholder="Instagram Link"
      ></v-text-field>
      <br />
      <br />
      <h1 class="title-text">Facebook</h1>
      <v-text-field
        v-model="facebookLink"
        placeholder="Facebook Link"
      ></v-text-field>
      <br />
      <br />
      <h1 class="title-text">Pinterest</h1>
      <v-text-field
        v-model="pinterestLink"
        placeholder="Pinterest Link"
      ></v-text-field>
      <br />
      <br />
      <h1 class="title-text">TikTok</h1>
      <v-text-field
        v-model="tikTokLink"
        placeholder="TikTok Link"
      ></v-text-field>
      <br />
      <br />
    </template>
    <template v-if="currentSection == sections().APPOINTMENT_INFORMATION">
      <div>
        <br />
        <h1 class="title-text">Preferred Location</h1>
        <v-autocomplete
          v-model="selectedPreferredLocations"
          :items="preferredLocations"
          chips
          item-text="text"
          item-value="id"
          multiple
        >
          <template v-slot:append>
            <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
          </template>
          <template v-slot:selection="data">
            <v-chip
              color="#428751"
              outlined
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="removePreferredLocation(data.item)"
            >
              {{ data.item.text }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title v-html="data.item.text"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <br />
        <br />

        <h1 class="title-text">Appointment Duration</h1>
        <v-autocomplete
          v-model="appointmentDuration"
          :items="appointmentDurations()"
          item-text="name"
          item-value="abbreviation"
          placeholder="Example: 1 Hour"
        >
          <template v-slot:append>
            <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
          </template></v-autocomplete
        >
        <br />
        <br />

        <h1 class="title-text">Appointment Rate</h1>
        <v-text-field
          v-model.number="appointmentRate"
          placeholder="Example: $65.00"
          prefix="$"
        ></v-text-field>
        <br />
        <br />
        <h1 class="title-text">Types of Payment Accepted</h1>
        <v-autocomplete
          class="custom-placeholer-color"
          v-model="selectedTypesOfPaymentsAccepted"
          :items="typesOfPaymentsAccepted"
          chips
          item-text="text"
          item-value="text"
          multiple
          placeholder="Venmo, PayPal, ApplePay, Square, Zelle"
        >
          <template v-slot:append>
            <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
          </template>
          <template v-slot:selection="data">
            <v-chip
              color="#428751"
              outlined
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
              @click:close="removeTypeOfPaymentAcccepted(data.item)"
            >
              {{ data.item.text }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title v-html="data.item.text"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <br />
      </div>
    </template>
    <template v-if="currentSection == sections().CATEGORIES_AND_TAGS">
      <v-list v-for="(category, index) in categories" :key="category.id" flat>
        <v-list-item-group multiple active-class="">
          <v-list-item>
            <Category
              :category="category"
              :tags="otherTags(category.id)"
              :goalTags="goalTags(category.id)"
              :paid="paid(category)"
              :expired="expired(category)"
              :validTill="validTill(category)"
              :oldSelectedTags="
                selectedTagsGoal(categoryInfos[category.id].selectedTags, false)
              "
              :oldSelectedGoalTags="
                selectedTagsGoal(categoryInfos[category.id].selectedTags, true)
              "
              @updateParent="updateParent"
              @subscribe="subscribe"
            />
          </v-list-item>
        </v-list-item-group>
        <v-divider
          v-if="index < categories.length - 1"
          :key="index"
        ></v-divider>
      </v-list>
    </template>
    <template v-if="currentSection == sections().MEMBER">
      <div>
        <br />
        <br />
        <h1 class="title-text">Age</h1>
        <v-textarea
          :readonly="!loggedIn"
          @click="signupAlert"
          v-model="age"
          placeholder="Age"
        ></v-textarea>
        <br />
        <br />
        <h1 class="title-text">Gender</h1>

        <v-select
          :readonly="!loggedIn"
          @click="signupAlert"
          :items="genders()"
          v-model="myGender"
        >
          <template v-slot:append>
            <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
          </template></v-select
        >
        <!--br />
        <br />
        <h1 class="title-text">Professional's Gender</h1>

        <v-select :items="genders()" v-model="expectedGender">
                  <template v-slot:append>
          <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
        </template>
        </v-select-->
        <br />
        <br />

        <h1 class="title-text">Insurance Accepted by Professional</h1>

        <v-radio-group
          :readonly="!loggedIn"
          @click="signupAlert"
          v-model="insuranceAccepted"
        >
          <v-radio label="No" value="No"></v-radio>
          <v-radio label="Yes" value="Yes"></v-radio>
          <v-radio label="Any" value="Any"></v-radio>
        </v-radio-group>
        <br />
        <br />

        <!--h1 class="title-text">Professional Age Range</h1>
        <v-select
          v-model="ageRange"
          :items="ages()"
          item-text="name"
          item-value="abbreviation"
          placeholder="My Age Range"
        >        <template v-slot:append>
          <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
        </template></v-select>
        <br />
        <br /!-->

        <h1 class="title-text">
          Are you interested in a Free 15 minute phone or video-chat with a
          professional before scheduling an appointment?
        </h1>
        <v-checkbox
          :readonly="!loggedIn"
          @click="signupAlert"
          v-model="free15"
        />
        <br />
        <br />

        <h1 class="title-text">
          Do you need a professional that offers their services on the weekends?
        </h1>
        <v-checkbox
          :readonly="!loggedIn"
          @click="signupAlert"
          v-model="workWeekends"
        />
        <br />
        <br />

        <h1 class="title-text">Preferred Location</h1>
        <v-autocomplete
          :readonly="!loggedIn"
          @click="signupAlert"
          v-model="selectedPreferredLocations"
          :items="preferredLocations"
          chips
          item-text=""
          item-value="id"
          multiple
        >
          <template v-slot:append>
            <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
          </template>
          <template v-slot:selection="data">
            <v-chip
              color="#428751"
              outlined
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="removePreferredLocation(data.item)"
            >
              {{ data.item.text }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title v-html="data.item.text"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <template v-if="addressNeeded()">
          <br />
          <br />
          <h1 class="title-text">
            Please type your address for the professional in the line below.
          </h1>
          <v-textarea
            :readonly="!loggedIn"
            @click="signupAlert"
            v-model="address"
            placeholder="Address"
          ></v-textarea>
        </template>
        <br />
        <br />
        <h1 class="title-text">
          Please type any issues or concerns you may have for the professional
          in the line below.
        </h1>
        <v-textarea
          :readonly="!loggedIn"
          @click="signupAlert"
          v-model="issuesAndConcerns"
          placeholder="Issues or Concerns"
        ></v-textarea>
      </div>
    </template>
    <v-card flat>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          v-if="showSave"
          class="mr-4 main-btn success-green"
          :disabled="!loggedIn"
          @click="saveProfile()"
        >
          Save
        </v-btn>
        <v-spacer></v-spacer> </v-card-actions
    ></v-card>
    <SubscribeDialog
      @dialogHidden="dialogHidden"
      v-if="showSubscribeDialog"
      :categoryIds="categoryIds()"
    />

    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
  </div>
</template>
<script>
import Proof from './Proof';
import Category from './Category';
import moment from 'moment';
import SubscribeDialog from './SubscribeDialog';

/* eslint-disable no-debugger */

import API from '../../api';
import statesJSON from '../../../data/states.json';
import countriesJSON from '../../../data/countries.json';
import agesJSON from '../../../data/ages.json';
import appointmentDurationsJSON from '../../../data/appointmentDurations.json'; //appointmentDurations
import axios from 'axios';

/* eslint-disable-next-line  */
import EditProfileSections from './EditProfileSections';
import loginStore from '../../util/loginStore';

export default {
  name: 'Profile',
  props: {
    section: {
      type: Number,
    },
  },
  components: {
    Proof,
    Category,
    SubscribeDialog,
    //Matching,
    //Tags,
  },

  data: () => ({
    subscribableValue: false,
    showSave: true,

    imageName: '',
    imageUrl: '',
    imageFile: '',
    currentSection: null,

    photoSelectedDialog: false,

    profileImageName: '',
    profileImageUrl: '',
    profileImageFile: '',
    showSubscribeDialog: false,
    specialityDialog: false,

    error: null,
    profile: {},
    tags: [],
    tab: 'profile',
    selectedTags: [],
    selectedPreferredLocations: [],
    selectedState: '',
    selectedCountry: '',
    categories: [],
    categoryInfos: {},
    images: [],
    hours: [],
    userCategoryByCategory: {},
    preferredLocations: [
      { text: "Professional's Location", id: 1 },
      { text: "Member's Location", id: 2 },
      { text: 'Web Conferencing', id: 3 },
      { text: 'Agreed Upon Location', id: 4 },
    ],
    typesOfPaymentsAccepted: [
      { text: 'Cash', id: 1 },
      { text: 'Checks', id: 2 },
      { text: 'Debit cards', id: 3 },
      { text: 'Credit cards', id: 4 },
      { text: 'Venmo', id: 4 },
      { text: 'PayPal', id: 4 },
      { text: 'ApplePay', id: 4 },
      { text: 'Square', id: 4 },
      { text: 'Zelle', id: 4 },
      { text: 'GooglePay', id: 4 },
      { text: 'Other Mobile Payment', id: 4 },
    ],
    selectedTypesOfPaymentsAccepted: [],
    state: null,
    zipcode: null,
    name: null,
    description: null,
    myWebsite: null,
    instagramLink: null,
    facebookLink: null,
    pinterestLink: null,
    tikTokLink: null,

    insuranceCarriers: '',
    myStory: '',
    mainPractice: '',
    secondaryPractices: '',
    pni: '',
    appointmentDuration: 3600,
    appointmentRate: 0,
    myGender: 'Any',
    expectedGender: 'Any',
    insuranceAccepted: 'Any',
    ageRange: 'Any',
    free15: false,
    workWeekends: false,

    issuesAndConcerns: '',
    age: null,
    address: '',
    goals: [],
    otherTagsCache: {},
    goalTagsCache: {},
    tagById: {},
  }),
  mounted() {
    document.title = 'Edit Profile';
  },

  created: async function() {
    this.currentSection = this.section;
    await this.loadGoals();
    this.error = null;
    let res = await API.get('profile?noAuth=true');
    if (!res) {
      return;
    }
    this.showSave =
      this.currentSection != this.sections().EDIT_YOUR_INFORMATION &&
      this.currentSection != this.sections().PROOF_OF_CERTIFICATION &&
      this.currentSection != this.sections().EDIT_YOUR_INFORMATION;
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      if (
        this.currentSection == this.sections().CATEGORIES_AND_TAGS &&
        !res.data.user.profileImageUrl
      ) {
        this.showSave = false;
        this.error =
          ' You need to upload your profile photo to be able to select category';
        return;
      }

      this.profile = res.data.user;
      if (!this.profile) {
        this.selectedPreferredLocations = [3];
        return;
      }
      this.tags = res.data.tags;
      this.tagById = {};
      for (let index = 0; index < this.tags.length; index++) {
        const tag = this.tags[index];
        this.tagById[tag.id] = tag;
      }
      this.categories = res.data.categories;
      this.name = this.profile.name;
      this.images = res.data.images;
      this.description = this.profile.description;
      this.selectedState = this.profile.state;
      this.selectedCountry = this.profile.country;
      this.zipcode = this.profile.zipcode;
      this.profileImageUrl = this.profile.profileImageUrl;
      this.imageUrl = this.profile.backgroundImageUrl;
      this.myGender = this.profile.myGender;
      this.expectedGender = this.profile.expectedGender;
      this.insuranceAccepted = this.profile.insuranceAccepted;
      this.ageRange = this.profile.ageRange;
      if (this.profile.other) {
        this.myWebsite = this.profile.other.myWebsite;

        this.instagramLink = this.profile.other.instagramLink;
        this.facebookLink = this.profile.other.facebookLink;
        this.pinterestLink = this.profile.other.pinterestLink;
        this.tikTokLink = this.profile.other.tikTokLink;

        this.myStory = this.profile.other.myStory;
        this.mainPractice = this.profile.other.mainPractice;
        this.secondaryPractices = this.profile.other.secondaryPractices;
        this.pni = this.profile.other.pni;
        this.insuranceCarriers = this.profile.other.insuranceCarriers;
        if (this.profile.other.appointmentDuration) {
          this.appointmentDuration = this.profile.other.appointmentDuration;
        }
        if (this.profile.other.appointmentRate) {
          this.appointmentRate = this.profile.other.appointmentRate;
        }
        if (this.profile.other.typesOfPaymentsAccepted) {
          this.selectedTypesOfPaymentsAccepted = this.profile.other.typesOfPaymentsAccepted;
        }

        this.address = this.profile.other.address;
      }
      if (this.profile.personalInfo) {
        this.issuesAndConcerns = this.profile.personalInfo.issuesAndConcerns;
        this.age = this.profile.personalInfo.age;
        this.address = this.profile.personalInfo.address;
        this.free15 = this.profile.personalInfo.expectedFree15;
        this.workWeekends = this.profile.personalInfo.expectedWorkWeekends;
      }
      //if ()dsffsf
      this.findTags();
      this.findSelectedPreferredLocations();
      this.fillUserCategoryByCategory();
      this.subscribableValue = this.subscribable();
    }
  },
  computed: {
    loggedIn() {
      return loginStore.loggedIn;
    },
  },
  methods: {
    selectedTagsGoal(selectedTags, goal) {
      let res = [];
      if (selectedTags) {
        for (let index = 0; index < selectedTags.length; index++) {
          const selectedTag = selectedTags[index];
          let tag = this.tagById[selectedTag];
          if (tag) {
            if (goal == tag.hidden) {
              res.push(selectedTag);
            }
          }
        }
      }
      return res;
    },
    //LOAD
    async loadGoals() {
      let res = await API.get('question?type=professional');
      if (res.data) {
        var questions = [res.data.question];
        for (let index = 0; index < questions.length; index++) {
          const element = questions[index];
          this.goals = element.answers;
          return;
        }
      }
      this.goals = [];
    },
    signupAlert() {
      this.$emit('signupAlert');
    },
    fillUserCategoryByCategory() {
      for (let index = 0; index < this.profile.UserCategories.length; index++) {
        const userCategory = this.profile.UserCategories[index];
        this.userCategoryByCategory[userCategory.categoryId] = userCategory;
      }
    },
    onBackgroundImagePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageFile = files[0];
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf('.') <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load', () => {
          this.imageUrl = fr.result;
          this.imageFile = files[0]; // this is an image file that can be sent to server...
          this.saveProfile(true);
        });
      } else {
        this.imageName = '';
        this.imageFile = '';
        this.imageUrl = '';
      }
    },
    pickBackgroundFile() {
      this.$refs.backgroundImage.click();
    },
    pickProfileFile() {
      this.$refs.profileImage.click();
    },
    onProfileImagePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.profileImageFile = files[0];
        this.profileImageName = files[0].name;
        if (this.profileImageName.lastIndexOf('.') <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load', () => {
          this.profileImageUrl = fr.result;
          this.profileImageFile = files[0]; // this is an image file that can be sent to server...
          this.saveProfile(true);
        });
      } else {
        this.profileImageName = '';
        this.profileImageFile = '';
        this.profileImageUrl = '';
      }
    },
    removeTag(item) {
      const index = this.selectedTags.indexOf(item.id);
      if (index >= 0) this.selectedTags.splice(index, 1);
    },
    removePreferredLocation(item) {
      const index = this.selectedPreferredLocations.indexOf(item.id);
      if (index >= 0) this.selectedPreferredLocations.splice(index, 1);
    },
    topTags() {
      return this.tags.filter((tag) => tag.isTop);
    },
    otherTags(categoryId) {
      if (this.otherTagsCache[categoryId]) {
        return this.otherTagsCache[categoryId];
      }
      let res = this.tags.filter((tag) => !tag.isTop && !tag.hidden);
      if (categoryId) {
        let res2 = [];
        for (let index = 0; index < res.length; index++) {
          const tag = res[index];
          let found = false;
          for (let index2 = 0; index2 < tag.TagCategories.length; index2++) {
            let tagCategory = tag.TagCategories[index2];
            if (tagCategory.categoryId === categoryId) {
              found = true;
              break;
            }
          }
          if (found) {
            res2.push(tag);
          }
        }
        res = res2;
        res.sort((a, b) => {
          return a.text.localeCompare(b.text);
        });
      }
      this.otherTagsCache[categoryId] = res;
      return res;
    },
    goalTags(categoryId) {
      if (this.goalTagsCache[categoryId]) {
        return this.goalTagsCache[categoryId];
      }
      var category;
      for (let index2 = 0; index2 < this.categories.length; index2++) {
        const currentCategory = this.categories[index2];
        if (currentCategory.id == categoryId) {
          category = currentCategory;
        }
      }
      if (!category) {
        return [];
      }
      let resTags = [];
      for (let index = 0; index < this.goals.length; index++) {
        const goal = this.goals[index];
        for (let index2 = 0; index2 < goal.tag.length; index2++) {
          const tag = goal.tag[index2];
          if (tag == category.tagId) {
            for (let index3 = 0; index3 < goal.tag.length; index3++) {
              const tag2 = goal.tag[index3];
              if (tag2 != tag) {
                resTags.push(tag2);
              }
            }
            continue;
          }
        }
      }

      let res = [];
      for (let index = 0; index < this.tags.length; index++) {
        const tag = this.tags[index];
        for (let index2 = 0; index2 < resTags.length; index2++) {
          const tagId = resTags[index2];
          if (tag.id == tagId) {
            res.push(tag);
          }
        }
      }

      this.goalTagsCache[categoryId] = res;
      return res;
    },
    findSelectedPreferredLocations() {
      let selectedPreferredLocations = [];
      if (this.profile.preferredHome) {
        selectedPreferredLocations.push(1);
      }
      if (this.profile.preferredClient) {
        selectedPreferredLocations.push(2);
      }
      if (this.profile.preferredOnline) {
        selectedPreferredLocations.push(3);
      }
      if (this.profile.preferredAgreedUpon) {
        selectedPreferredLocations.push(4);
      }

      this.selectedPreferredLocations = selectedPreferredLocations;
    },
    findTags() {
      if (this.profile.other && this.profile.other.requestedTags) {
        for (let index2 = 0; index2 < this.categories.length; index2++) {
          const category = this.categories[index2];
          category.selected = false;
          for (
            let index = 0;
            index < this.profile.other.requestedTags.length;
            index++
          ) {
            const tag = this.profile.other.requestedTags[index];
            if (category.tagId == tag) {
              category.selected = true;
            }
          }
          this.categoryInfos[category.id] = {
            id: category.id,
            selected: category.selected,
          };
          let selectedTags = [];
          let otherTags = this.otherTags(category.id);
          let goalTags = this.goalTags(category.id);
          var tempTags = [];
          Array.prototype.push.apply(tempTags, otherTags);
          Array.prototype.push.apply(tempTags, goalTags);
          for (
            let index4 = 0;
            index4 < this.profile.other.requestedTags.length;
            index4++
          ) {
            const tag = this.profile.other.requestedTags[index4];
            let found = false;
            for (let index3 = 0; index3 < tempTags.length; index3++) {
              const otherTag = tempTags[index3];
              if (otherTag.id == tag) {
                found = true;
              }
            }
            if (found && !selectedTags.includes(tag)) {
              selectedTags.push(tag);
            }
          }
          this.categoryInfos[category.id].selectedTags = selectedTags;
          category.specialization = this.profile.other.specialization
            ? this.profile.other.specialization[category.id]
            : '';
          category.categoryTitle = this.profile.other.categoryTitle
            ? this.profile.other.categoryTitle[category.id]
            : '';
          category.categoryDescription = this.profile.other.categoryDescription
            ? this.profile.other.categoryDescription[category.id]
            : '';
          this.categoryInfos[category.id].categoryTitle =
            category.categoryTitle;
          this.categoryInfos[category.id].categoryDescription =
            category.categoryDescription;
        }
      }
    },
    selectedCategoriesArray() {
      let res = [];
      for (let index = 0; index < this.categories.length; index++) {
        const category = this.categories[index];

        if (category.selected) {
          res.push(parseInt(category.id));
        }
      }
      return res;
    },
    genders() {
      return [
        { text: 'Female', value: 'Female' },
        { text: 'Male', value: 'Male' },
        { text: 'Non-binary', value: 'NonBinary' },
        { text: 'Transgender', value: 'Transgender' },
        { text: 'Intersex', value: 'Intersex' },
        { text: 'Gender Diverse', value: 'GenderDiverse' },
        { text: 'Prefer Not To Say', value: 'Any' },
      ];
    },
    addressNeeded() {
      //      let preferredHome = false;
      let preferredClient = false;
      //      let preferredOnline = false;
      let preferredAgreedUpon = false;

      for (
        let index = 0;
        index < this.selectedPreferredLocations.length;
        index++
      ) {
        const element = this.selectedPreferredLocations[index];
        /*if (element == 1) {
          preferredHome = true;
        }*/
        if (element == 2) {
          preferredClient = true;
        }
        /*if (element == 3) {
          preferredOnline = true;
        }*/
        if (element == 4) {
          preferredAgreedUpon = true;
        }
      }
      return preferredClient || preferredAgreedUpon;
    },
    //SAVE
    async saveProfile(noRedirect) {
      let tags = [];
      let error = false;
      for (let index = 0; index < this.categories.length; index++) {
        const category = this.categories[index];
        if (
          this.categoryInfos[category.id] &&
          this.categoryInfos[category.id].error
        ) {
          error = true;
          break;
        }

        if (
          this.categoryInfos[category.id] &&
          this.categoryInfos[category.id].selected
        ) {
          var selectedTags = [];
          Array.prototype.push.apply(
            selectedTags,
            this.categoryInfos[category.id].selectedTags
          );
          Array.prototype.push.apply(
            selectedTags,
            this.categoryInfos[category.id].selectedGoalTags
          );
          tags = tags.concat(selectedTags);
          let key = category.id;
          let keyInt = parseInt(key);
          for (let index2 = 0; index2 < this.categories.length; index2++) {
            const category = this.categories[index2];
            if (category.id == keyInt) {
              tags.push(parseInt(category.tagId));
            }
          }
        }
      }

      if (error) {
        this.specialityDialog = true;
        return;
      }

      //preferred locations
      let preferredHome = false;
      let preferredClient = false;
      let preferredOnline = false;
      let preferredAgreedUpon = false;

      for (
        let index = 0;
        index < this.selectedPreferredLocations.length;
        index++
      ) {
        const element = this.selectedPreferredLocations[index];
        if (element == 1) {
          preferredHome = true;
        }
        if (element == 2) {
          preferredClient = true;
        }
        if (element == 3) {
          preferredOnline = true;
        }
        if (element == 4) {
          preferredAgreedUpon = true;
        }
      }
      let requestedTags = [];
      for (let index = 0; index < tags.length; index++) {
        const tag = tags[index];
        if (tag) {
          requestedTags.push(tag);
        }
      }
      let backgroundImage;
      if (this.imageFile) {
        const formData = new FormData();
        formData.append('file', this.imageFile, this.imageFile.name);
        formData.set('fileType', 'background');

        // the request itself
        backgroundImage = await axios({
          method: 'post',
          url: `${API.backendUrl}/file/upload`,
          data: formData,
        });
      }
      let profileImage;
      if (this.profileImageFile) {
        const formData = new FormData();
        formData.append(
          'file',
          this.profileImageFile,
          this.profileImageFile.name
        );
        formData.set('fileType', 'profile');

        // the request itself
        profileImage = await axios({
          method: 'post',
          url: `${API.backendUrl}/file/upload`,
          data: formData,
        });
      }
      let request = {
        description: this.description,
        preferredHome,
        preferredClient,
        preferredOnline,
        preferredAgreedUpon,
        state: this.selectedCountry == 'US' ? this.selectedState : null,
        country: this.selectedCountry,
        zipcode: this.zipcode,
        myGender: this.myGender,
        expectedGender: this.expectedGender,
        insuranceAccepted: this.insuranceAccepted,
        ageRange: this.ageRange,
        profileImageUrl:
          profileImage &&
          profileImage.data &&
          profileImage.data.data &&
          profileImage.data.data.url
            ? profileImage.data.data.url
            : this.profile.profileImageUrl,
        backgroundImageUrl:
          backgroundImage &&
          backgroundImage.data &&
          backgroundImage.data.data &&
          backgroundImage.data.data.url
            ? backgroundImage.data.data.url
            : this.profile.backgroundImageUrl,
      };
      if (this.profile.isProfessional) {
        let other = this.profile.other;
        if (!other) {
          other = {};
        }
        other.requestedTags = requestedTags;
        other.myWebsite = this.myWebsite;
        other.instagramLink = this.instagramLink;
        other.facebookLink = this.facebookLink;
        other.pinterestLink = this.pinterestLink;
        other.tikTokLink = this.tikTokLink;
        other.insuranceCarriers = this.insuranceCarriers;
        other.myStory = this.myStory;
        other.mainPractice = this.mainPractice;
        other.secondaryPractices = this.secondaryPractices;
        other.pni = this.pni;
        other.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        other.appointmentDuration = this.appointmentDuration;
        other.appointmentRate = this.appointmentRate;
        other.typesOfPaymentsAccepted = this.selectedTypesOfPaymentsAccepted;
        other.address = this.address;
        other.specialization = {};
        other.categoryTitle = {};
        other.categoryDescription = {};
        other.selectedGoalTags = {};
        for (let index2 = 0; index2 < this.categories.length; index2++) {
          const category = this.categories[index2];
          if (this.categoryInfos[category.id]) {
            other.specialization[category.id] = this.categoryInfos[
              category.id
            ].specialization;
          }
          if (this.categoryInfos[category.id]) {
            other.categoryTitle[category.id] = this.categoryInfos[
              category.id
            ].categoryTitle;
          }
          if (this.categoryInfos[category.id]) {
            let res = [];
            if (this.categoryInfos[category.id].selectedGoalTags) {
              for (
                let index = 0;
                index < this.categoryInfos[category.id].selectedGoalTags.length;
                index++
              ) {
                const element = this.categoryInfos[category.id]
                  .selectedGoalTags[index];
                let tag = this.tagById[element];
                if (tag) {
                  res.push(tag.text);
                }
              }
            }
            other.selectedGoalTags[category.id] = res.join(', ');
          }
          if (this.categoryInfos[category.id]) {
            other.categoryDescription[category.id] = this.categoryInfos[
              category.id
            ].categoryDescription;
          }
        }
        request.other = other;
      }
      let personalInfo = this.profile.personalInfo;
      if (!personalInfo) {
        personalInfo = {};
      }
      personalInfo.issuesAndConcerns = this.issuesAndConcerns;
      personalInfo.age = this.age;
      personalInfo.address = this.address;
      personalInfo.expectedFree15 = this.free15;
      personalInfo.expectedWorkWeekends = this.workWeekends;
      request.personalInfo = personalInfo;

      let res = await API.post('edit-profile-info', request);
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        this.sent = false;
      } else {
        this.error = null;
        if (!noRedirect) {
          this.sent = true;
          this.$emit('nextSection');
        }
      }
    },
    states() {
      return statesJSON;
    },
    countries() {
      return countriesJSON;
    },
    ages() {
      return agesJSON;
    },
    appointmentDurations() {
      return appointmentDurationsJSON;
    },
    paid(/*category*/) {
      return true;
      /*      if (!this.userCategoryByCategory[category.id]) {
        return false;
      }
      if (this.userCategoryByCategory[category.id].status == 'Active') {
        return true;
      }
      return false;
*/
    },
    expired(category) {
      if (!this.userCategoryByCategory[category.id]) {
        return false;
      }
      if (this.userCategoryByCategory[category.id].status == 'Expired') {
        return true;
      }
      return false;
    },
    validTill(category) {
      if (!this.userCategoryByCategory[category.id]) {
        return '';
      }
      return moment(this.userCategoryByCategory[category.id].validTill).format(
        'YYYY/MM/DD'
      );
    },
    createCheckoutSession(priceId) {
      return fetch('/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId: priceId,
        }),
      }).then(function(result) {
        return result.json();
      });
    },
    sections() {
      return EditProfileSections;
    },
    updateParent(categoryInfo) {
      this.categoryInfos[categoryInfo.id] = categoryInfo;
      this.subscribableValue = this.subscribable();
    },
    dialogHidden() {
      this.showSubscribeDialog = false;
    },
    categoryIds() {
      let res = [];
      for (let index2 = 0; index2 < this.categories.length; index2++) {
        const category = this.categories[index2];
        if (
          this.categoryInfos[category.id] &&
          this.categoryInfos[category.id].selected
        ) {
          res.push(category.id);
        }
      }

      return res;
    },
    subscribe() {
      this.showSubscribeDialog = true;
    },
    subscribable() {
      let found = false;
      for (let index2 = 0; index2 < this.categories.length; index2++) {
        const category = this.categories[index2];
        if (
          this.categoryInfos[category.id] &&
          this.categoryInfos[category.id].selected
        ) {
          if (
            !this.userCategoryByCategory[category.id] ||
            this.userCategoryByCategory[category.id].status != 'Active'
          ) {
            found = true;
          }
        }
      }

      return found;
    },
  },
};
</script>
<style>
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
.name-profile {
  font-size: 24px;
}
.title-text {
  font-size: 15px !important;
}
.blue-text {
  letter-spacing: 0.3px;
  opacity: 0.8;
  color: #6596b2;
  font-size: 15px;
  font-weight: 700;
}
.bolder {
  font-weight: bolder;
}
.avatar2 {
  width: 140px !important;
  height: 140px !important;
  top: 0px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.filepond--item {
  width: calc(50% - 0.5em);
}
.v-icon.outlined {
  background-color: white;
  height: 50px;
  width: 50px;
}
.img {
  background: rgba(0, 0, 0, 0.5);
  height: 400px;
  cursor: pointer;
}
.text-image {
  color: #fff;
  text-shadow: 0 1px #666;
  cursor: pointer;
  line-height: 21px;
  font-size: 16px;
}
.v-textarea textarea {
  max-height: none;
  height: 32px;
}
.custom-placeholer-color input::placeholder {
  color: #428751 !important;
  opacity: 1;
}

.custom-label-color .v-label {
  color: #428751ed;
  opacity: 1;
}

.custom-placeholer-color input,
.custom-label-color input {
  color: #428751 !important;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 25px !important;
}
</style>
