// store.js
import Vue from 'vue';

let notificationStore = Vue.observable({
  notifications: [],
  grouped: [],
});
/* eslint-disable no-debugger */

export default notificationStore;

import Socket from '@/sockets/socket';
Socket.addListener((type, payload, notifications) => {
  if (type === Socket.CONNECT && payload.connected) {
    Socket.authenticate();
  }
  if (type === Socket.AUTH && payload.status === 'ok') {
    console.log(payload);
    if (payload.auth) {
      console.log('logged in');
    } else {
      console.log('logged out');
    }
  }
  if (type !== Socket.CONNECT && notifications) {
    notificationStore.grouped = notifications.grouped;
    notificationStore.notifications = notifications.notifications;
  }
});
