<template>
  <div>
    <v-list>
      <v-list-item
        v-for="(product, index) in productsList"
        :key="'product' + index"
        style="margin-bottom:12px;"
        @click="productClicked(index)"
      >
        <v-list-item-avatar tile class="ma-2 pb-0" style="border: 1px solid;">
          <v-img v-if="!product.thumbnailUrl" src="/wlg-logo-110x110.png">
          </v-img>
          <v-img v-if="product.thumbnailUrl" :src="product.thumbnailUrl">
          </v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <div>
            <h2 style="margin-bottom:12px;">{{ product.link }}</h2>
            <div>Title: {{ product.title }}</div>
            <div>Description: {{ product.description }}</div>
            <div>Category: {{ product.category }}</div>
            <div>Subcategory: {{ product.subcategory }}</div>
          </div>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn v-if="product.uuid" :to="'/product/' + product.uuid" icon>
            <v-icon>mdi-file-find</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action>
          <v-btn @click.stop="imagesClicked(index)" icon>
            <v-icon>mdi-image</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action style="margin-left:0px;">
          <v-btn @click.stop="deleteProductConfirmation(product)" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-btn class="mr-4 main-btn success-green" text @click="addCustomDate()">
        Add Product
      </v-btn>
    </v-card-actions>
    <v-dialog v-if="dialog" v-model="dialog" max-width="400">
      <Product
        :product="currentProduct"
        :categories="categories"
        @saved="productClosed"
        @close="dialog = false"
    /></v-dialog>
    <v-dialog v-if="dialog2" v-model="dialog2" max-width="640">
      <v-card>
        <v-card-title>Upload product images</v-card-title>
        <v-card-text>
          <ProductImages :product="currentProduct" @close="dialog2 = false" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 main-btn"
            outlined
            color="gray"
            text
            @click="dialog2 = false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Delete Product?
        </v-card-title>
        <v-card-text>This operation can not be undone</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="mr-4"
            text
            @click="(dialogDelete = false), deleteProduct()"
          >
            Delete
          </v-btn>
          <v-btn color="green darken-1" text @click="dialogDelete = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-debugger */
import API from '../../api';
import Product from './Product';
import ProductImages from './ProductImages';

export default {
  name: 'WorkingDays',
  props: {},
  components: {
    Product,
    ProductImages,
    //Matching,
    //Tags,
  },
  data: () => ({
    error: null,
    productsList: [],
    categories: [],
    categoriesMap: {},
    currentProduct: {},
    dialog: false,
    dialog2: false,
    dialog3: false,
    dialogDelete: false,
  }),
  async created() {
    this.loadData(this.sessionType);
  },
  mounted() {
    document.title = 'Edit Products';
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData();
    next();
  },
  watch: {
    dialog2(val) {
      if (!val) {
        this.loadData(val);
      }
    },
  },
  methods: {
    async loadData() {
      this.productsList = [];
      let res = await API.get('product');
      this.productsList = res.data.products;
      this.categories = res.data.categories;
      this.categoriesMap = {};
      for (let index = 0; index < this.categories.length; index++) {
        const category = this.categories[index];
        this.categoriesMap[category.id] = category;
      }
      //sdfsd;
    },
    productClicked(index) {
      this.currentProduct = Object.assign({}, this.productsList[index]);
      this.dialog = true;
    },
    addCustomDate() {
      this.currentProduct = {};
      this.dialog = true;
    },
    async productClosed() {
      await this.loadData();
      this.dialog = false;
    },
    async deleteProduct() {
      if (this.currentProduct.id) {
        await API.delete('product', { id: this.currentProduct.id });
        await this.loadData();
      }
    },
    deleteProductConfirmation(product) {
      this.currentProduct = product;
      this.dialogDelete = true;
    },
    imagesClicked(index) {
      this.currentProduct = Object.assign({}, this.productsList[index]);
      this.dialog2 = true;
    },
  },
};
</script>

<style>
.dow {
  width: 60px;
  display: inline-block;
}
.dowCheckbox {
  margin-top: 0px;
}
</style>
