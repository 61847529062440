<template>
  <div>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Delete product?
        </v-card-title>
        <v-card-text>This operation can not be undone</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="mr-4"
            text
            @click="(deleteDialog = false), deleteProduct()"
          >
            Delete
          </v-btn>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
          <v-checkbox label="Approved" v-model="selectedProductApproved" />
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 main-btn success-green"
            text
            @click="(dialog = false), changeStatus()"
          >
            Change
          </v-btn>
          <v-btn
            class="mr-4 main-btn"
            outlined
            color="gray"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="1000">
      <v-card>
        <v-card-text>
          <v-select
            item-text="name"
            item-value="abbreviation"
            :items="categories()"
            v-model="selectedProductCategory"
          >
            <template v-slot:append>
              <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
            </template>
          </v-select>
          <h2 style="margin-bottom:12px;">Subcategorty</h2>
          <v-select
            item-text="name"
            item-value="abbreviation"
            :items="subcategories(selectedProductCategory)"
            v-model="selectedProductSubcategory"
          >
            <template v-slot:append>
              <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
            </template></v-select
          >
          <v-textarea
            placeholder="title"
            v-model="selectedProductTitle"
            auto-grow
          ></v-textarea>
          <v-textarea
            placeholder="description"
            v-model="selectedProductDescription"
            auto-grow
          ></v-textarea>
          <!--v-textarea
            placeholder="iframe"
            v-model="selectedProductIframe"
            auto-grow
          ></v-textarea-->
        </v-card-text>

        <v-alert v-if="error" type="error">
          {{ error }}
        </v-alert>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-4 main-btn success-green" text @click="save()">
            Save
          </v-btn>
          <v-btn
            class="mr-4 main-btn"
            outlined
            color="gray"
            text
            @click="dialog2 = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card
      ><v-card-text>
        <v-select
          label="Category"
          item-text="name"
          item-value="abbreviation"
          :items="categoriesWithAny()"
          v-model="category"
          placeholder="Category"
        >
          <template v-slot:append>
            <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
          </template></v-select
        >
        <v-select
          label="Subcategory"
          item-text="name"
          item-value="abbreviation"
          :items="subcategoriesWithAny(category)"
          v-model="subcategory"
          placeholder="Category"
        >
          <template v-slot:append>
            <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
          </template>
        </v-select>
        <v-data-table
          :headers="headers"
          :items="products"
          :options.sync="options"
          :server-items-length="productCount"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.username`]="{ item }">
            <a :href="'/user/' + item.User.username">
              {{ item.User.username }}
            </a>
          </template>
          <template v-slot:[`item.link`]="{ item }">
            <a target="_blank" :href="item.link">
              {{ item.link }}
            </a>
          </template>
          <template v-slot:[`item.approved`]="{ item }">
            <a @click="approvedClick(item)">
              {{ item.approved }}
            </a>
          </template>
          <template v-slot:[`item.mainPractice`]="{ item }">
            {{ item.other.mainPractice }}
          </template>
          <template v-slot:[`item.category`]="{ item }">
            <a @click="editClick(item)">
              {{ item.category }}
            </a>
          </template>
          <template v-slot:[`item.subcategory`]="{ item }">
            <a @click="editClick(item)">
              {{ item.subcategory }}
            </a>
          </template>
          <template v-slot:[`item.title`]="{ item }">
            <a @click="editClick(item)">
              {{
                item.title && item.title.length > 10
                  ? item.title.substring(0, 10) + '..'
                  : item.title
              }}
            </a>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <a @click="editClick(item)">
              {{
                item.description && item.description.length > 10
                  ? item.description.substring(0, 10) + '..'
                  : item.description
              }}
            </a>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="showDeleteDialog(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <v-btn color="success" class="mr-4 mt-4" @click="createClick()">
          Create
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import productCategoryJSON from '../../../data/productCategories.json';

// Import Vue FilePond

/* eslint-disable no-debugger */

import API from '../../api';

/* eslint-disable-next-line  */

export default {
  name: 'admin-users',
  props: {
    questionId: {
      type: [String],
    },
  },

  components: {},

  data: () => ({
    error: null,
    info: {},

    sent: false,
    activateCategoryDialog: false,
    dialog: false,
    dialog2: false,
    deleteDialog: false,
    productCount: 0,
    products: [],
    loading: true,
    options: {},
    selectedStatus: 0,
    selectedProduct: {},
    categoryFromId: {},
    selectedProductIframe: '',
    selectedProductTitle: '',
    selectedProductDescription: '',
    selectedProductApproved: false,
    category: 'Any',
    subcategory: 'Any',
    selectedProductLink: '',
    selectedProductCategory: '',
    selectedProductSubcategory: '',
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      {
        text: 'Username',
        sortable: false,
        align: 'start',
        value: 'username',
      },
      {
        text: 'Link',
        align: 'start',
        value: 'link',
      },
      {
        text: 'Created',
        align: 'start',
        value: 'createdAt',
      },
      {
        text: 'Approved',
        align: 'start',
        value: 'approved',
      },
      {
        text: 'Category',
        align: 'start',
        value: 'category',
      },
      {
        text: 'Subcategory',
        align: 'start',
        value: 'subcategory',
      },
      {
        text: 'title',
        align: 'start',
        value: 'title',
      },
      {
        text: 'description',
        align: 'start',
        value: 'description',
      },
      {
        text: 'Actions',
        align: 'start',
        value: 'actions',
      },
    ],
  }),
  created: async function() {
    this.error = null;
    let res = await API.get('admin/info');
    if (!res) {
      return;
    }
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      this.info = res.data.info;
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    category(newCategory) {
      if (!this.subcategory || this.subcategory == '') {
        return;
      }
      if (!newCategory || newCategory == '') {
        return;
      }
      let categories = this.subcategories(newCategory);
      let found = false;
      for (let index = 0; index < categories.length; index++) {
        const subcategory = categories[index];
        if (subcategory.abbreviation == this.subcategory) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.subcategory = 'Any';
      }
      this.getDataFromApi();
    },
    subcategory() {
      this.getDataFromApi();
    },
  },
  mounted() {
    document.title = 'Admin Products';
  },
  methods: {
    statuses() {
      return [];
    },
    async search() {
      this.getDataFromApi(this.professionalsShown());
    },
    async getDataFromApi() {
      this.loading = true;
      let res = await API.post('admin/product', {
        options: this.options,
        category: this.category === 'Any' ? null : this.category,
        subcategory: this.subcategory === 'Any' ? null : this.subcategory,
      });
      this.products = res.data.products;
      this.productCount = res.data.productCount;
      this.loading = false;
    },
    async approvedClick(product) {
      this.selectedProduct = product;
      this.selectedProductApproved = product.approved;
      this.dialog = true;
    },
    async editClick(product) {
      this.selectedProduct = product;
      this.selectedProductTitle = product.title;
      this.selectedProductDescription = product.description;
      this.selectedProductLink = product.link;
      this.selectedProductCategory = product.category;
      this.selectedProductSubcategory = product.subcategory;
      this.dialog2 = true;
    },
    async showDeleteDialog(product) {
      this.selectedProduct = product;
      this.deleteDialog = true;
    },
    async createClick() {
      this.selectedProduct = {};
      this.selectedProductTitle = '';
      this.selectedProductDescription = '';
      this.selectedProductLink = '';
      this.selectedProductCategory =
        this.category === 'Any' ? null : this.category;
      this.selectedProductSubcategory =
        this.subcategory === 'Any' ? null : this.subcategory;
      this.dialog2 = true;
    },
    async changeStatus() {
      await API.post('admin/product/setApproved', {
        productId: this.selectedProduct.id,
        approved: this.selectedProductApproved,
        //        iframe: this.selectedProductIframe,
        title: this.selectedProductTitle,
        description: this.selectedProductDescription,
      });
      this.getDataFromApi();
    },
    async save() {
      let res = await API.post('product', {
        id: this.selectedProduct.id,
        link: this.selectedProductLink,
        //        iframe: this.selectedProductIframe,
        title: this.selectedProductTitle,
        description: this.selectedProductDescription,
        category: this.selectedProductCategory,
        subcategory: this.selectedProductSubcategory,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        this.error = null;
        this.dialog2 = false;
      }
      await this.getDataFromApi();
    },

    subcategoriesWithAny(categoryAbbrevation) {
      let categories = [{ name: 'Any', value: 'Any' }];
      for (let index = 0; index < productCategoryJSON.length; index++) {
        const category = productCategoryJSON[index];
        if (category.abbreviation == categoryAbbrevation) {
          return categories.concat(category.subcategories);
        }
      }
      return categories;
    },
    subcategories(categoryAbbrevation) {
      let categories = [];
      for (let index = 0; index < productCategoryJSON.length; index++) {
        const category = productCategoryJSON[index];
        if (category.abbreviation == categoryAbbrevation) {
          return categories.concat(category.subcategories);
        }
      }
      return categories;
    },
    categoriesWithAny() {
      let categories = [{ name: 'Any', value: 'Any' }];
      return categories.concat(productCategoryJSON);
    },
    categories() {
      return productCategoryJSON;
    },
    async deleteProduct() {
      await API.delete('product', { id: this.selectedProduct.id });
      this.getDataFromApi();
    },
  },
  computed: {},
};
</script>

<style></style>
