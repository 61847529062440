/* -eslintdisable no-debugger */

export function formatName(name) {
  if (!name) {
    return '';
  }
  let parts = name.split(' ');
  for (let index = 0; index < parts.length; index++) {
    const part = parts[index];
    if (part.length > 0) {
      parts[index] =
        part.substring(0, 1).toUpperCase() + part.substring(1).toLowerCase();
    }
  }
  return parts.join(' ');
}

export function shorterString(text, maxLength) {
  if (!text || text.length <= maxLength) {
    return text;
  }
  var trimmedString = text.substr(0, maxLength);

  //re-trim if we are in the middle of a word
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
  );
  return trimmedString + '...';
}
