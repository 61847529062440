<template>
  <v-card>
    <v-card>
      <v-card-text>
        <div>
          <h2 style="margin-bottom:12px;">{{ dayTitle() }}</h2>
          {{ hoursText(workHour) }}
          <v-checkbox
            style="margin-top:0px;"
            hide-details
            label="Work"
            v-model="workHour.work"
          />

          <v-range-slider
            hide-details
            v-model="workHoursRange"
            step="15"
            :disabled="!workHour.work"
            :min="0"
            :max="24 * 60"
            @change="hoursChanged"
          ></v-range-slider>

          <v-checkbox
            v-if="workHour.sessionType == 'Standard'"
            style="margin-top:0px;"
            hide-details
            label="Lunch"
            v-model="workHour.lunch"
            :disabled="!workHour.work"
          />

          <v-range-slider
            v-if="workHour.sessionType == 'Standard'"
            hide-details
            v-model="workHoursLunchRange"
            step="15"
            :disabled="!workHour.lunch || !workHour.work"
            :min="0"
            :max="24 * 60"
            @change="hoursChanged"
          ></v-range-slider>
        </div>
        <v-menu
          v-if="workHour.dayType == 'Custom'"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label=""
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @change="menu = false"
            no-title
            scrollable
          >
          </v-date-picker>
        </v-menu>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-4 main-btn success-green" text @click="save()">
          Save
        </v-btn>
        <v-btn
          class="mr-4 main-btn"
          outlined
          color="gray"
          text
          @click="cancel()"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
/* eslint-disable no-debugger */
import moment from 'moment';
import API from '../../api';

export default {
  name: 'WorkHour',
  props: {
    workHour: {
      type: [Object],
    },
  },
  data: () => ({
    //    tags: [],
    error: null,
    workHoursRange: [],
    workHoursLunchRange: [],
    menu: false,
    date: '',
    //    tagsMap: {},
  }),
  watch: {
    workHoursRange(val) {
      this.workHour.start = val[0];
      this.workHour.end = val[1];
    },
    workHoursLunchRange(val) {
      this.workHour.lunchStart = val[0];
      this.workHour.lunchEnd = val[1];
    },
  },
  async created() {
    this.workHoursRange = [this.workHour.start, this.workHour.end];
    this.workHoursLunchRange = [
      this.workHour.lunchStart,
      this.workHour.lunchEnd,
    ];
    this.date = this.workHour.date;
  },
  methods: {
    dayTitle() {
      if (this.workHour.dayType === 'Custom') {
        return moment(this.workHour.date).format('YYYY/MM/DD');
      } else {
        return this.workHour.dayType;
      }
    },
    hoursChanged() {
      this.$emit('updateHours', this.myWorkingDays);
    },
    hoursText(workHour) {
      if (workHour.work) {
        let start = moment();
        start.set({ hour: workHour.start / 60, minute: workHour.start % 60 });
        let end = moment();
        end.set({ hour: workHour.end / 60, minute: workHour.end % 60 });
        let res = start.format('LT') + '-' + end.format('LT');
        if (workHour.lunch) {
          let start = moment();
          start.set({
            hour: workHour.lunchStart / 60,
            minute: workHour.lunchStart % 60,
          });
          let end = moment();
          end.set({
            hour: workHour.lunchEnd / 60,
            minute: workHour.lunchEnd % 60,
          });
          res = res + ' Lunch: ' + start.format('LT') + '-' + end.format('LT');
        }
        return res;
      } else {
        return 'Not available';
      }
      //ange[1];
    },
    async save() {
      await API.post('workhour', {
        id: this.workHour.id,
        work: this.workHour.work,
        start: this.workHour.start,
        end: this.workHour.end,
        lunch: this.workHour.lunch,
        lunchStart: this.workHour.lunchStart,
        lunchEnd: this.workHour.lunchEnd,
        date: this.date,
        dayType: this.workHour.dayType,
        comment: this.workHour.comment,
        sessionType: this.workHour.sessionType,
      });
      this.$emit('saved');
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.dow {
  width: 60px;
  display: inline-block;
}
.dowCheckbox {
  margin-top: 0px;
}
</style>
