<template>
  <v-container>
    <v-row class="main-container">
      <v-col>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <h2
              class="title mb-2"
              style="font-size: 24px !important; font-weight: 700"
            >
              {{
                isProfessional()
                  ? 'Join as Professional for FREE!'
                  : "Search and Match with Certified Professionals - it's free!"
              }}
            </h2>
            <div v-if="isProfessional()">
              Please sign up using the form below. Join Whole Life Goals for
              FREE Get more exposure, showcase your expertise, increase your
              practice, and contribute to our health, wealth and wellness
              community.
            </div>
            <div v-if="!isProfessional()">
              Please add your email address below to search, match, contact, and
              schedule appointments with certified professionals.
            </div>
            <!--div v-if="isProfessional()">
              <br />
              <br />
              <h2
                class="title mb-2 greenText"
                style="font-size: 28px !important"
              >
                <b>What Do You Specialise In?</b>
              </h2>
              <div>Please select all that apply.</div>

              <v-autocomplete
                multiple
                chips
                v-model="selectedGoals"
                :items="goals"
                item-text="text"
                item-value="id"
                placeholder="
                "
                return-object
              >
                <template v-slot:append>
                  <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
                </template>
                <template v-slot:selection="data">
                  <v-chip
                    color="#428751"
                    outlined
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    @click="data.select"
                  >
                    {{ data.item.text }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </div-->
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="name"
                :rules="nameRules"
                placeholder="Name"
                required
              ></v-text-field>

              <v-text-field
                v-model="username"
                :rules="usernameRules"
                placeholder="Username"
                required
              ></v-text-field>

              <v-text-field
                v-model="email"
                :rules="emailRules"
                placeholder="E-mail"
                required
              ></v-text-field>

              <v-text-field
                v-model="password"
                :rules="[passwordRules.req, passwordRules.min]"
                name="input-10-1"
                type="password"
                placeholder="Password"
                hint="At least 8 characters"
                counter
              ></v-text-field>

              <v-text-field
                v-model="confirmPassword"
                :rules="[
                  passwordRules.req,
                  passwordRules.min,
                  passwordConfirmationRule,
                ]"
                name="input-10-1"
                type="password"
                placeholder="Confirm Password"
                hint="At least 8 characters"
                counter
              ></v-text-field>

              <div v-if="isProfessional()">
                <b>What happens next</b>
                <div>
                  Once you've registered, you can set up your Professional
                  Profile with information about you and your practice.
                </div>
              </div>
              <br />
              <div>
                By signing up you accept the
                <a href="https://dashboard.wholelifegoals.com/terms-of-use/"
                  >Terms&nbsp;of&nbsp;Use</a
                >
                and
                <a href="https://dashboard.wholelifegoals.com/privacy-policy/"
                  >Privacy&nbsp;Policy</a
                >
              </div>
              <br />
              <v-alert v-if="error" type="error">
                {{ error }}
              </v-alert>
              <v-alert v-if="sent" type="success">
                Reistration complete
              </v-alert>
              <v-btn
                v-if="!sent"
                class="mr-4 main-btn success-green"
                @click="register"
              >
                Register
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */

import API from '../../api';
import loginStore from '@/util/loginStore';

export default {
  name: 'Register',
  props: {
    registrationType: {
      type: [String],
    },
  },
  data: () => ({
    //    tags: [],
    sent: false,
    error: null,
    valid: false,
    name: '',
    nameRules: [(v) => !!v || 'Name is required'],
    username: '',
    usernameRules: [
      (v) => !!v || 'Username is required',
      (v) =>
        /^[a-zA-Z0-9/._]+$/.test(v) ||
        'Only symbols a-z, 0-9, "_", "." are allowed for username',
    ],
    email: '',
    questions: [],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    password: '',
    confirmPassword: '',
    passwordRules: {
      req: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
    },
    items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
    goals: [],
    selectedGoals: [],
  }),
  mounted() {
    document.title = 'Register';
  },
  methods: {
    async loadData(registrationType) {
      let res = await API.get(
        registrationType == 'professional'
          ? 'question?type=professional'
          : 'question?type=what'
      );
      if (res.data) {
        this.questions = [res.data.question];
        for (let index = 0; index < this.questions.length; index++) {
          const element = this.questions[index];
          this.goals = element.answers;
        }
      }
      let resGoals = [];
      for (let index = 0; index < this.goals.length; index++) {
        const element = this.goals[index];
        resGoals.push(element);
        if ((index + 1) % 3 == 0 && index != 0) {
          resGoals.push({ divider: true });
        }
      }
      this.goals = resGoals;
    },
    isProfessional() {
      return this.registrationType == 'professional';
    },
    async register() {
      let selectedGoals;
      if (Array.isArray(this.selectedGoals)) {
        selectedGoals = this.selectedGoals;
      } else {
        selectedGoals = [this.selectedGoals];
      }
      let questionsAnswers = [];
      let requestedTags = [];
      for (let index = 0; index < selectedGoals.length; index++) {
        const answer = selectedGoals[index];
        questionsAnswers.push({
          question: this.questions[0].text,
          answer: answer.text,
        });
        for (let index2 = 0; index2 < answer.tag.length; index2++) {
          const element = answer.tag[index2];
          requestedTags.push(element);
        }
      }
      if (!this.$refs.form.validate()) {
        return;
      }
      let other = {};
      if (this.isProfessional()) {
        other.requestedTags = requestedTags;
      } else {
        other.searchTags = requestedTags;
        other.questionsAnswers = questionsAnswers;
      }
      let res = await API.post('register', {
        name: this.name,
        username: this.username,
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
        isProfessional: this.isProfessional(),
        nextUrl: this.$route.query.nextUrl,
        other,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        this.sent = false;
      } else {
        this.error = null;
        loginStore.loginWithData(res.data);
        this.sent = true;
        setTimeout(() => {
          if (this.$route.query.nextUrl) {
            this.$router.push(this.$route.query.nextUrl);
          } else {
            if (res.data.user.isProfessional) {
              this.$router.push('/profile/edit');
            } else {
              this.$router.push('/profile/member?panel=1');
            }
          }
        }, 1000);
      }
    },
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || 'Passwords must match';
    },
  },
  async created() {
    this.loadData(this.registrationType);
  },

  beforeRouteUpdate(to, from, next) {
    this.selectedGoals = [];
    this.loadData(to.params.registrationType);
    next();
  },
};
</script>
<style>
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
.custom-placeholer-color input::placeholder {
  color: #428751 !important;
  opacity: 1;
}

.custom-label-color .v-label {
  color: #428751;
  opacity: 1;
}

.custom-placeholer-color input,
.custom-label-color input {
  color: #428751 !important;
}
</style>
