<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <h2 class="title mb-2">Login as {{ username }}</h2>

            <v-form ref="form">
              <v-alert v-if="error" type="error">
                {{ error }}
              </v-alert>
              <v-alert v-if="sent" type="success">
                Login successful!
              </v-alert>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */

import API from '../../api';
import loginStore from '@/util/loginStore';

export default {
  name: 'AdminLoginAs',
  props: {
    username: {
      type: [String],
    },
  },
  data: () => ({
    //    tags: [],
    error: null,
    password: '',
    confirmPassword: '',
    passwordRules: {
      req: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
    },
    checkbox: false,
    sent: false,
  }),
  created: async function() {
    let res = await API.post('admin/loginAs', {
      username: this.username,
    });
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
      this.sent = false;
    } else {
      loginStore.loginWithData(res.data);
      this.error = null;
      this.sent = true;
      setTimeout(() => {
        if (res.data.user.isProfessional) {
          this.$router.push('/profile/edit');
        } else {
          this.$router.push('/profile/member?panel=1');
        }
      }, 1000);
    }
  },
  mounted() {
    document.title = 'Login As ...';
  },
  methods: {},
  computed: {},
};
</script>
<style></style>
