import axios from 'axios';

export function parseVideo(url) {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - http://y2u.be/huKvjPQ-Xm4
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  //   - http://www.youtube.com/embed/Ab25nviakcw
  //   - http://www.youtube-nocookie.com/v/Ab25nviakcw?version=3&hl=en_US
  //   - http://www.youtube.com/watch?feature=player_embedded&v=Ab25nviakcw#
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551
  let type,
    id,
    match = url.match(
      /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|y2u\.be|youtu(be\.com|\.be|be\.googleapis\.com|be-nocookie\.com))\/(video\/|embed\/|watch\?\S*v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/i
    );
  if (match && match[6]) {
    id = match[6];
    if (
      match[3] &&
      (match[3].indexOf('youtu') > -1 || match[3].indexOf('y2u') > -1)
    )
      type = 'Youtube';
    if (match[3] && match[3].indexOf('vimeo') > -1) type = 'Vimeo';
  }
  return {
    type: type,
    id: id,
  };
}

export async function getThumbnailUrl(id, type) {
  // Obtains the video's thumbnail and passed it back to a callback function.
  if (type == 'Youtube') {
    return '//img.youtube.com/vi/' + id + '/hqdefault.jpg';
  } else if (type == 'Vimeo') {
    // Requires jQuery
    let data = await axios.get('http://vimeo.com/api/v2/video/' + id + '.json');
    return data.data[0].thumbnail_large;
  }
}

export function getVideoUrl(id, type) {
  if (type == 'Youtube') {
    return 'https://www.youtube.com/watch?v=' + id;
  } else {
    return 'https://player.vimeo.com/video/' + id;
  }
}
