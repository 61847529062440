<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto">
          <v-card-text>
            Name:
            <v-text-field
              v-model="name"
              placeholder="Text"
              required
            ></v-text-field>
            Root tag:
            <v-autocomplete
              v-model="tagId"
              :items="tagsWithNone"
              item-text="text"
              item-value="id"
              placeholder="Root tag"
            >
              <template v-slot:append>
                <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
              </template></v-autocomplete
            >

            Color:

            <v-color-picker v-model="color" flat></v-color-picker>
            Order Index:
            <v-text-field
              v-model="orderIndex"
              placeholder="Order Index"
              required
            ></v-text-field>
            <v-btn color="success" class="mr-4" @click="saveCategory()">
              Save
            </v-btn>
            <v-dialog
              v-if="categoryId"
              v-model="dialog"
              persistent
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="error" class="mr-4" v-bind="attrs" v-on="on">
                  Delete
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">
                  Delete category?
                </v-card-title>
                <v-card-text>This operation can not be undone</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    class="mr-4"
                    text
                    @click="(dialog = false), deleteCategory()"
                  >
                    Delete
                  </v-btn>
                  <v-btn color="green darken-1" text @click="dialog = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
          <v-alert v-if="error" type="error">
            {{ error }}
          </v-alert>
          <v-card-text>
            Category tags:
            <v-list two-line>
              <template v-for="item in tagCategories">
                <v-list-item :key="item.id" :to="'/admin/tag/' + item.id">
                  <v-list-item-content>
                    <v-list-item-title v-html="item.text"></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="item.priority"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// Import Vue FilePond

/* eslint-disable no-debugger */

import API from '../../api';

/* eslint-disable-next-line  */

export default {
  name: 'Profile',
  props: {
    categoryId: {
      type: [String],
    },
  },

  components: {},

  data: () => ({
    error: null,
    dialog: false,
    info: {},
    categories: {},
    selectedCategories: [],
    name: '',
    color: '#FF0000',
    priority: 0,
    sent: false,
    tagCategories: [],
    tagsWithNone: [],
    tagId: null,
    orderIndex: '',
  }),
  //LOAD
  mounted() {
    document.title = 'Admin Category';
  },
  created: async function() {
    this.error = null;
    let res = await API.get('admin/info');
    if (!res) {
      return;
    }
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      this.info = res.data.info;
      for (let index = 0; index < this.info.categories.length; index++) {
        const category = this.info.categories[index];
        if (category.id == this.categoryId) {
          this.tagsWithNone = [{ id: null, text: '[NONE]' }];
          Array.prototype.push.apply(this.tagsWithNone, this.info.tags);
          this.name = category.name;
          this.color = category.color;
          this.tagId = category.tagId;
          this.orderIndex = category.orderIndex;
          this.tagCategories = [];
          for (let index = 0; index < res.data.info.tags.length; index++) {
            const tag = res.data.info.tags[index];
            let found = false;
            for (let index2 = 0; index2 < tag.TagCategories.length; index2++) {
              const tagCategory = tag.TagCategories[index2];
              if (tagCategory.categoryId == this.categoryId) {
                found = true;
                break;
              }
            }
            if (found) {
              this.tagCategories.push(tag);
            }
          }
        }
      }
    }
  },
  computed: {},
  methods: {
    async saveCategory() {
      let res = await API.post('admin/category', {
        categoryId: this.categoryId,
        name: this.name,
        color: this.color && this.color.hex ? this.color.hex : this.color,
        tagId: this.tagId,
        orderIndex: this.orderIndex,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        this.sent = false;
      } else {
        this.error = null;
        this.sent = true;
        this.$router.push('/admin/categories');
      }
    },
    async deleteCategory() {
      let res = await API.delete('admin/category', {
        categoryId: this.categoryId,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        this.sent = false;
      } else {
        if (res.data && res.data.length > 0) {
          this.error =
            'Can not delete category that has tags in it. Please remove category from tags first';
        } else {
          this.error = null;
          this.sent = true;
          this.$router.push('/admin/categories');
        }
      }
    },
  },
};
</script>

<style></style>
