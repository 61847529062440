<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto" max-width="800">
          <v-card-text>
            <h2 class="title mb-2">
              A subscription request has been created.
              <br />
              Your payment is now being processed.
              <br />
              You will receive a successfully processed email confirmation
              invoice shortly.
              <br />
              Once received please continue editing your categories and tags.
              <br />
              Thank you for subscribing to Whole Life Goals!
              <br />
              <br />
              If you have any questions please email:
              <a href="mailto:info@wholelifegoals.com"
                >info@wholelifegoals.com</a
              >
            </h2>

            <v-form ref="form">
              <v-alert v-if="error" type="error">
                {{ error }}
              </v-alert>

              <v-btn class="mr-4 main-btn success-green" @click="paymentPortal">
                Manage Billing
              </v-btn>

              <v-btn
                class="mr-4 main-btn success-green"
                to="/profile/edit?panel=3"
              >
                Edit Categories
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */

import API from '../../api';

export default {
  name: 'StripeSuccess',

  data: () => ({
    //    tags: [],
    error: null,
    sent: false,
  }),
  mounted() {
    document.title = 'Stripe Subscriptions';
  },
  created: async function() {
    if (this.$route.query.session_id) {
      let res = await API.post('category/session', {
        sessionId: this.$route.query.session_id,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        this.sent = false;
      } else {
        this.error = null;
        this.sent = true;
      }
    }
  },
  methods: {
    async paymentPortal() {
      let res = await API.post('category/portal', {});
      window.location.href = res.data.url;
    },
  },
  computed: {},
};
</script>
<style></style>
