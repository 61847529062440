import { render, staticRenderFns } from "./EnlargedImage.vue?vue&type=template&id=5685cc6b&class=main-container&"
import script from "./EnlargedImage.vue?vue&type=script&lang=js&"
export * from "./EnlargedImage.vue?vue&type=script&lang=js&"
import style0 from "./EnlargedImage.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VImg})
