<template>
  <v-container>
    <v-row class="main-container">
      <v-col>
        <v-card class="mx-auto" max-width="1000">
          <v-card-title> Appointments list</v-card-title>

          <v-list two-line>
            <template v-for="item in appointments">
              <v-list-item :key="item.uuid" :to="'/appointment/' + item.uuid">
                <v-list-item-avatar>
                  <v-icon v-if="!user(item).profileImageUrl">
                    mdi-account-circle
                  </v-icon>
                  <v-img
                    v-if="user(item).profileImageUrl"
                    :src="user(item).profileImageUrl"
                  >
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-html="formatName(user(item).name)"
                  ></v-list-item-title>
                  <v-list-item-subtitle>{{
                    formatted(item.start) + '-' + formatted2(item.end)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.status == 'Cancelled' ? 'Canceled' : item.status }}
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
          <v-alert v-if="error" type="error">
            {{ error }}
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable no-debugger */
import { formatName } from '@/util/stringUtils';

import API from '../../api';
import moment from 'moment';
import loginStore from '@/util/loginStore';

export default {
  name: 'appointments-list',
  props: {},
  data: () => ({
    appointments: [],
    error: null,
    tab: 'Scheduled',
    dialog: false,
    selectedAppointment: null,
  }),
  created: function() {
    this.loadAppointments(this.tab);
  },
  mounted() {
    document.title = 'Appointments List';
  },
  watch: {
    tab: {
      handler(value) {
        this.loadAppointments(value);
      },
      deep: true,
    },
  },
  methods: {
    formatName(name) {
      return formatName(name);
    },
    async loadAppointments() {
      let res = await API.post('appointment/load');
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        return;
      }
      this.appointments = res.data;
    },
    user(item) {
      if (
        item.Professional &&
        item.Professional.username != loginStore.username
      ) {
        return item.Professional;
      } else {
        return item.Member;
      }
    },
    formatDate(date) {
      return moment(date).format('LLL');
    },
    formatted(date) {
      return moment(date).format('LLL');
    },
    formatted2(date) {
      return moment(date).format('LT');
    },
    edit(appointment, event) {
      this.selectedAppointment = appointment;
      this.dialog = true;
      // теперь у нас есть доступ к нативному событию
      if (event) {
        event.preventDefault();
      }
    },
    getUsername() {
      return loginStore.username;
    },
  },
};
</script>
/* eslint-disable no-unused-vars */
