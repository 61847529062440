<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <v-form ref="form">
              <v-alert v-if="error" type="error">
                {{ error }}
              </v-alert>

              Redirecting...
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */

import API from '../../api';

export default {
  name: 'StripeSubscriptions',

  data: () => ({
    //    tags: [],
    error: null,
    sent: false,
  }),
  mounted() {
    document.title = 'Stripe Subscriptions';
  },
  created: async function() {
    let res = await API.post('category/portal', {});
    window.location.href = res.data.url;
  },
  methods: {},
};
</script>
<style></style>
