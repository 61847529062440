function localWebsocketUrl() {
  var loc = window.location,
    newUri;
  if (loc.protocol === 'https:') {
    newUri = 'wss:';
  } else {
    newUri = 'ws:';
  }
  newUri += '//' + loc.host;
  newUri += '/socket';
  return newUri;
}

export default {
  backendUrl: function() {
    if (process.env.NODE_ENV === 'development') {
      return 'http://192.168.34.99:8080/api/';
    } else {
      return '/api/';
    }
  },

  socketUrl: function() {
    if (process.env.NODE_ENV === 'development') {
      return 'ws://192.168.34.99:8081/';
    } else {
      return localWebsocketUrl();
    }
  },
  baseUrl: function() {
    if (process.env.NODE_ENV === 'development') {
      return '/';
    } else {
      return '/';
    }
  },
};
