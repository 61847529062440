<template>
  <v-app class="main-container">
    <v-main>
      <v-toolbar class="main">
        <!--v-toolbar-items>
          <v-btn flat to="/"
            ><div class="button-style" style="">
              <img height="22px" src="/images/logo_9_1.png" />
            </div>
          </v-btn>
        </v-toolbar-items-->

        <v-spacer></v-spacer>
        <div class="main-menu">
          <div style="margin-top: -5px">
            <a href="https://wholelifegoals.com/">
              <img src="../public/wlg-logo-1024x110.png" style="width: 240px" />
            </a>
          </div>
          <v-toolbar-items class="hidden-sm-and-down">
            <!--v-btn text to="/home"><div class="button-style">Home</div></v-btn-->
            <v-btn v-if="!loggedIn()" text to="/register/member"
              ><div
                :class="
                  currentRouteName.startsWith('/register/member')
                    ? 'button-style-underlined'
                    : 'button-style'
                "
              >
                Sign Up
              </div></v-btn
            >
            <v-btn v-if="!isProfessional()" text to="/search"
              ><div
                :class="
                  currentRouteName.startsWith('/search')
                    ? 'button-style-underlined'
                    : 'button-style'
                "
              >
                Search Professionals
              </div></v-btn
            >

            <!--v-btn text to="/how-it-works">
              <div class="button-style">How it Works</div></v-btn
            -->

            <v-btn v-if="!loggedIn()" text to="/register/professional"
              ><div
                :class="
                  currentRouteName.startsWith('/register/professional')
                    ? 'button-style-underlined'
                    : 'button-style'
                "
              >
                Join as a Professional
              </div></v-btn
            >
            <v-btn
              v-if="!loggedIn()"
              text
              href="https://dashboard.wholelifegoals.com/how-it-works/"
              ><div class="button-style">How it Works</div></v-btn
            >
            <!--v-btn v-if="!loggedIn()" text to="/register"
                ><div class="button-style">Become a Member</div></v-btn
              -->
            <!--v-btn text to="/about-wlg"
                ><div class="button-style">About WLG</div></v-btn
              -->
            <v-btn v-if="isAdmin()" text to="/admin"
              ><div
                :class="
                  currentRouteName.startsWith('/admin')
                    ? 'button-style-underlined'
                    : 'button-style'
                "
              >
                Admin
              </div></v-btn
            >
            <v-btn v-if="loggedIn()" text to="/appointment/list"
              ><div
                :class="
                  currentRouteName.startsWith('/appointment')
                    ? 'button-style-underlined'
                    : 'button-style'
                "
              >
                Appointments
              </div></v-btn
            >
            <v-btn v-if="loggedIn()" text to="/chats"
              ><div
                :class="
                  currentRouteName.startsWith('/chat')
                    ? 'button-style-underlined'
                    : 'button-style'
                "
              >
                <v-badge
                  v-if="chatNotifications > 0"
                  color="red"
                  :content="chatNotifications"
                  :value="chatNotifications"
                >
                  Chats
                </v-badge>
                <div v-if="!chatNotifications">
                  Chats
                </div>
              </div></v-btn
            >
            <v-btn
              v-if="loggedIn()"
              text
              :to="
                isProfessional() ? '/user/' + getUsername() : '/profile/member'
              "
              ><div
                :class="
                  currentRouteName.startsWith('/profile/member') ||
                  currentRouteName.startsWith('/user')
                    ? 'button-style-underlined'
                    : 'button-style'
                "
              >
                {{ isProfessional() ? 'My Profile' : 'My Filters' }}
              </div></v-btn
            >
            <v-btn text to="/products"
              ><div
                :class="
                  currentRouteName.startsWith('/products')
                    ? 'button-style-underlined'
                    : 'button-style'
                "
              >
                Products
              </div>
            </v-btn>
            <v-btn text :to="loggedIn() ? '/logout' : '/login'"
              ><div
                :class="
                  currentRouteName.startsWith('/log')
                    ? 'button-style-underlined'
                    : 'button-style'
                "
              >
                {{ loggedIn() ? 'Logout' : 'Login' }}
              </div></v-btn
            >
          </v-toolbar-items>
        </div>

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="hidden-md-and-up"
              :ripple="false"
              icon
              text
              v-bind="attrs"
              v-on="on"
            >
              <v-badge
                color="red"
                :content="menuNotifications"
                :value="menuNotifications"
              >
                <img
                  id="image-6-6"
                  alt=""
                  src="https://oxyset.com/design/headersvol1/wp-content/uploads/2019/07/Open.svg"
                  class="ct-image icon-open"
                />
              </v-badge>
            </v-btn>
          </template>

          <v-list>
            <!--v-list-item to="/home">
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-->
            <v-list-item v-if="!loggedIn()" to="/register/member">
              <v-list-item-title>Sign Up</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!isProfessional()" to="/search">
              <v-list-item-title>Search Professionals</v-list-item-title>
            </v-list-item>
            <!--v-list-item to="/how-it-works">
              <v-list-item-title>How it Works</v-list-item-title>
            </v-list-item-->
            <v-list-item v-if="!loggedIn()" to="/register/professional">
              <v-list-item-title>Join as a Professional</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!loggedIn()"
              href="https://dashboard.wholelifegoals.com/how-it-works/"
            >
              <v-list-item-title>How it Works</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isAdmin()" to="/admin">
              <v-list-item-title>Admin</v-list-item-title>
            </v-list-item>
            <v-list-item to="/appointment/list" v-if="loggedIn()">
              <v-list-item-title>Appointments</v-list-item-title>
            </v-list-item>
            <v-list-item to="/chats" v-if="loggedIn()">
              <v-list-item-title>
                <v-badge
                  v-if="chatNotifications > 0"
                  color="red"
                  :content="chatNotifications"
                  :value="chatNotifications"
                >
                  Chats
                </v-badge>
                <div v-if="chatNotifications == 0">
                  Chats
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="loggedIn()"
              :to="
                isProfessional() ? '/user/' + getUsername() : '/profile/member'
              "
            >
              <v-list-item-title>
                {{
                  isProfessional() ? 'My Profile' : 'My Filters'
                }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item to="/products">
              <v-list-item-title>Products</v-list-item-title>
            </v-list-item>
            <v-list-item :to="loggedIn() ? '/logout' : '/login'">
              <v-list-item-title>{{
                loggedIn() ? 'Logout' : 'Login'
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <keep-alive include="Search">
        <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
      <!--SocialMenu /-->
    </v-main>
  </v-app>
</template>

<script>
//import Matching from './components/Matching';
//import Tags from './components/Tags';
/* eslint-disable no-debugger */

//import SocialMenu from './components/SocialMenu';
import loginStore from '@/util/loginStore';

import notificationStore from '@/sockets/notificationStore';

export default {
  name: 'App',

  components: {
    //SocialMenu,
    //Matching,
    //Tags,
  },

  data: () => ({
    chatNotifications: 0,
    menuNotifications: 0,
    //
  }),
  computed: {
    grouped() {
      return notificationStore.grouped;
    },
    currentRouteName() {
      return this.$route.path;
    },
  },
  methods: {
    loggedIn() {
      return loginStore.loggedIn;
    },
    isAdmin() {
      return loginStore.isAdmin;
    },
    getUsername() {
      return loginStore.username;
    },
    isProfessional() {
      return loginStore.isProfessional;
    },
  },
  watch: {
    grouped(notifications) {
      let chatNotifications = 0;
      for (let index = 0; index < notifications.length; index++) {
        const notification = notifications[index];
        if (notification.notificationType == 'chat') {
          chatNotifications++;
        }
      }
      this.chatNotifications = chatNotifications;
      this.menuNotifications = chatNotifications;
    },
  },
};
</script>
<style>
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
.main-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.main {
  /*box-shadow: none !important; */
  padding: 20px !important;
  height: auto !important;
}
.hidden-sm-and-down {
  height: auto !important;
}
.menu {
  font-size: 16px !important;
  text-transform: inherit !important;
}
.v-btn {
  font-size: 16px !important;
  text-transform: inherit !important;
  font-family: 'Nunito' !important;
  letter-spacing: unset !important;
  line-height: 1.6 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: auto !important;
}
.v-btn:before {
  opacity: 0 !important;
  color: #000000 !important;
}
.button-style {
  border-bottom: #ffffff 2px solid !important;
  padding-bottom: 8px !important;
}
.button-style-underlined {
  border-bottom: #000000 2px solid !important;
  padding-bottom: 8px !important;
}
.button-style:hover {
  border-bottom: #000000 2px solid !important;
  padding-bottom: 8px !important;
}
.v-ripple__container {
  opacity: 0 !important;
  height: auto !important;
}
.v-toolbar__content {
  height: auto !important;
  padding: 10px 16px 4px 0px !important;
}
.v-application .success-green {
  background-color: #428751 !important;
  color: #f9fafb !important;
  font-weight: 700 !important;
  letter-spacing: 1.5px !important;
}
.greenText {
  color: #428751 !important;
}

.v-application .success-red {
  background-color: #c94646 !important;
  color: #f9fafb !important;
  font-weight: 700 !important;
  letter-spacing: 1.5px !important;
}
.v-application .success-gray {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: #f9fafb !important;
  font-weight: 700 !important;
  letter-spacing: 1.5px !important;
}
.v-application .main-btn {
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  text-align: left;
  font-weight: 700;
  border-radius: 20px;
  flex-direction: unset;
  display: inline-block;
  letter-spacing: 1.5px;
}
.v-btn__content {
  height: 100%;
}
</style>
