<template>
  <v-container>
    <v-row class="main-container">
      <v-col>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <h2 class="title mb-2">
              Please enter your email address and we will send you reset
              password link
            </h2>

            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                placeholder="E-mail"
                required
              ></v-text-field>

              <v-alert v-if="error" type="error">
                {{ error }}
              </v-alert>
              <v-alert v-if="sent" type="success">
                Password sent!
              </v-alert>
              <v-btn
                v-if="!sent"
                class="mr-4 main-btn success-green"
                @click="send"
              >
                Send
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */

import API from '../../api';

export default {
  name: 'Login',

  data: () => ({
    error: null,
    valid: false,
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    sent: false,
  }),
  mounted() {
    document.title = 'Forgot Password';
  },
  created: async function() {},
  methods: {
    async send() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let res = await API.post('forgot-password', {
        email: this.email,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        this.sent = false;
      } else {
        this.error = null;
        this.sent = true;
      }
    },
  },
  computed: {},
};
</script>
<style>
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
.v-application .title {
  font-family: 'Nunito', sans-serif !important;
}
</style>
