<template>
  <v-container>
    <v-card flat>
      <v-card outlined>
        <v-card-text class="text-center">
          <div class="greenText" style="font-size:18px;">
            Here are a few products we Love at<br />Whole Life Goals.
          </div>
        </v-card-text>
      </v-card>
      <v-tabs color="black" centered>
        <v-tab
          @click="currentCategory = category"
          v-for="category in categoryProductsList"
          :key="category.abbreviation"
        >
          {{ category.name }}
        </v-tab>
      </v-tabs>
      <template v-for="subcategory in currentCategory.products">
        <div :key="subcategory.id">
          <v-card-title class="greenText">
            {{ subcategory.name }}
          </v-card-title>
          <v-card-text>
            <div style="width:100%; display:flex; justify-content: left">
              <ViewProduct
                v-for="product in subcategory.products"
                :product="product"
                :key="product.uuid"
              />
            </div>
          </v-card-text>
        </div>
      </template>
    </v-card>
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
  </v-container>
</template>
<script>
/* eslint-disable no-debugger */
import { formatName } from '@/util/stringUtils';

import API from '../../api';
import productCategoryJSON from '../../../data/productCategories.json';
import ViewProduct from './ViewProduct';

export default {
  name: 'public-products-list',
  props: {},
  data: () => ({
    currentCategory: {},
    products: [],
    categoryProductsList: [],
    error: null,
  }),
  components: {
    ViewProduct,
    //Tags,
  },
  created: function() {
    this.loadData(this.tab);
  },
  mounted() {
    document.title = 'Products';
  },
  watch: {
    tab: {
      handler(value) {
        this.loadData(value);
      },
      deep: true,
    },
  },
  methods: {
    formatName(name) {
      return formatName(name);
    },
    async loadData() {
      let res = await API.get('product/public');
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        return;
      }
      this.products = res.data.products;
      let categoryProductsList = [];
      let categories = this.categories();
      for (let index = 0; index < categories.length; index++) {
        const category = categories[index];
        let categoryProducts = {
          name: category.name,
          abbreviation: category.abbreviation,
          products: [],
        };
        for (let index = 0; index < category.subcategories.length; index++) {
          let subcategory = category.subcategories[index];
          let products = this.categoryProducts(
            category.abbreviation,
            subcategory.abbreviation
          );
          //if (products && products.length) {
          let subcategoryProducts = {
            name: subcategory.name,
            abbreviation: subcategory.abbreviation,
            products: products,
          };
          categoryProducts.products.push(subcategoryProducts);
          // }
        }
        //if (categoryProducts.products.length) {
        categoryProductsList.push(categoryProducts);
        //}
      }
      this.categoryProductsList = categoryProductsList;
      this.currentCategory = this.categoryProductsList[0];
    },
    categoryProducts(category, subcategory) {
      let res = [];
      for (let index = 0; index < this.products.length; index++) {
        const product = this.products[index];
        if (
          product.category === category &&
          product.subcategory === subcategory
        ) {
          res.push(product);
        }
      }
      return res;
    },
    subcategories() {
      let categories = [];
      if (this.currentCategory) {
        for (let index = 0; index < productCategoryJSON.length; index++) {
          const category = productCategoryJSON[index];
          if (category.abbreviation === this.currentCategory.abbreviation) {
            return category.subcategories;
          }
        }
      }
      return categories;
    },
    categories() {
      return productCategoryJSON;
    },
  },
};
</script>
/* eslint-disable no-unused-vars */
