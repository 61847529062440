<template>
  <div style="width:100%; display:flex; justify-content: center;margin:20px">
    <ViewProduct :product="product" />
  </div>
</template>

<script>
/* eslint-disable no-debugger */
import API from '../../api';
import ViewProduct from './ViewProduct';

export default {
  name: 'View',
  props: {
    uuid: {
      type: [String],
    },
  },
  components: {
    ViewProduct,
    //Tags,
  },
  data: () => ({
    product: {},
    //    tags: [],
    //    tagsMap: {},
  }),
  async created() {
    let res = await API.get('product/' + this.uuid);
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      this.product = res.data.product;
    }
  },
  methods: {},
};
</script>

<style>
.dow {
  width: 60px;
  display: inline-block;
}
.dowCheckbox {
  margin-top: 0px;
}
</style>
