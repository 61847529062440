<template>
  <div>
    <h1 class="title mb-2">
      {{ title ? title : currentQuestion.text }}
    </h1>
    <v-row
      style="text-transform: uppercase"
      v-if="currentQuestion.id == questionWhat"
      no-gutters
      class="hidden-sm-and-down"
    >
    </v-row>
    <v-chip-group
      :class="hasDetails(currentQuestion.answers) ? 'aa' : ''"
      column
      v-model="currentAnswers"
      :multiple="currentQuestion.multiple"
      active-class="primary--text"
    >
      <v-row v-if="currentQuestion.id == questionWhat" no-gutters>
        <v-col
          :key="index2"
          v-for="(category, index2) in categories"
          md="3"
          align="center"
          justify="center"
        >
          <div
            style="text-transform: uppercase; font-size:16px;"
            class="ma-4 greenText"
          >
            <b>
              {{ category.name }}
            </b>
          </div>
          <div
            v-for="(answer, index) in answersForCategory(
              currentQuestion.answers,
              category
            )"
            :key="tagId(index)"
            class="ml-1 mr-1"
          >
            <v-chip
              outlined
              style="width:100%;font-size:12px !important;justify-content:center !important"
              :filter="currentQuestion.multiple"
              @click="selectAnswer"
              color="#428751"
              class="text-center"
            >
              {{ chipTitle(answer.text) }}
            </v-chip>
            <br />
            <span :class="chipSelected(index) ? 'greenText' : ''"
              >{{ chipDescription(answer.text) }}
            </span>
          </div>
        </v-col>
      </v-row>
      <template v-if="currentQuestion.id != questionWhat">
        <div
          v-for="(answer, index) in currentQuestion.answers"
          :key="tagId(index)"
        >
          <v-chip
            outlined
            :filter="currentQuestion.multiple"
            @click="selectAnswer"
            color="#428751"
            style="font-size:12px !important;"
          >
            {{ chipTitle(answer.text) }}
          </v-chip>
          <template v-if="chipDescription(answer.text) != ''">
            <br class="hidden-sm-and-up" />

            <span :class="'pr-1 ' + (chipSelected(index) ? 'greenText' : '')"
              >{{ chipDescription(answer.text) }}
            </span>
          </template>
        </div>
        <br />
      </template>
    </v-chip-group>
    <v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-4 main-btn success-green" @click="save()">
          {{ loggedIn ? 'Save' : 'Next' }}
        </v-btn>

        <v-btn
          v-if="loggedIn"
          @click="cancel()"
          class="mr-4 main-btn"
          outlined
          color="gray"
        >
          Cancel
        </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card-text>
  </div>
</template>

<script>
/* eslint-disable no-debugger */

import questionsStore from '@/components/member/questionsStore';
import loginStore from '../../util/loginStore';

export default {
  name: 'Profile',
  props: {
    currentQuestion: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
  data: () => ({
    //    tags: [],
    error: null,
    currentAnswers: [],
    //selectedAnswersIndexes: {},
    //    tagsMap: {},
  }),
  computed: {
    loggedIn() {
      return loginStore.loggedIn;
    },
    questions() {
      return questionsStore.questions;
    },
    categories() {
      return questionsStore.categories;
    },
    questionWhat() {
      return questionsStore.questionWhat;
    },
    tags() {
      return questionsStore.tags;
    },
    selectedAnswers() {
      return questionsStore.selectedAnswers;
    },
  },
  watch: {
    selectedAnswers: {
      immediate: true,
      handler: function() {
        this.currentAnswers = this.selectedAnswersToCurrentAnswers(
          this.selectedAnswers[this.currentQuestion.id]
        );
      },
    },
  },
  async created() {},
  methods: {
    selectedTags() {
      return questionsStore.selectedTags();
    },
    loadQuestionsAnswers() {
      return questionsStore.loadQuestionsAnswers();
    },
    selectAnswer() {
      /*      setTimeout(() => {
        questionsStore.selectedAnswers = this.selectedAnswers;
      }, 200);
*/
    },
    chipTitle(text) {
      if (text && text.indexOf('(') !== -1) {
        return text.substring(0, text.indexOf('('));
      }
      return text;
    },
    chipDescription(text) {
      if (text && text.indexOf('(') !== -1) {
        return text.substring(text.indexOf('(') + 1, text.length - 1);
      }
      return '';
    },
    chipSelected(index) {
      if (
        (!Array.isArray(this.currentAnswers) && index == this.currentAnswers) ||
        (this.currentAnswers &&
          Array.isArray(this.currentAnswers) &&
          this.currentAnswers.includes(index))
      ) {
        return true;
      } else {
        return false;
      }
    },
    hasDetails(answers) {
      for (let index = 0; index < answers.length; index++) {
        const answer = answers[index];
        if (answer.text.indexOf('(') != -1) {
          return true;
        }
      }
      return false;
    },
    answersForCategory(answers, category) {
      let res = [];
      for (let index = 0; index < answers.length; index++) {
        const answer = answers[index];
        if (answer.tag.includes(category.tagId)) {
          res.push(answer);
        }
      }
      return res;
    },
    answersIndexesForAllCategories(answers) {
      let res = [];
      for (let index2 = 0; index2 < this.categories.length; index2++) {
        let category = this.categories[index2];
        for (let index = 0; index < answers.length; index++) {
          const answer = answers[index];
          if (answer.tag.includes(category.tagId)) {
            res.push(index);
          }
        }
      }
      return res;
    },
    currentAnswersToSelectedAnswers(currentAnswers) {
      if (this.currentQuestion.id != this.questionWhat) {
        return currentAnswers;
      }

      if (!Array.isArray(currentAnswers)) {
        currentAnswers = [currentAnswers];
      }
      let res = [];
      let answersIndexes = this.answersIndexesForAllCategories(
        this.currentQuestion.answers
      );
      for (let index = 0; index < currentAnswers.length; index++) {
        const element = currentAnswers[index];
        if (!res.includes(answersIndexes[element])) {
          res.push(answersIndexes[element]);
        }
      }
      return res;
    },
    selectedAnswersToCurrentAnswers(selectedAnswers) {
      if (this.currentQuestion.id != this.questionWhat) {
        return selectedAnswers;
      }

      if (!Array.isArray(selectedAnswers)) {
        selectedAnswers = [selectedAnswers];
      }
      let res = [];
      let answersIndexes = this.answersIndexesForAllCategories(
        this.currentQuestion.answers
      );
      for (let index = 0; index < answersIndexes.length; index++) {
        const element = answersIndexes[index];
        if (selectedAnswers.includes(element)) {
          res.push(index);
        }
      }
      return res;
    },
    async save() {
      this.selectedAnswers[
        this.currentQuestion.id
      ] = this.currentAnswersToSelectedAnswers(this.currentAnswers);
      await questionsStore.save();
      this.$emit('nextSection');
    },
    cancel() {
      this.$emit('cancelAskQuestion');
    },
    tagId(index) {
      return index + 100000 * this.currentQuestion.id;
    },
  },
};
</script>

<style>
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.aa .v-slide-group__content {
  flex-direction: column;
}
</style>
