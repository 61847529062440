import config from '@/config';
import { setTimeout } from 'core-js';
import loginStore from '@/util/loginStore';

/* eslint-disable no-debugger */

class Socket {
  CONNECT = 'connect';
  AUTH = 'auth';
  CHAT = 'chat';
  CHAT_MESSAGE_DELETE = 'chat-message-delete';
  NOTIFICATIONS_READ = 'notifications-read';

  constructor() {
    this.connect();
    this.listeners = [];
  }

  receiveMessage(type, payload, notifications) {
    for (let index = 0; index < this.listeners.length; index++) {
      const listener = this.listeners[index];
      listener(type, payload, notifications);
    }
  }

  connect() {
    let $this = this;
    $this.ws = new WebSocket(config.socketUrl());
    $this.ws.onopen = function() {
      console.log('ws openned');
      $this.receiveMessage($this.CONNECT, { connected: true });
    };

    $this.ws.onmessage = function(e) {
      let message = JSON.parse(e.data);
      console.log(message);
      $this.receiveMessage(
        message.type,
        message.payload,
        message.notifications
      );
    };

    $this.ws.onclose = function() {
      setTimeout(function() {
        $this.connect();
      }, 1000);
    };

    $this.ws.onerror = function(err) {
      console.error(
        'Socket encountered error: ',
        err.message,
        'Closing socket'
      );
      $this.ws.close();
    };
  }
  authenticate() {
    const token = loginStore.jwt;
    this.sendSocketNotification(this.AUTH, { token });
  }

  sendSocketNotification(type, payload) {
    try {
      this.ws.send(JSON.stringify({ type, payload }));
    } catch (e) {
      setTimeout(() => {
        this.sendSocketNotification(type, payload);
      }, 1000);
    }
  }

  addListener(listener) {
    this.listeners.push(listener);
  }
  removeListener(listener) {
    let index = this.listeners.indexOf(listener);
    if (index > -1) {
      this.listeners.splice(index, 1);
    }
  }
}

export default new Socket();
