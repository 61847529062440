<template>
  <v-card>
    <v-card>
      <v-card-text>
        <div>
          <h2 style="margin-bottom:12px;">Link</h2>
          <v-text-field
            v-model="link"
            placeholder="https://YourWebsite.com/product"
          ></v-text-field>
          <h2 style="margin-bottom:12px;">Title</h2>
          <v-text-field v-model="title" placeholder="title"></v-text-field>
          <h2 style="margin-bottom:12px;">Description</h2>
          <v-text-field
            v-model="description"
            placeholder="description"
          ></v-text-field>

          <h2 style="margin-bottom:12px;">Category</h2>
          <v-select
            item-text="name"
            item-value="abbreviation"
            :items="categories()"
            v-model="category"
          >
            <template v-slot:append>
              <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
            </template></v-select
          >
          <h2 style="margin-bottom:12px;">Subcategory</h2>
          <v-select
            item-text="name"
            item-value="abbreviation"
            :items="subcategories(category)"
            v-model="subcategory"
          >
            <template v-slot:append>
              <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
            </template>
          </v-select>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-4 main-btn success-green" text @click="save()">
          Save
        </v-btn>
        <v-btn
          class="mr-4 main-btn"
          outlined
          color="gray"
          text
          @click="cancel()"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-alert v-if="error" type="error">
        {{ error }}
      </v-alert>
    </v-card>
  </v-card>
</template>

<script>
/* eslint-disable no-debugger */
import API from '../../api';
import productCategoryJSON from '../../../data/productCategories.json';

export default {
  name: 'WorkHour',
  props: {
    product: {
      type: [Object],
    },
  },
  components: {
    //Matching,
    //Tags,
  },
  data: () => ({
    //    tags: [],
    error: null,
    link: '',
    category: '',
    subcategory: '',
    title: '',
    description: '',
    //    tagsMap: {},
  }),
  watch: {
    category(newCategory) {
      if (!this.subcategory || this.subcategory == '') {
        return;
      }
      if (!newCategory || newCategory == '') {
        return;
      }
      let categories = this.subcategories(newCategory);
      let found = false;
      for (let index = 0; index < categories.length; index++) {
        const subcategory = categories[index];
        if (subcategory.abbreviation == this.subcategory) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.subcategory = null;
      }
    },
  },
  async created() {
    this.category = this.product.category;
    this.subcategory = this.product.subcategory;
    this.link = this.product.link;
    this.title = this.product.title;
    this.description = this.product.description;
  },
  methods: {
    subcategories(categoryAbbrevation) {
      let categories = [];
      for (let index = 0; index < productCategoryJSON.length; index++) {
        const category = productCategoryJSON[index];
        if (category.abbreviation == categoryAbbrevation) {
          return category.subcategories;
        }
      }
      return categories;
    },
    categories() {
      return productCategoryJSON;
    },
    async save() {
      let res = await API.post('product', {
        id: this.product.id,
        category: this.category,
        link: this.link,
        subcategory: this.subcategory,
        title: this.title,
        description: this.description,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        this.error = null;
        this.$emit('saved');
      }
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.dow {
  width: 60px;
  display: inline-block;
}
.dowCheckbox {
  margin-top: 0px;
}
</style>
