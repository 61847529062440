<template>
  <v-container>
    <v-row class="main-container">
      <v-col>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <h2 class="title mb-2">
              Enter new password
            </h2>

            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="password"
                :rules="[passwordRules.req, passwordRules.min]"
                name="input-10-1"
                placeholder="Password"
                hint="At least 8 characters"
                type="password"
                counter
              ></v-text-field>

              <v-text-field
                v-model="confirmPassword"
                :rules="[
                  passwordRules.req,
                  passwordRules.min,
                  passwordConfirmationRule,
                ]"
                name="input-10-1"
                placeholder="Confirm Password"
                hint="At least 8 characters"
                type="password"
                counter
              ></v-text-field>
              <v-alert v-if="error" type="error">
                {{ error }}
              </v-alert>
              <v-alert v-if="sent" type="success">
                Password changed!
              </v-alert>
              <v-btn class="mr-4 main-btn success-green" @click="register">
                Reset
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */

import API from '../../api';

export default {
  name: 'ResetPassword',

  data: () => ({
    //    tags: [],
    error: null,
    valid: false,
    password: '',
    confirmPassword: '',
    passwordRules: {
      req: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
    },
    checkbox: false,
    sent: false,
  }),
  created: async function() {},
  mounted() {
    document.title = 'Reset Password';
  },
  methods: {
    async register() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let res = await API.post('reset-password', {
        hash: this.$route.query.hash,
        email: this.$route.query.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        this.sent = false;
      } else {
        this.error = null;
        this.sent = true;
        setTimeout(() => {
          this.$router.push('login');
        }, 1000);
      }
    },
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || 'Passwords must match';
    },
  },
};
</script>
<style>
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
</style>
