// store.js
import Vue from 'vue';
import API from '../../api';
import loginStore from '../../util/loginStore';

/* eslint-disable no-debugger */

let questionsStore = Vue.observable({
  categories: [],
  questions: [],
  questionWhat: -1,
  questionsAnswers: [],
  selectedAnswers: {},
  saveDate: null,
  shownQuestions: [],
  async created() {
    if (this.questions && this.questions.length > 0) {
      return;
    }
    let res = await API.get('profile?noAuth=true');
    if (!res) {
      return;
    }
    this.questions = [];
    this.questionsAnswers = [];
    this.selectedAnswers = {};
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      if (
        res.data.user &&
        res.data.user.other &&
        res.data.user.other.questionsAnswers
      ) {
        this.questionsAnswers = res.data.user.other.questionsAnswers;
      }
    }
    res = await API.get('tags');
    if (res.data) {
      this.tags = res.data.tags;
      this.categories = res.data.categories;
    }
    res = await API.get('questions');
    if (res.data) {
      this.questions = res.data.questions;
      this.questionWhat = res.data.what;
      var newSelectedAnswers = {};
      for (let index = 0; index < this.questions.length; index++) {
        const question = this.questions[index];
        for (let index2 = 0; index2 < this.questionsAnswers.length; index2++) {
          const questionAnswer = this.questionsAnswers[index2];
          if (questionAnswer.question == question.text) {
            //let answers = [];
            for (let index3 = 0; index3 < question.answers.length; index3++) {
              let answer = question.answers[index3];
              if (questionAnswer.answer == answer.text) {
                if (question.multiple) {
                  if (!newSelectedAnswers[question.id]) {
                    newSelectedAnswers[question.id] = [];
                  }
                  newSelectedAnswers[question.id].push(index3);
                } else {
                  newSelectedAnswers[question.id] = index3;
                }
              }
            }
          }
          if (question.multiple && newSelectedAnswers[question.id]) {
            newSelectedAnswers[question.id].sort();
          }
        }
      }
      this.selectedAnswers = newSelectedAnswers;
      this.shownQuestions = this.shownQuestionsList();
    }
  },
  selectedTags() {
    let tags = [];
    let keys = Object.keys(this.selectedAnswers);
    for (let index2 = 0; index2 < keys.length; index2++) {
      const key = keys[index2];

      let selectedAnswersToQuestion = this.selectedAnswers[key];
      let question;
      for (let index = 0; index < this.questions.length; index++) {
        const currentQuestion = this.questions[index];
        if (currentQuestion.id == key) {
          question = currentQuestion;
          break;
        }
      }

      if (typeof selectedAnswersToQuestion !== 'undefined') {
        if (Array.isArray(selectedAnswersToQuestion)) {
          for (
            let index2 = 0;
            index2 < selectedAnswersToQuestion.length;
            index2++
          ) {
            const answer = question.answers[selectedAnswersToQuestion[index2]];
            for (let index6 = 0; index6 < answer.tag.length; index6++) {
              const element = answer.tag[index6];
              tags.push(element);
            }
          }
        } else {
          const answer = question.answers[selectedAnswersToQuestion];
          for (let index6 = 0; index6 < answer.tag.length; index6++) {
            const element = answer.tag[index6];
            tags.push(element);
          }
        }
      }
    }
    return tags;
  },
  loadQuestionsAnswers() {
    let res = [];
    let keys = Object.keys(this.selectedAnswers);
    for (let index2 = 0; index2 < keys.length; index2++) {
      const key = keys[index2];

      let selectedAnswersToQuestion = this.selectedAnswers[key];
      let question;
      for (let index = 0; index < this.questions.length; index++) {
        const currentQuestion = this.questions[index];
        if (currentQuestion.id == key) {
          question = currentQuestion;
          break;
        }
      }

      let answer = null;

      if (typeof selectedAnswersToQuestion !== 'undefined') {
        if (Array.isArray(selectedAnswersToQuestion)) {
          for (
            let index2 = 0;
            index2 < selectedAnswersToQuestion.length;
            index2++
          ) {
            answer = question.answers[selectedAnswersToQuestion[index2]];
            res.push({ question: question.text, answer: answer.text });
          }
        } else {
          answer = question.answers[selectedAnswersToQuestion];
          res.push({ question: question.text, answer: answer.text });
        }
      }
    }
    return res;
  },
  shownQuestionsList() {
    let selectedTags = this.selectedTags();
    let selectedTagsMap = {};
    for (let index = 0; index < selectedTags.length; index++) {
      const tag = selectedTags[index];
      selectedTagsMap[tag] = tag;
    }

    let res = [];
    for (let index = 0; index < this.questions.length; index++) {
      const question = this.questions[index];

      if (
        !question.hidden &&
        (!question.ifTagId || selectedTagsMap[question.ifTagId])
      )
        res.push(question);
    }
    return res;
  },

  selectAnswer() {
    setTimeout(() => {
      if (!this.currentQuestion().multiple) {
        this.next();
      }
    }, 500);
  },
  emitSelectedTags() {
    let questionsAnswers = this.loadQuestionsAnswers();
    this.$emit('selectedTags', {
      questionsAnswers,
      selectedTags: this.selectedTags(),
    });
  },
  async save() {
    let questionsAnswers = this.loadQuestionsAnswers();
    let selectedTags = this.selectedTags();
    if (loginStore.loggedIn) {
      await API.post('edit-search-info', {
        questionsAnswers,
        searchTags: selectedTags,
      });
    }

    this.questionsAnswers = questionsAnswers;

    this.saveDate = new Date();
    this.shownQuestions = this.shownQuestionsList();
  },
  async questionsAnswersPrepared(questionAnswers) {
    let res = [];
    for (let index = 0; index < this.questions.length; index++) {
      const question = this.questions[index];
      for (let index2 = 0; index2 < questionAnswers.length; index2++) {
        var questionAnswer = questionAnswers[index2];
        let questionText = questionAnswer.question;
        let answerText = questionAnswer.answer;
        if (questionText == question.text) {
          for (let index3 = 0; index3 < question.answers.length; index3++) {
            let answer = question.answers[index3];
            if (answerText == answer.text) {
              if (
                res.length == 0 ||
                res[res.length - 1].question.id != question.id
              ) {
                res.push({ question, answers: [] });
              }
              res[res.length - 1].answers.push(answerText);
            }
          }
        }
      }
    }
    return res;
  },
});
export default questionsStore;
