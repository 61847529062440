<template>
  <a
    @click="details()"
    style="border:1px solid #000000; width: 100px;min-height: 120px;margin:5px"
  >
    <v-card flat v-if="product">
      <v-img
        :src="
          product.thumbnailUrl ? product.thumbnailUrl : '/wlg-logo-110x110.png'
        "
      ></v-img>
      <div
        style="font-size: 12px; font-weight: bold; text-align: center; line-height: 1;
    padding: 5px;"
      >
        {{ product.title }}
      </div>
    </v-card>
    <v-dialog v-model="photoDialog" max-width="600">
      <v-card>
        <v-card-title> {{ product.title }} </v-card-title>
        <v-card-text> {{ product.description }}</v-card-text>
        <v-row>
          <v-col class="d-flex child-flex">
            <v-carousel v-model="carouselIndex" hide-delimiters>
              <v-carousel-item
                v-for="(n, index) in images.length
                  ? images
                  : [{ uuid: 'test', url: '/wlg-logo-110x110.png' }]"
                :key="n.uuid"
                v-html="carouselIndex == index && photoDialog ? html(n) : ''"
              ></v-carousel-item>
            </v-carousel> </v-col
        ></v-row>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="mr-4 main-btn success-green" :href="product.link">
            Buy
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </a>
</template>

<script>
/* eslint-disable no-debugger */
import API from '../../api';
import { parseVideo } from '@/util/video';

export default {
  name: 'View',
  props: {
    product: {
      type: [Object],
    },
  },
  components: {
    //Matching,
    //Tags,
  },
  data: () => ({
    photoDialog: false,
    images: [],
    carouselIndex: 0,

    //    tags: [],
    //    tagsMap: {},
  }),
  async created() {},
  methods: {
    async details() {
      this.photoDialog = true;
      let res = await API.get('product/' + this.product.uuid);
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        this.error = null;
        this.images = res.data.images;
      }
    },
    html(image) {
      if (image.storage == 'Youtube' || image.storage == 'Vimeo') {
        let parsed = parseVideo(image.url);
        let videoCode = parsed.id;
        if (image.storage == 'Youtube') {
          return `<div style="height:100%;width:100%">
              <iframe style="height:100%;width:100%" src="https://www.youtube.com/embed/${videoCode}" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>`;
        } else {
          return `<div style="height:100%;width:100%">
              <iframe style="height:100%;width:100%" src="https://player.vimeo.com/video/${videoCode}" allow="fullscreen"></iframe>
            </div>`;
        }
      } else {
        let res =
          '<a target="_blank" href="' +
          this.product.link +
          '"><image style="height:100%;width:auto; display: block;margin-left: auto;margin-right: auto;" src="' +
          (image.thumbnailUrl ? image.thumbnailUrl : image.url) +
          '"></a>';
        return res;
      }
    },
  },
};
</script>

<style>
.dow {
  width: 60px;
  display: inline-block;
}
.dowCheckbox {
  margin-top: 0px;
}
</style>
