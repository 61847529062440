<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto">
          <v-card-text>
            Title:
            <v-text-field
              v-model="title"
              placeholder="Title"
              required
            ></v-text-field>
            Text:
            <v-text-field
              v-model="text"
              placeholder="Text"
              required
            ></v-text-field>
            If Tag
            <v-autocomplete
              v-model="ifTagId"
              :items="tagsWithNone"
              item-text="text"
              item-value="id"
              placeholder="If tag"
            >
              <template v-slot:append>
                <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
              </template></v-autocomplete
            >

            Hidden:
            <v-checkbox v-model="hidden" label="Hidden"></v-checkbox>

            Multiple:
            <v-checkbox v-model="multiple" label="Multiple"></v-checkbox>
            Answers:
            <v-list two-line>
              <template v-for="(answer, index) in answers">
                <v-list-item :key="answer.id">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-text-field
                        v-model="answers[index].text"
                        placeholder="Text"
                        required
                      ></v-text-field>
                    </v-list-item-title>
                    <v-list-item-title>
                      <v-autocomplete
                        v-model="answers[index].tag"
                        :items="info.tags"
                        chips
                        item-text="text"
                        item-value="id"
                        multiple
                      >
                        <template v-slot:append>
                          <v-icon color="#428751"
                            >mdi-arrow-down-drop-circle</v-icon
                          >
                        </template>
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="removeTag(index, data.item)"
                          >
                            {{ data.item.text }}
                          </v-chip>
                        </template>
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.text"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn @click="deleteAnswer(index)" icon>
                      <v-icon color="grey lighten-1">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
            <div>
              <v-btn color="success" class="mr-4" @click="addAnswer()">
                Add Answer
              </v-btn>
            </div>
          </v-card-text>
          <v-card-text>
            <v-btn color="success" class="mr-4" @click="saveQuestion()">
              Save
            </v-btn>

            <v-dialog
              v-if="questionId"
              v-model="dialog"
              persistent
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="error" class="mr-4" v-bind="attrs" v-on="on">
                  Delete
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">
                  Delete question?
                </v-card-title>
                <v-card-text>This operation can not be undone</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    class="mr-4"
                    text
                    @click="(dialog = false), deleteQuestion()"
                  >
                    Delete
                  </v-btn>
                  <v-btn color="green darken-1" text @click="dialog = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
          <v-alert v-if="error" type="error">
            {{ error }}
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// Import Vue FilePond

/* eslint-disable no-debugger */

import API from '../../api';

/* eslint-disable-next-line  */

export default {
  name: 'Profile',
  props: {
    questionId: {
      type: [String],
    },
  },

  components: {},

  data: () => ({
    error: null,
    info: {},
    tags: {},
    answers: [],
    title: '',
    text: '',
    ifTagId: null,
    multiple: false,
    sent: false,
    dialog: false,
    tagsWithNone: [],
    hidden: false,
  }),
  mounted() {
    document.title = 'Admin Question';
  },
  //LOAD
  created: async function() {
    this.error = null;
    let res = await API.get(
      'admin/question?questionId=' + encodeURI(this.questionId)
    );
    if (!res) {
      return;
    }
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      this.info = res.data.info;
      this.tagsWithNone = [{ id: null, text: '[NONE]' }];
      Array.prototype.push.apply(this.tagsWithNone, this.info.tags);
      if (this.info.question) {
        this.text = this.info.question.text;
        this.title = this.info.question.title;
        this.hidden = this.info.question.hidden;
        this.multiple = this.info.question.multiple;
        this.ifTagId = this.info.question.ifTagId;
        this.answers = this.info.question.answers;
        //if (this.info.question.TagCategories) {
        //  this.selectedCategories = this.info.tag.TagCategories.map(
        //    (tagCategory) => tagCategory.categoryId
        //  );
        //}
      } else {
        this.text = 'Question text';
        this.multiple = true;
        this.answers = [{ tag: [], text: 'First Answer' }];
      }
      console.log(JSON.stringify(this.answers));

      for (let index = 0; index < res.data.info.tags.length; index++) {
        const tag = res.data.info.tags[index];
        this.tags[tag.id] = tag;
      }
    }
  },
  computed: {},
  methods: {
    removeTag(answerIndex, item) {
      const index = this.answers[answerIndex].tag.indexOf(item.id);
      if (index >= 0) this.answers[answerIndex].tag.splice(index, 1);
    },
    deleteAnswer(index) {
      this.answers.splice(index, 1);
    },
    addAnswer() {
      this.answers.push({ tag: [], text: '', multiple: true });
    },
    async saveQuestion() {
      let res = await API.post('admin/question', {
        questionId: this.questionId,
        text: this.text,
        title: this.title,
        hidden: this.hidden,
        multiple: this.multiple,
        ifTagId: this.ifTagId,
        answers: this.answers,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        this.sent = false;
      } else {
        this.error = null;
        this.sent = true;
        this.$router.push('/admin/questions');
      }
    },
    async deleteQuestion() {
      let res = await API.delete('admin/question', {
        questionId: this.questionId,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        this.sent = false;
      } else {
        this.error = null;
        this.sent = true;
        this.$router.push('/admin/questions');
      }
    },
  },
};
</script>

<style></style>
