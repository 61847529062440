<template>
  <div>
    <file-pond
      allow-multiple="true"
      max-files="100"
      credits="false"
      name="file"
      ref="input"
      itemInsertLocation="after"
      :onremovefile="onremovefile"
      :beforeRemoveFile="beforeRemove"
      allowFileTypeValidation="true"
      acceptedFileTypes="image/jpeg, image/png"
      filePosterMaxHeight="100"
      imagePreviewHeight="100"
      :server="{ process, revert, restore, load, fetch: null }"
      :files="files"
      @init="handleFilePondInit"
    />
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import API from '../../api';
import axios from 'axios';

// Import Vue FilePond
import vueFilePond from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// Import the plugin code
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';

// Import the plugin styles
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';

// Register the plugin
// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFilePoster
);
//Vue.use(FilePond);

/* eslint-disable no-debugger */

//import API from '../../api';
let imageFiles = {};

/* eslint-disable-next-line  */
export default {
  name: 'Library',
  props: {
    product: {
      type: [Object],
    },
  },

  components: {
    FilePond,
    //Matching,
    //Tags,
  },

  data: () => ({
    files: [],
    images: [],
  }),
  //LOAD
  created: async function() {
    this.error = null;
    let res = await API.get('product/' + this.product.uuid);
    if (!res) {
      return;
    }
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;

      this.images = res.data.images;
    }
  },
  mounted() {
    document.title = 'Edit Product Images';
  },
  methods: {
    process(fieldName, file, metadata, load, error, progress, abort) {
      // set data
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.set('fileType', 'product');
      formData.set('otherObjectId', this.product.id);

      // related to aborting the request
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // the request itself
      axios({
        method: 'post',
        url: `${API.backendUrl}/file/upload`,
        data: formData,
        cancelToken: source.token,
        onUploadProgress: (e) => {
          // updating progress indicator
          progress(e.lengthComputable, e.loaded, e.total);
        },
      })
        .then((response) => {
          // passing the file id to FilePond
          load(response.data.data.uuid);
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            console.log('Request canceled', thrown.message);
          } else {
            // handle error
          }
        });

      // Setup abort interface
      return {
        abort: () => {
          source.cancel('Operation canceled by the user.');
        },
      };
    },
    async onremovefile(err, file) {
      if (file && file.serverId) {
        this.revert(file.serverId);
      }
    },
    async revert(uniqueFileId, load, error) {
      try {
        let res = await API.post('file/delete', {
          uuid: uniqueFileId,
        });
        if (load) {
          load();
        }
      } catch {
        if (error) {
          error();
        }
      }
    },
    beforeRemove: (file) => {},
    load: (uniqueFileId, load, error, progress, abort, headers) => {
      let file = imageFiles[uniqueFileId];
      fetch(file.url)
        .then((res) => {
          console.log(res);
          return res.blob();
        })
        .then(load);
    },
    fetch(url, load, error, progress, abort, headers) {
      error('not supported');
    },
    restore(uniqueFileId, load, error, progress, abort, headers) {
      error();
    },
    handleError(error, e) {
      switch (e.code) {
        case 'storage/canceled':
          break;
        default:
          error(e.message);
      }
    },
    handleFilePondInit: function() {
      //this.$refs.input.getFiles();
    },
  },
  watch: {
    images: {
      immediate: true,
      handler: function(images, oldVal) {
        let files = [];

        for (let index = 0; index < images.length; index++) {
          const image = images[index];
          imageFiles[image.uuid] = image;
          let file = {
            // the server file reference
            source: image.uuid,

            // set type to local to indicate an already uploaded file
            options: {
              type: 'local',
            },
          };
          files.push(file);
        }
        this.files = files;
      },
    },
  },
};
</script>

<style>
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.filepond--item {
  width: calc(50% - 0.5em);
}
</style>
