<template>
  <div>
    <v-tabs>
      <v-tab @click="sessionType = 'standard'">
        Standard
      </v-tab>
      <v-tab @click="sessionType = 'free15'">
        Free 15 min session
      </v-tab>
    </v-tabs>
    <v-list>
      <v-list-item
        v-for="(workHour, index) in workHours"
        :key="'range' + index"
        style="margin-bottom:12px;"
        @click="workHourClicked(index)"
      >
        <v-list-item-content>
          <div>
            <h2 style="margin-bottom:12px;">{{ dayTitle(workHour) }}</h2>
            {{ hoursText(workHour) }}
          </div>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            :disabled="workHour.dayType !== 'Custom'"
            @click.stop="deleteDayConfirmation(workHour)"
            icon
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn></v-list-item-action
        >
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-btn class="mr-4 main-btn success-green" text @click="addCustomDate()">
        Add Custom Date
      </v-btn>
    </v-card-actions>
    <v-dialog v-if="dialog" v-model="dialog" max-width="400">
      <WorkHour
        :workHour="currentWorkHour"
        @saved="hoursClosed"
        @close="dialog = false"
    /></v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Delete day?
        </v-card-title>
        <v-card-text>This operation can not be undone</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="mr-4"
            text
            @click="(dialogDelete = false), deleteDay()"
          >
            Delete
          </v-btn>
          <v-btn color="green darken-1" text @click="dialogDelete = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-debugger */
import moment from 'moment';
import API from '../../api';
import WorkHour from './WorkHour';

export default {
  name: 'WorkingDays',
  props: {},
  components: {
    WorkHour,
    //Matching,
    //Tags,
  },
  data: () => ({
    error: null,
    sessionType: 'standard',
    workHours: [],
    currentWorkHour: {},
    dialog: false,
    dialogDelete: false,
  }),
  async created() {
    this.loadData(this.sessionType);
  },
  mounted() {
    document.title = 'Edit Working Days';
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData(to.params.sessionType);
    next();
  },
  watch: {
    sessionType(val) {
      this.loadData(val);
    },
  },
  methods: {
    sessionTypeFixed(sessionType) {
      if (
        !sessionType ||
        sessionType == 'standard' ||
        sessionType == 'Standard'
      ) {
        sessionType = 'Standard';
      } else {
        sessionType = 'Free15';
      }
      return sessionType;
    },
    async loadData(sessionType) {
      sessionType = this.sessionTypeFixed(sessionType);

      this.workHours = [];
      let res = await API.get('workhour?sessionType=' + sessionType);
      this.workHours = this.parseHours(res.data.workHours);
      //sdfsd;
    },
    findDayOfType(hours, dayType) {
      for (let index = 0; index < hours.length; index++) {
        const element = hours[index];
        if (element.dayType === dayType) {
          return element;
        }
      }
      return null;
    },
    parseHours(hours) {
      let res = [];
      res.push(this.findDayOfType(hours, 'Mon'));
      res.push(this.findDayOfType(hours, 'Tue'));
      res.push(this.findDayOfType(hours, 'Wed'));
      res.push(this.findDayOfType(hours, 'Thu'));
      res.push(this.findDayOfType(hours, 'Fri'));
      res.push(this.findDayOfType(hours, 'Sat'));
      res.push(this.findDayOfType(hours, 'Sun'));
      let res2 = [];
      for (let index = 0; index < hours.length; index++) {
        const element = hours[index];
        if (element.dayType === 'Custom') {
          res2.push(element);
        }
      }
      res2.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      return res.concat(res2);
    },
    hoursText(workHour) {
      if (workHour.work) {
        let start = moment();
        start.set({ hour: workHour.start / 60, minute: workHour.start % 60 });
        let end = moment();
        end.set({ hour: workHour.end / 60, minute: workHour.end % 60 });
        let res = start.format('LT') + '-' + end.format('LT');
        if (workHour.lunch && workHour.sessionType != 'Free15') {
          let start = moment();
          start.set({
            hour: workHour.lunchStart / 60,
            minute: workHour.lunchStart % 60,
          });
          let end = moment();
          end.set({
            hour: workHour.lunchEnd / 60,
            minute: workHour.lunchEnd % 60,
          });
          res = res + ' Lunch: ' + start.format('LT') + '-' + end.format('LT');
        }
        return res;
      } else {
        return 'Not available';
      }
      //ange[1];
    },
    workHourClicked(index) {
      this.currentWorkHour = Object.assign({}, this.workHours[index]);
      this.dialog = true;
    },
    addCustomDate() {
      this.currentWorkHour = {
        dayType: 'Custom',
        work: true,
        start: 9 * 60,
        end: 18 * 60,
        lunch: true,
        lunchStart: 13 * 60,
        lunchEnd: 14 * 60,
        sessionType: this.sessionTypeFixed(this.sessionType),
        date: moment(new Date()).format('YYYY-MM-DD'),
      };
      this.dialog = true;
    },
    async hoursClosed() {
      await this.loadData(this.sessionType);
      this.dialog = false;
    },
    dayTitle(workHour) {
      if (workHour.dayType === 'Custom') {
        return moment(workHour.date).format('YYYY/MM/DD');
      } else {
        return workHour.dayType;
      }
    },
    async deleteDay() {
      if (this.currentWorkHour.id) {
        await API.delete('workhour', { id: this.currentWorkHour.id });
        await this.loadData(this.sessionType);
      }
    },
    deleteDayConfirmation(workHour) {
      this.currentWorkHour = workHour;
      this.dialogDelete = true;
    },
  },
};
</script>

<style>
.dow {
  width: 60px;
  display: inline-block;
}
.dowCheckbox {
  margin-top: 0px;
}
</style>
