<template>
  <v-container>
    <v-row class="main-container">
      <v-col>
        <v-card class="mx-auto" max-width="1000">
          <v-card-text>
            <v-avatar size="140" class="ml-3">
              <img :src="profile.profileImageUrl" />
            </v-avatar>
            <div>
              <h2 class="ml-3">
                {{ formatName(profile.name) }}
              </h2>
              <h2 class="ml-3">
                {{
                  profile.other && profile.other.pni
                    ? profile.other.pni + ' '
                    : ''
                }}
              </h2>
            </div>
          </v-card-text>
          <v-card-text class="ml-3">
            <v-checkbox
              v-model="free15"
              label="Schedule a Free 15 minute phone or video chat before your appointment.

Please note that not every Professional will offer a free 15 minute phone call."
            ></v-checkbox>
          </v-card-text>
          <v-card-text class="ml-3">
            Select appointment date
          </v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-card-text>
                <v-date-picker
                  elevation="3"
                  full-width
                  v-model="dateString"
                  :allowed-dates="allowedDates"
                  class="mt-4"
                  style="margin-top:0px !important;"
                  :events="events"
                  :picker-date.sync="pickerDate"
                ></v-date-picker>

                <v-alert v-if="error" type="error">
                  {{ error }}
                </v-alert>
                <v-dialog v-model="dialog" max-width="320">
                  <v-card>
                    <v-card-title class="headline">
                      New Appointment:
                    </v-card-title>
                    <v-card-text> Member: You </v-card-text>
                    <v-card-text>
                      Professional: {{ formatName(profile.name) }}
                    </v-card-text>
                    <v-card-text>
                      {{ createEvent ? formatDate(createEvent.start) : '' }}
                    </v-card-text>
                    <v-card-text>
                      {{ createEvent ? formatDate(createEvent.end) : '' }}
                    </v-card-text>

                    <v-card-text>
                      <v-autocomplete
                        label="Location"
                        v-model="selectedPreferredLocation"
                        :items="preferredLocationsFiltered"
                        chips
                        item-text="text"
                        item-value="id"
                        return-object
                      >
                        <template v-slot:append>
                          <v-icon color="#428751"
                            >mdi-arrow-down-drop-circle</v-icon
                          >
                        </template>
                      </v-autocomplete>
                      <v-text-field
                        v-model="address"
                        label="Address"
                        v-if="
                          selectedPreferredLocation &&
                            selectedPreferredLocation.id != 3
                        "
                        :disabled="
                          selectedPreferredLocation &&
                            selectedPreferredLocation.id != 4
                        "
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="mb-2 main-btn success-green"
                        text
                        @click="scheduleAppointment()"
                      >
                        Schedule
                      </v-btn>
                      <v-btn
                        class="mb-2 main-btn"
                        outlined
                        color="gray"
                        text
                        @click="cancelAppointment()"
                      >
                        Cancel
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-alert v-if="errorDialog" type="error">
                      {{ errorDialog }}
                    </v-alert>
                  </v-card>
                </v-dialog>
              </v-card-text>
            </v-col>

            <v-col cols="12" sm="6" v-if="available && available.length > 0">
              <v-card-text>
                <v-card>
                  <v-card-text>
                    <v-list style="height:344px;overflow:scroll">
                      <v-list-item
                        v-for="item in available"
                        :key="item.starts"
                        @click="createAppointment(item)"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="
                              formatDate2(item.start) +
                                '-' +
                                formatDate2(item.end)
                            "
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable no-debugger */

import API from '../../api';
import moment from 'moment';
import { formatName } from '@/util/stringUtils';

const preferredHome = 1;
const preferredClient = 2;
const preferredOnline = 3;
const preferredAgreedUpon = 4;

export default {
  name: 'create-appointment',
  props: {
    username: {
      type: [String],
    },
  },
  data: () => ({
    dateString: null,
    events: [],
    schedule: [],
    pickerDate: null,
    error: '',
    errorDialog: '',
    dialog: false,
    createEvent: null,
    start: null,
    end: null,
    available: [],
    profile: {},
    selfProfile: {},
    hours: [],
    free15: false,
    selectedPreferredLocation: null,
    address: null,
    preferredLocations: [
      { text: "Professional's Location", id: preferredHome, location: 'Home' },
      { text: "Member's Location", id: preferredClient, location: 'Client' },
      { text: 'Web Conferencing', id: preferredOnline, location: 'Online' },
      {
        text: 'Agreed Upon Location',
        id: preferredAgreedUpon,
        location: 'AgreedUpon',
      },
    ],
    preferredLocationsFiltered: [],
  }),
  watch: {
    pickerDate(val) {
      this.updateStartEnd(new Date(val));
    },
    dateString(val) {
      this.loadAvailableTimes(val);
    },
    free15() {
      this.updateStartEnd(new Date(this.pickerDate));
    },
    selectedPreferredLocation(preferredLocation) {
      if (preferredLocation.id === preferredHome) {
        this.address =
          this.profile && this.profile.other
            ? this.profile.other.address
            : null;
      }
      if (preferredLocation.id === preferredClient) {
        this.address =
          this.selfProfile && this.selfProfile.personalInfo
            ? this.selfProfile.personalInfo.address
            : null;
      }
      if (preferredLocation.id === preferredOnline) {
        this.address = null;
      }
      if (preferredLocation.id === preferredAgreedUpon) {
        this.address = '';
      }
    },
  },
  async created() {
    let res = await API.get('profile?username=' + encodeURI(this.username));
    if (!res) {
      return;
    }
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      this.profile = res.data.user;
      let preferredLocationsFiltered = [];
      if (this.profile.preferredHome) {
        preferredLocationsFiltered.push(this.preferredLocations[0]);
      }
      if (this.profile.preferredClient) {
        preferredLocationsFiltered.push(this.preferredLocations[1]);
      }
      if (this.profile.preferredOnline) {
        preferredLocationsFiltered.push(this.preferredLocations[2]);
      }
      if (
        this.profile.preferredAgreedUpon ||
        preferredLocationsFiltered.length === 0
      ) {
        preferredLocationsFiltered.push(this.preferredLocations[3]);
      }
      this.preferredLocationsFiltered = preferredLocationsFiltered;
      if (preferredLocationsFiltered.length === 1) {
        this.selectedPreferredLocation = preferredLocationsFiltered[0];
      }
      this.images = res.data.images;
      res = await API.get('profile');
      if (!res) {
        this.error = 'no response';
      }
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        this.selfProfile = res.data.user;
      }
    }
  },
  mounted() {
    document.title = 'Create Appointment';
  },
  methods: {
    formatName(name) {
      return formatName(name);
    },
    async updateStartEnd(date) {
      this.getEvents(
        moment(date)
          .subtract(1, 'M')
          .toDate(),
        moment(date)
          .add(1, 'M')
          .toDate()
      );
    },
    functionEvents(date) {
      const [, , day] = date.split('-');
      if ([12, 17, 28].includes(parseInt(day, 10))) return true;
      if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', '#00f'];
      return false;
    },
    async getEvents(start, end) {
      const events = [];
      let res = await API.post('schedule/load', {
        username: this.username,
        start: start,
        end: end,
        sessionType: this.free15 ? 'Free15' : 'Standard',
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        return;
      }
      this.hours = res.data.workHours;

      for (let i = 0; i < res.data.schedule.length; i++) {
        let scheduleEntry = res.data.schedule[i];
        const first = new Date(scheduleEntry.start);
        //        const second = new Date(scheduleEntry.end);

        events.push(first.toISOString().substr(0, 10));
      }
      this.schedule = res.data.schedule;
      this.events = events;
    },
    async scheduleAppointment() {
      if (!this.selectedPreferredLocation) {
        this.errorDialog = 'Please select location';
        return;
      }
      this.errorDialog = null;
      this.dialog = false;
      let res = await API.post('appointment/create', {
        professionalUsername: this.username,
        start: this.createEvent.start,
        end: this.createEvent.end,
        location: this.selectedPreferredLocation.location,
        address: this.address,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        return;
      }

      this.createEvent = null;
      await this.updateStartEnd(new Date(this.pickerDate));
      this.dateString = null;
    },
    formatDate(date) {
      return moment(date).format('LLL');
    },
    formatDate2(date) {
      return moment(date).format('LT');
    },
    formatDateForPicker(date) {
      return moment(date).format('YYYY-mm-dd');
    },
    workHourForDate: function(date) {
      //find date
      for (let index = 0; index < this.hours.length; index++) {
        const workhour = this.hours[index];
        if (workhour.date === date && workhour.hidden) {
          return workhour;
        }
      }
      for (let index = 0; index < this.hours.length; index++) {
        const workhour = this.hours[index];
        if (workhour.date === date) {
          return workhour;
        }
      }
      //if no date - find day of week
      let momentValue = moment(date);
      for (let index = 0; index < this.hours.length; index++) {
        const workhour = this.hours[index];
        if (workhour.dayType === 'Mon' && momentValue.day() === 0) {
          return workhour;
        }
        if (workhour.dayType === 'Tue' && momentValue.day() === 1) {
          return workhour;
        }
        if (workhour.dayType === 'Wed' && momentValue.day() === 2) {
          return workhour;
        }
        if (workhour.dayType === 'Thu' && momentValue.day() === 3) {
          return workhour;
        }
        if (workhour.dayType === 'Fri' && momentValue.day() === 4) {
          return workhour;
        }
        if (workhour.dayType === 'Sat' && momentValue.day() === 5) {
          return workhour;
        }
        if (workhour.dayType === 'Sun' && momentValue.day() === 6) {
          return workhour;
        }
      }
      return null;
    },
    allowedDates: function(date) {
      if (new Date(date).getTime() < new Date().getTime()) {
        return false;
      }
      let workHour = this.workHourForDate(date);
      if (!workHour) {
        return false;
      }
      return workHour.work;
    },
    loadAvailableTimes: async function(date) {
      if (date) {
        let res = await API.post('schedule/available', {
          username: this.username,
          date: date,
          sessionType: this.free15 ? 'Free15' : 'Standard',
        });
        if (res.errors && res.errors.length > 0) {
          this.error = res.errors[0].msg;
          return;
        }
        this.checkAndSetAvailable(res.data);
      } else {
        this.available = [];
      }
    },
    checkAndSetAvailable: async function(dates) {
      /*      let newAvailable = [];
      for (let index = 0; index < dates.length; index++) {
        const date = dates[index];
        let found = false;
        for (let index2 = 0; index2 < this.schedule.length; index2++) {
          let scheduleEntry = this.schedule[index2];
          if (
            new Date(scheduleEntry.start).getTime() <
              new Date(date.end).getTime() &&
            new Date(scheduleEntry.end).getTime() >
              new Date(date.start).getTime()
          ) {
            found = true;
          }
        }
        if (!found) {
          newAvailable.push(date);
        }
      }*/
      this.available = dates;
    },
    createAppointment: async function(event) {
      this.createEvent = event;
      this.error = null;
      this.dialog = true;
    },
    cancelAppointment() {
      this.dialog = false;
      this.createEvent = null;
    },
  },
};
</script>
/* eslint-disable no-unused-vars */
