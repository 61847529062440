import axios from 'axios';
import Socket from '@/sockets/socket';
import Vue from 'vue';
import questionsStore from '@/components/member/questionsStore';

function loggedIn() {
  return localStorage.getItem('jwt') ? true : false;
}
function isAdmin() {
  return localStorage.getItem('isAdmin') == 'true' ? true : false;
}
function getUsername() {
  return localStorage.getItem('username');
}
function isProfessional() {
  return localStorage.getItem('isProfessional') == 'true' ? true : false;
}
function jwt() {
  return localStorage.getItem('jwt');
}
function userId() {
  return localStorage.getItem('userId');
}

let loginStore = Vue.observable({
  loggedIn: loggedIn(),
  isAdmin: isAdmin(),
  username: getUsername(),
  isProfessional: isProfessional(),
  userId: userId(),
  jwt: jwt(),

  loginWithData(data) {
    localStorage.setItem('jwt', data.token);
    localStorage.setItem('userId', data.user.id);
    localStorage.setItem('isAdmin', data.user.isAdmin);
    localStorage.setItem('isProfessional', data.user.isProfessional);
    localStorage.setItem('username', data.user.username);
    axios.defaults.headers.common['Authorization'] = data.token;
    Socket.authenticate();
    this.updateThis();
  },
  logout() {
    localStorage.removeItem('jwt'); // if the request fails, remove any possible user token if possible
    localStorage.removeItem('userId'); // if the request fails, remove any possible user token if possible
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('isProfessional');
    localStorage.removeItem('username');
    axios.defaults.headers.common['Authorization'] = null;
    Socket.authenticate();
    this.updateThis();
  },
  updateThis() {
    this.loggedIn = loggedIn();
    this.isAdmin = isAdmin();
    this.username = getUsername();
    this.userId = userId();
    this.jwt = jwt();
    this.isProfessional = isProfessional();
    questionsStore.created();
  },
});

export default loginStore;
