/* eslint-disable no-debugger */

export class ApprovedTagsExtractor {
  constructor(approvedTags, tags) {
    let approvedTagsList = approvedTags.split(', ');
    let appovedKeys = {};
    for (let index = 0; index < approvedTagsList.length; index++) {
      const element = approvedTagsList[index];
      appovedKeys[element] = true;
    }
    this.appovedKeys = appovedKeys;
    this.tags = tags;
  }

  approvedTagsByCategory(categoryToSearchFor, max) {
    let res = [];
    for (let index = 0; index < this.tags.length; index++) {
      let tag = this.tags[index];
      if (this.appovedKeys[tag.text] && tag.id != categoryToSearchFor.tagId) {
        for (let index2 = 0; index2 < tag.TagCategories.length; index2++) {
          const category = tag.TagCategories[index2];
          if (category.categoryId === categoryToSearchFor.id) {
            res.push(tag.text);
            continue;
          }
        }
      }
    }
    if (max) {
      if (res.length > 3) {
        return res.splice(0, 3).join(', ') + '..';
      }
    }
    return res.join(', ');
  }
}
