<template>
  <div style="display:flex;flex-direction:column;width:100%">
    <div style="display:flex;align-items:center;justify-content:space-between">
      <div>
        <v-checkbox
          style="text-transform: uppercase"
          :ripple="false"
          v-model="selected"
          :label="category.name"
        ></v-checkbox>
      </div>
      <!--div v-if="paid">
        <div class="greenText" v-if="paid">Valid till {{ validTill }}</div>
      </div>
      <div v-else>
        <div v-if="expired">
          <div class="red--text">Expired</div>
        </div>
        <div v-else>
          <div class="red--text">Not paid</div>
        </div>
      </div-->
    </div>
    <div style="width:100%" v-if="selected">
      <v-text-field
        :placeholder="exampleForCategory(category.id)"
        v-model="categoryTitle"
        :error-messages="titleErrorMessages"
      />
      <v-text-field
        :placeholder="exampleSpecialiseForCategory(category.id)"
        v-model="specialization"
      />
      <v-autocomplete
        :error-messages="goalErrorMessages"
        v-model="selectedGoalTags"
        :items="goalTags"
        chips
        item-text="text"
        item-value="id"
        multiple
        placeholder="Which goals do you help people achieve?"
        required
      >
        <template v-slot:append>
          <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
        </template>
        <template v-slot:selection="data">
          <v-chip
            color="#428751"
            outlined
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="removeTag(data.item)"
          >
            {{ data.item.text }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title v-html="data.item.text"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-model="selectedTags"
        :items="tags"
        chips
        item-text="text"
        item-value="id"
        multiple
        placeholder="Select all that apply."
      >
        <template v-slot:append>
          <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
        </template>
        <template v-slot:selection="data">
          <v-chip
            color="#428751"
            outlined
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="removeTag(data.item)"
          >
            {{ data.item.text }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title v-html="data.item.text"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-text-field
        placeholder="Please briefly describe a typical appointment."
        v-model="categoryDescription"
      />
    </div>
    <v-list-item-action width="100"> </v-list-item-action>
  </div>
</template>
<script>
/* eslint-disable no-debugger */
import API from '../../api';

/* eslint-disable-next-line  */

export default {
  name: 'Subscribe',

  props: {
    oldSelectedTags: {
      type: Array,
    },
    oldSelectedGoalTags: {
      type: Array,
    },
    category: {
      type: Object,
    },
    paid: {
      type: Boolean,
    },
    expired: {
      type: Boolean,
    },
    validTill: {
      type: String,
    },
    tags: {
      type: Array,
    },
    goalTags: {
      type: Array,
    },
    goals: {
      type: Array,
    },
  },
  data: () => ({
    //    tags: [],
    selected: false,
    specialization: '',
    selectedTags: [],
    selectedGoalTags: [],
    error: null,
    categoryTitle: '',
    categoryDescription: '',
    valid: true,
  }),
  async created() {
    if (this.category) {
      this.selected = this.category.selected && this.paid;
      this.specialization = this.category.specialization;
      this.categoryTitle = this.category.categoryTitle;
      this.categoryDescription = this.category.categoryDescription;
      this.selectedTags = this.oldSelectedTags;
      this.selectedGoalTags = this.oldSelectedGoalTags;
    }
  },
  computed: {
    goalErrorMessages() {
      if (this.selectedGoalTags.length) {
        return [];
      } else {
        return ['Goal required'];
      }
    },
    titleErrorMessages() {
      if (this.categoryTitle.length) {
        return [];
      } else {
        return ['Title required'];
      }
    },
  },
  watch: {
    selected() {
      if (!this.paid) {
        this.$nextTick(() => {
          this.selected = false;
          this.subscribe();
        });
      } else {
        this.updateParent();
      }
    },
    selectedTags() {
      this.updateParent();
    },
    selectedGoalTags() {
      this.updateParent();
    },
    specialization() {
      this.updateParent();
    },
    categoryTitle() {
      this.updateParent();
    },
    categoryDescription() {
      this.updateParent();
    },
  },

  methods: {
    removeTag(item) {
      const index = this.selectedTags.indexOf(item.id);
      if (index >= 0) this.selectedTags.splice(index, 1);
      const index2 = this.selectedGoalTags.indexOf(item.id);
      if (index2 >= 0) this.selectedGoalTags.splice(index, 1);
    },
    async querySelections(val) {
      this.loading = true;
      // Simulated ajax query
      try {
        let res = await API.get('zipcode/search?text=' + encodeURI(val));
        if (!res.errors) {
          this.items = [];
          for (let index = 0; index < res.data.cities.length; index++) {
            const element = res.data.cities[index];
            this.items.push({ name: element.city, type: 'city' });
          }
          for (let index = 0; index < res.data.zipcodes.length; index++) {
            const element = res.data.zipcodes[index];
            this.items.push({ name: element.zipcode, type: 'zipcode' });
          }
        }
      } finally {
        this.loading = false;
      }
    },
    subscribe() {
      this.$emit('subscribe', {});
    },
    updateParent() {
      if (!this.selected) {
        this.$emit('updateParent', {
          id: this.category.id,
          selected: this.selected,
          selectedGoalTags: this.selectedGoalTags,
          selectedTags: this.selectedTags,
          specialization: this.specialization,
          categoryTitle: this.categoryTitle,
          categoryDescription: this.categoryDescription,
        });
      } else {
        if (this.selectedGoalTags.length > 0 && this.categoryTitle.length > 0) {
          this.$emit('updateParent', {
            id: this.category.id,
            selected: this.selected,
            selectedGoalTags: this.selectedGoalTags,
            selectedTags: this.selectedTags,
            specialization: this.specialization,
            categoryTitle: this.categoryTitle,
            categoryDescription: this.categoryDescription,
          });
        } else {
          this.$emit('updateParent', {
            id: this.category.id,
            error: true,
          });
        }
      }
    },
    exampleForCategory(categoryId) {
      if (categoryId === 2) {
        return 'Please add your profession.   Example: Massage Therapist';
      } else if (categoryId === 1) {
        return 'Please add your profession.   Example: Personal Chef';
      } else if (categoryId === 3) {
        return 'Please add your profession.   Example: Mental Health Counselor';
      } else {
        return 'Please add your profession.   Example: Personal Financial Consultant';
      }
    },
    exampleSpecialiseForCategory(categoryId) {
      if (categoryId === 2) {
        return 'What Do You Specialise In?   Example: Holistic Approach';
      } else if (categoryId === 1) {
        return 'What Do You Specialise In?   Example: Autoimmune';
      } else if (categoryId === 3) {
        return 'What Do You Specialise In?   Example: Processes of Change';
      } else {
        return 'What Do You Specialise In?   Example: Special Needs Trust';
      }
    },
  },
};
</script>

<style>
.v-list__group__header:hover {
  background-color: blue !important;
}
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.custom-placeholer-color input::placeholder {
  color: #428751 !important;
  opacity: 1;
}

.custom-label-color .v-label {
  color: #428751ed;
  opacity: 1;
}

.custom-placeholer-color input,
.custom-label-color input {
  color: #428751 !important;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 25px !important;
}
</style>
