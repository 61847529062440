<template>
  <iframe
    src="https://wholelifegoals.com/about-wlg/"
    style="border: 0; width: 100%; height: 100vh; margin-top: -86px;"
    >Your browser doesn't support iFrames.</iframe
  >
</template>

<script>
/* eslint-disable no-debugger */
/* margin-top: -86px;*/

export default {
  name: 'Home',
  props: {},

  data: () => ({
    content: '',
    error: null,
  }),
  mounted() {
    document.title = 'About';
  },
  created: async function() {},
  methods: {},
  computed: {},
};
</script>

<style>
::-webkit-scrollbar {
  height: 0 !important;
  width: 0 !important;
}
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
</style>
