<template>
  <div>
    <v-card class="mx-auto">
      <v-card-text>
        <v-btn color="success" class="mr-4" @click="createSubsctiptionType()">
          Create
        </v-btn>
        <v-list three-line>
          <template v-for="item in info.stripeSubscriptionTypes">
            <v-list-item
              :key="item.id"
              :to="'/admin/subscription-type/' + item.id"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-html="item.text + (item.hidden ? ' (HIDDEN)' : '')"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="'$' + item.price"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-html="item.duration + ' Days'"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// Import Vue FilePond

/* eslint-disable no-debugger */

import API from '../../api';

/* eslint-disable-next-line  */

export default {
  name: 'Profile',
  props: {},

  data: () => ({
    error: null,
    info: [],
    tags: {},
    categories: {},
    tab: '',
  }),
  //LOAD
  created: async function() {
    this.error = null;
    let res = await API.get('admin/info');
    if (!res) {
      return;
    }

    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      this.info = res.data.info;
      for (let index = 0; index < res.data.info.tags.length; index++) {
        const tag = res.data.info.tags[index];
        this.tags[tag.id] = tag;
      }
      for (let index = 0; index < res.data.info.categories.length; index++) {
        const category = res.data.info.categories[index];
        this.categories[category.id] = category;
      }
    }
  },
  computed: {},
  methods: {
    tagCategoriesText(tag) {
      let res = tag.TagCategories;
      if (!res) {
        return '';
      }

      res = res.map((category) =>
        this.categories[category.categoryId]
          ? this.categories[category.categoryId].name
          : ''
      );
      return res.join(', ');
    },
    answerTagsText(question) {
      let res = question.tag;
      if (!res) {
        return '';
      }

      res = res.map((tag) => (this.tags[tag] ? this.tags[tag].text : ''));
      return res.join(', ');
    },
    createSubsctiptionType() {
      this.$router.push('/admin/subscription-type/new');
    },
  },
};
</script>

<style></style>
