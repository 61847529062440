<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab href="#professionals">
        Professionals
      </v-tab>
      <v-tab href="#members">
        Members
      </v-tab>
    </v-tabs>
    <br />
    <div style="display: flex;align-items:CENTER">
      <div>
        <v-text-field
          style="width:300px;"
          hide-details
          placeholder="Search"
          clearable
          outlined
          v-model="searchText"
          @click:append="search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <div>
        <v-btn class="mr-4 main-btn success-green" text @click="search">
          Search
        </v-btn>
      </div>
    </div>
    <br />

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
          User status:
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="selectedStatus"
            :items="statuses()"
            item-text="name"
            item-value="abbreviation"
            placeholder="Status"
          >
            <template v-slot:append>
              <v-icon color="#428751">mdi-arrow-down-drop-circle</v-icon>
            </template>
          </v-autocomplete>
          <div v-for="file in selectedUser.UserFiles" :key="file.id">
            <div v-if="file.fileTypeId == 3">
              <a :href="file.url" target="_blank">{{ file.fileName }}</a>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 main-btn success-green"
            text
            @click="(dialog = false), changeStatus()"
          >
            Change
          </v-btn>
          <v-btn
            class="mr-4 main-btn"
            outlined
            color="gray"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="actionsDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Actions for user {{ selectedUser.username }}
        </v-card-title>
        <v-card-text>
          <a :href="'/user/' + selectedUser.username">
            Open user profile
          </a>
        </v-card-text>
        <v-card-text>
          <a @click="loginAsUser()">Login as this user</a>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 main-btn"
            outlined
            color="gray"
            text
            @click="actionsDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loginAsUserDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Are you sure?
        </v-card-title>
        <v-card-text>
          You will have to relogin to admin account after that
        </v-card-text>
        <v-card-text>
          <a :href="'/admin/loginAs/' + selectedUser.username"
            >Login as {{ selectedUser.username }}</a
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 main-btn"
            outlined
            color="gray"
            text
            @click="loginAsUserDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="activateCategoryDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Activate Categories:
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-for="category in categories"
            :key="category.id"
            v-model="selectedCategories[category.id]"
            :label="category.name"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 main-btn success-green"
            text
            @click="(activateCategoryDialog = false), activateCategories()"
          >
            Activate
          </v-btn>
          <v-btn
            class="mr-4 main-btn"
            outlined
            color="gray"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="users"
      :options.sync="options"
      :server-items-length="totalUsers"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.status`]="{ item }">
        <a @click="handleClick(item)">
          {{ item.status }}
        </a>
      </template>
      <template v-slot:[`item.username`]="{ item }">
        <a @click="usernameClick(item)">
          {{ item.username }}
        </a>
      </template>
      <template v-slot:[`item.requestedTags`]="{ item }">
        {{ tagsText(item) }}
      </template>
      <template v-slot:[`item.approvedCategories`]="{ item }">
        <a @click="categoriesClick(item)">
          {{
            item.approvedCategories && item.approvedCategories.length
              ? item.approvedCategories
              : '[NONE]'
          }}
        </a>
      </template>
      <template v-slot:[`item.mainPractice`]="{ item }">
        {{ item.other.mainPractice }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
// Import Vue FilePond

/* eslint-disable no-debugger */

import API from '../../api';

/* eslint-disable-next-line  */

export default {
  name: 'admin-users',
  props: {
    questionId: {
      type: [String],
    },
  },

  components: {},

  data: () => ({
    error: null,
    info: {},

    sent: false,
    activateCategoryDialog: false,
    loginAsUserDialog: false,
    actionsDialog: false,
    dialog: false,
    totalUsers: 0,
    users: [],
    categories: [],
    loading: true,
    options: {},
    tab: '',
    selectedStatus: 0,
    selectedUser: {},
    selectedCategories: {},
    searchText: '',
    tagFromId: {},
    categoryFromId: {},
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      {
        text: 'Name',
        align: 'start',
        value: 'name',
      },
      {
        text: 'Username',
        align: 'start',
        value: 'username',
      },
      {
        text: 'Email',
        align: 'start',
        value: 'email',
      },
      {
        text: 'Status',
        align: 'start',
        value: 'status',
      },
      {
        text: 'Requested Tags',
        align: 'start',
        value: 'requestedTags',
      },
      {
        text: 'Categories',
        align: 'start',
        value: 'approvedCategories',
      },
    ],
  }),
  created: async function() {
    this.error = null;
    let res = await API.get('admin/info');
    if (!res) {
      return;
    }
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      this.info = res.data.info;
      for (let index = 0; index < res.data.info.tags.length; index++) {
        const tag = res.data.info.tags[index];
        this.tagFromId[tag.id] = tag;
      }
      for (let index = 0; index < res.data.info.categories.length; index++) {
        const category = res.data.info.categories[index];
        this.categoryFromId[category.id] = category;
      }
    }
  },
  watch: {
    tab: {
      handler(value) {
        this.getDataFromApi(value == 'professionals');
      },
      deep: true,
    },
    options: {
      handler() {
        this.getDataFromApi(this.professionalsShown());
      },
      deep: true,
    },
  },
  mounted() {
    document.title = 'Admin Users';
  },
  methods: {
    tagsText(user) {
      if (!user.other) {
        return '';
      }
      let addedTags = {};
      let res = '';
      if (user.other && user.other.requestedTags) {
        for (let index = 0; index < user.other.requestedTags.length; index++) {
          const tag = user.other.requestedTags[index];
          if (!addedTags[tag]) {
            addedTags[tag] = true;
            if (this.tagFromId[tag]) {
              if (res == '') {
                res = this.tagFromId[tag].text;
              } else {
                res = res + ', ' + this.tagFromId[tag].text;
              }
            }
          }
        }
      }
      return res;
    },
    statuses() {
      let res = [];
      if (this.professionalsShown()) {
        res.push({
          name: 'Pending',
          abbreviation: 'Pending',
        });
      }
      res.push({
        name: 'Approved',
        abbreviation: 'Approved',
      });
      res.push({
        name: 'Rejected',
        abbreviation: 'Rejected',
      });
      return res;
    },
    async search() {
      this.getDataFromApi(this.professionalsShown());
    },
    async getDataFromApi(isProfessional) {
      this.loading = true;

      let data = await API.post('admin/users', {
        isProfessional: isProfessional,
        options: this.options,
        text: this.searchText,
      });
      this.users = data.data.users;
      this.totalUsers = data.data.usersCount;
      this.categories = data.data.categories;
      this.loading = false;
    },
    async handleClick(user) {
      this.selectedUser = user;
      this.selectedStatus = user.status;
      this.dialog = true;
    },
    async categoriesClick(user) {
      this.selectedUser = user;
      this.activateCategoryDialog = true;
    },
    async usernameClick(user) {
      this.selectedUser = user;
      this.actionsDialog = true;
    },
    professionalsShown() {
      return this.tab == 'professionals';
    },
    async changeStatus() {
      await API.post('admin/user/setStatus', {
        userId: this.selectedUser.id,
        status: this.selectedStatus,
      });
      this.getDataFromApi(this.professionalsShown());
    },
    async activateCategories() {
      let categoryIds = [];
      let keys = Object.keys(this.selectedCategories);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        if (this.selectedCategories[key]) {
          categoryIds.push(key);
        }
      }
      let validTill = new Date(new Date().getTime() + 365 * 3600 * 24 * 1000);
      await API.post('admin/user/activateCategories', {
        userId: this.selectedUser.id,
        categoryIds: categoryIds,
        validTill,
      });
      this.getDataFromApi(this.professionalsShown());
    },
    loginAsUser() {
      this.actionsDialog = false;
      this.loginAsUserDialog = true;
    },
  },
  computed: {},
};
</script>

<style></style>
