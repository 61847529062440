<template>
  <v-container>
    <v-row class="main-container">
      <v-col>
        <v-card class="mx-auto" max-width="1000">
          <v-card-title>Reviews</v-card-title>

          <v-list two-line>
            <template v-for="item in reviews">
              <v-list-item :key="item.username">
                <v-badge
                  color="red"
                  :content="
                    chatNotification[item.chatId]
                      ? chatNotification[item.chatId].notificationCount
                      : 0
                  "
                  :value="
                    chatNotification[item.chatId]
                      ? chatNotification[item.chatId].notificationCount
                      : 0
                  "
                >
                  <v-list-item-avatar>
                    <v-icon v-if="!item.profileImageUrl">
                      mdi-account-circle
                    </v-icon>
                    <v-img
                      v-if="item.profileImageUrl"
                      :src="item.profileImageUrl"
                    >
                    </v-img>
                  </v-list-item-avatar>
                </v-badge>

                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                  <v-list-item-subtitle>{{
                    formatted(item.start) + '-' + formatted2(item.end)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.status == 'Cancelled' ? 'Canceled' : item.status }}
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
          <v-alert v-if="error" type="error">
            {{ error }}
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable no-debugger */

import API from '../../api';
import moment from 'moment';
import loginStore from '@/util/loginStore';

import notificationStore from '@/sockets/notificationStore';

export default {
  name: 'chats-list',
  props: {
    username: {
      type: [String],
    },
  },
  data: () => ({
    chats: [],
    error: null,
    tab: 'Scheduled',
    dialog: false,
    selectedChat: null,
    chatNotification: {},
  }),
  created: function() {
    this.loadChats(this.tab);
  },
  mounted() {
    document.title = 'Reviews: ' + this.username;
  },
  watch: {
    tab: {
      handler(value) {
        this.loadChats(value);
      },
      deep: true,
      immediate: true,
    },
    grouped: {
      handler(notifications) {
        let chatNotification = {};
        for (let index = 0; index < notifications.length; index++) {
          const notification = notifications[index];
          if (notification.notificationType == 'chat') {
            chatNotification[notification.parentObjectId] = notification;
          }
        }
        this.chatNotification = chatNotification;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    grouped() {
      return notificationStore.grouped;
    },
  },
  methods: {
    async loadChats() {
      let res = await API.get('chats');
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        return;
      }
      this.chats = res.data;
    },
    formatDate(date) {
      return moment(date).format('LLL');
    },
    formatted(date) {
      return moment(date).format('LLL');
    },
    formatted2(date) {
      return moment(date).format('LT');
    },
    edit(chat, event) {
      this.selectedChat = chat;
      this.dialog = true;
      // теперь у нас есть доступ к нативному событию
      if (event) {
        event.preventDefault();
      }
    },
    getUsername() {
      return loginStore.username;
    },
  },
};
</script>
/* eslint-disable no-unused-vars */
