<template>
  <v-card>
    <v-card-title class="headline justify-center">
      Rate &amp; Review
    </v-card-title>

    <v-card-text>
      <div class="greenText">Please click on hands to rate:</div>
      <v-btn outlined>
        <v-rating v-model="rating" outlined>
          <template v-slot:item="props">
            <v-icon @click="props.click">
              {{
                props.isFilled
                  ? '$vuetify.icons.prayer'
                  : '$vuetify.icons.prayerGrayscalled'
              }}
            </v-icon>
          </template>
        </v-rating>
      </v-btn>
      <div class="greenText">Please leave a review in a box below.</div>
      <div style="padding-bottom:10px">
        <v-textarea v-model="text" auto-grow outlined hide-details></v-textarea>
      </div>
      <v-alert v-if="errorDialog" type="error">
        {{ errorDialog }}
      </v-alert>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-4 main-btn success-green" text @click="saveReview()">
          Save
        </v-btn>
        <v-btn
          class="mr-4 main-btn"
          outlined
          color="gray"
          text
          @click="cancelReview()"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<script>
/* eslint-disable no-debugger */

import API from '../../api';

export default {
  name: 'Profile',
  props: {
    review: {
      type: [Object],
    },
    appointmentUuid: {
      type: [String],
    },
  },
  components: {},

  data: () => ({
    text: '',
    errorDialog: '',
    rating: 0,
  }),
  created: async function() {
    if (this.review && this.review.rating) {
      this.rating = this.review.rating;
      this.text = this.review.text;
    }
  },
  methods: {
    async saveReview() {
      let res = await API.post('review', {
        text: this.text,
        rating: this.rating,
        appointmentUuid: this.appointmentUuid,
      });
      if (res.errors && res.errors.length > 0) {
        this.errorDialog = res.errors[0].msg;
        this.sent = false;
      } else {
        this.errorDialog = null;
        this.sent = true;
        this.$emit('close');
      }
    },
    async cancelReview() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.avatarMember {
  width: 140px !important;
  height: 140px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
h1 {
  font-size: 15px;
  font-weight: 600;
  border-bottom: solid 2px #eee;
  padding-bottom: 4px;
  line-height: 22px;
}
</style>
