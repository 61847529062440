<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <h2 class="title mb-2">
              List of all tags
            </h2>
          </v-card-text>
          <v-list subheader two-line>
            <v-list-item v-for="(tag, index) in tags" :key="index">
              <v-list-item-content>
                <v-list-item-title v-text="tag.text"></v-list-item-title>

                <v-list-item-subtitle
                  v-text="tag.priority"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */

import API from '../api';

export default {
  name: 'Tags',

  data: () => ({
    selectedAnswers: {},
    tags: [],
  }),
  created: async function() {
    let res = await API.get('tags');
    if (res.data) {
      this.tags = res.data.tags;
    }
  },
  methods: {},
};
</script>
<style></style>
