<template class="main-container">
  <v-container>
    <v-card class="mx-auto" max-width="1000">
      <v-container>
        <v-row class="main-container">
          <v-col cols="12">
            <v-card flat>
              <v-img
                v-if="profile.isProfessional"
                style="height: 324px"
                :src="
                  profile.backgroundImageUrl
                    ? profile.backgroundImageUrl
                    : '/cover.jpg'
                "
              >
                <v-card-title class="white--text mt-8"> </v-card-title>
              </v-img>
              <v-row align="center" class="spacer" no-gutters>
                <v-col xs="12" sm="4" md="3" cols="2">
                  <v-avatar
                    :class="profile.isProfessional ? 'avatar' : 'avatarMember'"
                    size="140"
                  >
                    <img
                      :src="
                        profile.profileImageUrl
                          ? profile.profileImageUrl
                          : '/profile-picture.png'
                      "
                    />
                  </v-avatar>
                </v-col>
                <v-col sm="6" md="6" cols="6" class="hidden-xs-only">
                  <h2 class="ml-3">
                    {{ formatName(profile.name) }}

                    <v-rating
                      v-if="rating"
                      dense
                      v-model="rating"
                      readonly
                      half-increments
                      empty-icon="$vuetify.icons.prayerGrayscalled"
                      full-icon="$vuetify.icons.prayer"
                      half-icon="$vuetify.icons.prayerHalfGrayscalled"
                    >
                    </v-rating>

                    <br />
                    {{
                      profile.other && profile.other.pni
                        ? profile.other.pni + ' '
                        : ''
                    }}
                  </h2>
                  <p class="ml-3">
                    {{ profile.description }}
                  </p>
                </v-col>
              </v-row>
              <v-row align="center" class="spacer" no-gutters>
                <v-col cols="9" class="hidden-sm-and-up">
                  <h2 class="ml-3">
                    {{ formatName(profile.name) }}
                    <v-rating
                      v-if="rating"
                      dense
                      v-model="rating"
                      readonly
                      half-increments
                      empty-icon="$vuetify.icons.prayerGrayscalled"
                      full-icon="$vuetify.icons.prayer"
                      half-icon="$vuetify.icons.prayerHalfGrayscalled"
                    >
                    </v-rating>

                    {{
                      profile.other && profile.other.pni
                        ? profile.other.pni + ' '
                        : ''
                    }}
                  </h2>
                  <p class="ml-3">
                    {{ profile.description }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="isMe()"
                      class="mr-4 main-btn success-green"
                      :to="
                        isProfessional() ? '/profile/edit' : '/profile/member'
                      "
                    >
                      Edit Profile
                    </v-btn>
                    <v-btn
                      v-if="!isMe() && profile.isProfessional"
                      class="mr-4 main-btn success-green"
                      :to="
                        loggedIn
                          ? '/appointment/create/' + username
                          : '/register/member?nextUrl=' +
                            encodeURI('/appointment/create/' + username)
                      "
                    >
                      Schedule
                    </v-btn>

                    <v-btn
                      v-if="!isMe()"
                      class="mr-4 main-btn success-green"
                      :to="
                        loggedIn
                          ? '/chat/' + username
                          : '/register/member?nextUrl=' +
                            encodeURI('/chat/' + username)
                      "
                    >
                      Message
                    </v-btn>
                    <v-spacer v-if="!isMe()"></v-spacer>
                  </v-card-actions>
                </v-col>
              </v-row>
              <v-row class="pa-4">
                <v-col
                  v-for="(n, index) in images"
                  :key="n.uuid"
                  class="d-flex child-flex"
                  cols="3"
                >
                  <v-img
                    :src="n.thumbnailUrl ? n.thumbnailUrl : n.url"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    @click="carusel(index, images)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <v-row v-if="products && products.length > 0">
                <v-col>
                  <v-card flat>
                    <v-card-text><h1>Products</h1></v-card-text></v-card
                  >
                  <div
                    style="width:100%; display:flex; justify-content: left;flex-wrap:wrap"
                  >
                    <ViewProduct
                      v-for="product in products"
                      :product="product"
                      :key="product.uuid"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="files && files.length > 0">
                <v-col>
                  <v-card flat>
                    <v-card-text
                      ><h1>Proof of Certification</h1></v-card-text
                    ></v-card
                  ></v-col
                >
              </v-row>
              <v-row class="pl-4 pr-4 pb-4">
                <v-col
                  v-for="(n, index) in files"
                  :key="n.uuid"
                  class="d-flex child-flex"
                  cols="3"
                >
                  <v-img
                    :src="n.thumbnailUrl ? n.thumbnailUrl : n.url"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    @click="carusel(index, files)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <v-dialog v-model="photoDialog">
                <v-card>
                  <v-row>
                    <v-col class="d-flex child-flex">
                      <v-carousel v-model="carouselIndex" hide-delimiters>
                        <v-carousel-item
                          v-for="(n, index) in carouselArray"
                          :key="n.uuid"
                          v-html="
                            carouselIndex == index && photoDialog ? html(n) : ''
                          "
                        ></v-carousel-item>
                      </v-carousel> </v-col
                  ></v-row>
                </v-card>
              </v-dialog>
              <!--v-card class="mx-auto" max-width="344" outlined>
            {{ link.image }}
            <v-img :src="link.image" height="200px"></v-img>

            <v-card-title>
              {{ link.title }}
            </v-card-title>

            <v-card-subtitle>
              {{ link.description }}
            </v-card-subtitle>
            <v-card-actions>
              <v-btn outlined rounded text>
                Open
              </v-btn>
            </v-card-actions>
          </v-card-->
            </v-card>
          </v-col>
          <v-col cols="12" v-if="profile.other && profile.other.myStory">
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>My Story</h1>
                  <br />
                  <div>{{ profile.other.myStory }}</div>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card flat>
              <v-card-text>
                <div v-if="profile.isProfessional">
                  <div
                    v-for="userCategory in profile.UserCategories"
                    :key="userCategory.id"
                  >
                    <div>
                      <h1 class="greenText" style="text-transform: uppercase">
                        <b>
                          {{
                            categoryById[userCategory.categoryId]
                              ? categoryById[userCategory.categoryId].name
                              : ''
                          }}</b
                        >
                      </h1>
                      <div v-if="profile.other && profile.other.categoryTitle">
                        <br />
                        {{
                          profile.other.categoryTitle[userCategory.categoryId]
                        }}
                      </div>
                      <br />
                      <br />
                    </div>
                    <div>
                      <h1>
                        Specialities<span
                          v-if="profile.other && profile.other.specialization"
                          >:
                          {{
                            profile.other.specialization[
                              userCategory.categoryId
                            ]
                          }}
                        </span>
                      </h1>
                      <br />
                      <div
                        class="greenText"
                        v-if="profile.other && profile.other.selectedGoalTags"
                      >
                        {{
                          profile.other.selectedGoalTags[
                            userCategory.categoryId
                          ]
                        }}
                      </div>
                      <br />
                      <br />
                    </div>
                    <div
                      v-if="
                        profile.other &&
                          profile.other.categoryDescription &&
                          profile.other.categoryDescription[
                            userCategory.categoryId
                          ]
                      "
                    >
                      <h1>Explanation of services</h1>
                      <br />
                      <div>
                        {{
                          profile.other.categoryDescription[
                            userCategory.categoryId
                          ]
                        }}
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-if="profile.other && profile.other.myWebsite"
          >
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>My Website</h1>
                  <br />
                  <div>
                    <a :href="profile.other.myWebsite">{{
                      profile.other.myWebsite
                    }}</a>
                  </div>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="profile.other && profile.other.instagramLink"
          >
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>My Instagram Link</h1>
                  <br />
                  <a :href="profile.other.instagramLink">{{
                    profile.other.instagramLink
                  }}</a>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="profile.other && profile.other.facebookLink"
          >
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>My Facebook Link</h1>
                  <br />
                  <a :href="profile.other.facebookLink">{{
                    profile.other.facebookLink
                  }}</a>

                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="profile.other && profile.other.pinterestLink"
          >
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>My Pinterest Link</h1>
                  <br />

                  <a :href="profile.other.pinterestLink">{{
                    profile.other.pinterestLink
                  }}</a>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="profile.other && profile.other.tikTokLink"
          >
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>My TikTok Link</h1>
                  <br />
                  <a :href="profile.other.tikTokLink">{{
                    profile.other.tikTokLink
                  }}</a>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="profile.other && profile.other.myWorkHours"
          >
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>My Work Hours</h1>
                  <br />
                  <div>{{ profile.other.myWorkHours }}</div>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="profile.country && profile.country != ''"
          >
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>Country</h1>
                  <br />
                  <div>{{ countryName() }}</div>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="
              profile.country &&
                profile.country == 'US' &&
                profile.state &&
                profile.state != ''
            "
          >
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>State</h1>
                  <br />
                  <div>{{ stateName() }}</div>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="profile.zipcode && profile.zipcode != ''"
          >
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>Zipcode</h1>
                  <br />
                  <div>{{ profile.zipcode }}</div>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" v-if="profile.other">
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>Insurance Accepted?</h1>
                  <br />
                  <div>
                    {{ profile.insuranceAccepted == 'Yes' ? 'Yes' : 'No' }}
                  </div>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="profile.other && profile.insuranceAccepted == 'Yes'"
          >
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>Insurance Carriers</h1>
                  <br />
                  <div>{{ profile.other.insuranceCarriers }}</div>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="
              profile.preferredHome ||
                profile.preferredClient ||
                profile.preferredOnline
            "
          >
            <v-card flat>
              <v-card-text>
                <div md="3">
                  <h1>Preferred Location</h1>
                  <br />
                  <div>{{ preferredLocation() }}</div>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-if="profile.other && profile.other.appointmentDuration"
          >
            <v-card flat>
              <v-card-text>
                <div md="3">
                  <h1>Appointment Duration</h1>
                  <br />
                  <div>
                    {{
                      appoinmentDurationText(profile.other.appointmentDuration)
                    }}
                  </div>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-if="profile.other && profile.other.appointmentRate"
          >
            <v-card flat>
              <v-card-text>
                <div md="3">
                  <h1>Appointment Rate</h1>
                  <br />
                  <div>${{ profile.other.appointmentRate }} per session</div>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-if="
              profile.other &&
                profile.other.typesOfPaymentsAccepted &&
                profile.other.typesOfPaymentsAccepted.length > 0
            "
          >
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>Types of Payment Accepted</h1>
                  <br />
                  <div>
                    {{ profile.other.typesOfPaymentsAccepted.join(', ') }}
                  </div>
                  <br />
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" v-if="reviews && reviews.length > 0">
            <v-card flat>
              <v-card-text>
                <div>
                  <h1>Reviews</h1>
                  <br />

                  <div v-for="review in reviews" :key="'review' + review.id">
                    <div
                      v-if="review.text"
                      style="border-bottom: solid 2px #eee;"
                    >
                      <b>{{ review.User.name }}</b>
                      <v-rating
                        dense
                        v-model="review.rating"
                        readonly
                        half-increments
                        empty-icon="$vuetify.icons.prayerGrayscalled"
                        full-icon="$vuetify.icons.prayer"
                        half-icon="$vuetify.icons.prayerHalfGrayscalled"
                      >
                      </v-rating>
                      {{ review.text }}
                    </div>
                    <br />
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card flat>
              <v-alert v-if="error" type="error">
                {{ error }}
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */
import { parseVideo } from '@/util/video';
import ViewProduct from '../product/ViewProduct';

import API from '../../api';
import statesJSON from '../../../data/states.json';
import countriesJSON from '../../../data/countries.json';
import agesJSON from '../../../data/ages.json';
import { formatName } from '@/util/stringUtils';
import { ApprovedTagsExtractor } from '@/util/approvedTagExtractor';
import appointmentDurationsJSON from '../../../data/appointmentDurations.json'; //appointmentDurations
import loginStore from '@/util/loginStore';

export default {
  name: 'Profile',
  props: {
    username: {
      type: [String],
    },
  },

  data: () => ({
    //    tags: [],
    error: null,
    profile: {},
    images: [],
    files: [],
    reviews: [],
    photoDialog: false,
    carouselIndex: 0,
    link: {},
    rating: 0,
    categoryById: {},
    categories: [],
    approvedTags: [],
    approvedTagsExtractor: null,
    carouselArray: [],
    products: [],
  }),
  components: {
    ViewProduct,
    //Tags,
  },
  created: async function() {
    this.loadData(this.username);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData(to.params.username);
    next();
  },
  mounted() {
    document.title = 'View Profile:  ' + this.username;
  },
  methods: {
    fillCategoryById() {
      for (let index = 0; index < this.categories.length; index++) {
        const category = this.categories[index];
        this.categoryById[category.id] = category;
      }
    },
    formatName(name) {
      return formatName(name);
    },
    async loadData(username) {
      let res = await API.get(
        'profile' + (username ? '?username=' + encodeURI(username) : '')
      );
      if (!res) {
        return;
      }
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        this.error = null;
        this.profile = res.data.user;
        this.rating = parseFloat(this.profile.rating);
        this.images = res.data.images;
        this.files = res.data.files;
        this.categories = res.data.categories;
        this.reviews = res.data.reviews;
        this.approvedTagsExtractor = new ApprovedTagsExtractor(
          this.profile.approvedTags,
          res.data.tags
        );
        this.fillCategoryById();
        this.products = res.data.products;
      }
    },
    preferredLocation() {
      let res = null;
      if (this.profile.preferredHome) {
        res = "Professional's Location ";
      }
      if (this.profile.preferredClient) {
        if (res) {
          res = res + ", Member's Location";
        } else {
          res = "Member's Location";
        }
      }
      if (this.profile.preferredOnline) {
        if (res) {
          res = res + ', Web Conferencing';
        } else {
          res = 'Web Conferencing';
        }
      }
      if (this.profile.preferredAgreedUpon) {
        if (res) {
          res = res + ', Agreed Upon Location';
        } else {
          res = 'Agreed Upon Location';
        }
      }
      return res;
    },
    stateName() {
      for (let index = 0; index < statesJSON.length; index++) {
        const state = statesJSON[index];
        if (state.abbreviation == this.profile.state) {
          return state.name;
        }
      }
    },
    countryName() {
      for (let index = 0; index < countriesJSON.length; index++) {
        const country = countriesJSON[index];
        if (country.code == this.profile.country) {
          return country.name;
        }
      }
    },
    ageRangeName() {
      for (let index = 0; index < agesJSON.length; index++) {
        const state = agesJSON[index];
        if (state.abbreviation == this.profile.ageRange) {
          return state.name;
        }
      }
    },
    isProfessional() {
      return loginStore.isProfessional;
    },
    html(image) {
      if (image.storage == 'Youtube' || image.storage == 'Vimeo') {
        let parsed = parseVideo(image.url);
        let videoCode = parsed.id;
        if (image.storage == 'Youtube') {
          return `<div style="height:100%;width:100%">
              <iframe style="height:100%;width:100%" src="https://www.youtube.com/embed/${videoCode}" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>`;
        } else {
          return `<div style="height:100%;width:100%">
              <iframe style="height:100%;width:100%" src="https://player.vimeo.com/video/${videoCode}" allow="fullscreen"></iframe>
            </div>`;
        }
      } else {
        let res =
          '<image style="height:100%;width:auto; display: block;margin-left: auto;margin-right: auto;" src="' +
          (image.thumbnailUrl ? image.thumbnailUrl : image.url) +
          '">';
        return res;
      }
    },
    appoinmentDurationText(duration) {
      for (const appointmentDuration of appointmentDurationsJSON) {
        if (duration == appointmentDuration.abbreviation) {
          return appointmentDuration.name;
        }
      }
      return '';
    },

    carusel(index, array) {
      this.carouselIndex = index;
      this.carouselArray = array;
      this.photoDialog = true;
    },
    isMe() {
      let userId = loginStore.userId;
      return userId && userId == this.profile.id;
    },
    approvedTagsByCategory(category) {
      return this.approvedTagsExtractor.approvedTagsByCategory(category);
    },
  },
  computed: {
    loggedIn() {
      return loginStore.loggedIn;
    },
  },
};
</script>

<style>
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.avatarMember {
  width: 140px !important;
  height: 140px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
h1 {
  font-size: 15px;
  font-weight: 600;
  border-bottom: solid 2px #eee;
  padding-bottom: 4px;
  line-height: 22px;
}
</style>
