<template>
  <div>
    <v-card class="mx-auto">
      <v-card-text>
        <v-btn color="success" class="mr-4" @click="createCategory()">
          Create
        </v-btn>
        <!--v-btn color="success" class="mr-4" @click="createTag()">
          Create
        </v-btn-->
        <v-list three-line>
          <template v-for="item in info.categories">
            <v-list-item :key="item.id" :to="'/admin/category/' + item.id">
              <v-list-item-content>
                <v-list-item-title v-html="item.name"></v-list-item-title>
                <!--v-list-item-subtitle v-html="item.tagId" /!-->
                <v-list-item-subtitle
                  :style="{ backgroundColor: item.color }"
                  >{{ item.color }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// Import Vue FilePond

/* eslint-disable no-debugger */

import API from '../../api';

/* eslint-disable-next-line  */

export default {
  name: 'Profile',
  props: {},

  data: () => ({
    error: null,
    info: {},
    tab: '',
  }),
  //LOAD
  mounted() {
    document.title = 'Admin Categories';
  },
  created: async function() {
    this.error = null;
    let res = await API.get('admin/info');
    if (!res) {
      return;
    }
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      this.info = res.data.info;
    }
  },
  computed: {},
  methods: {
    createCategory() {
      this.$router.push('/admin/category/new');
    },
  },
};
</script>

<style></style>
