<template>
  <v-dialog v-model="dialog" persistent max-width="850">
    <v-card>
      <v-card-title class="pa-6 justify-center">
        <img
          src="/wlg-logo-1024x110.png"
          class="justify-center"
          style="width:70%;"
        />
      </v-card-title>
      <v-card-text class="pa-6 top-text">
        <div class="middle-text">
          Our goal is to create a healthy and wealthy work force of certified
          professionals who can teach everyone in our community to thrive.
        </div>
        <div
          style="color: #828282; font-size: 18px; margin-bottom: 20px; margin-left: 10px"
        >
          Thank you for sharing our goals and vision.
        </div>
        <div
          style="color: #828282; font-size: 20px; font-weight: bold; margin-left: 10px"
        >
          The Whole Life Goals Team
        </div>
        <div
          style="width: 100%; height: 2px; background-color: #828282; margin-top: 20px"
        ></div>
      </v-card-text>

      <div
        class="desktop"
        style="justify-content: center; height: 330px; align-items: center;"
      >
        <div
          class="tarif"
          style="padding: 20px;"
          @click="selectTarif(0)"
          v-bind:class="
            arrSelectTarif == 0 ? 'tarifSelected' : 'tarifUnSelected'
          "
        >
          <div style="font-size: 40px">6</div>
          <div>months</div>
          <div>$15.00/mo</div>
          <div style="color: #fe6900; font-weight: bold">
            SAVE 50%
          </div>
          <div
            style="width: 100%; height: 1px; background-color: #c1c1c1; margin: 53px 20px 15px"
          ></div>
          <div v-bind:class="arrSelectTarif == 0 ? 'priceSelected' : ''">
            $89.99
          </div>
        </div>

        <div
          class="tarif"
          style="padding: 5px 10px;"
          v-bind:class="
            arrSelectTarif == 1 ? 'tarifSelected' : 'tarifUnSelected'
          "
          @click="selectTarif(1)"
        >
          <div
            style="background-color: #659c34; color: #ffffff; text-align: center; border-radius: 10px;
                  width: 100%; padding: 3px; font-weight: bold;"
          >
            BEST VALUE
          </div>
          <div style="font-size: 40px">12</div>
          <div>months</div>
          <div>$10.00/mo</div>
          <div style="color: #fe6900; font-weight: bold">
            SAVE 67%
          </div>
          <div
            v-bind:class="arrSelectTarif == 1 ? '' : 'smallLineUnSelected'"
            class="smallLine"
          ></div>
          <div v-bind:class="arrSelectTarif == 1 ? 'priceSelected' : ''">
            $119.99
          </div>
          <div
            v-bind:class="arrSelectTarif == 1 ? 'lineSelected' : ''"
            class="line"
          ></div>
          <div style="color: #fe6900; font-weight: bold">
            Add Promo Code
          </div>
          <input
            v-model="promo"
            style="width: 120px; border-bottom: 1px solid #000000; outline:none; font-size: 12px; text-align: center;"
          />
        </div>

        <div
          class="tarif"
          style="padding: 20px;"
          v-bind:class="
            arrSelectTarif == 2 ? 'tarifSelected' : 'tarifUnSelected'
          "
          @click="selectTarif(2)"
        >
          <div style="font-size: 40px">1</div>
          <div>month</div>
          <div>$29.99</div>
          <div
            style="width: 100%; height: 1px; background-color: #c1c1c1; margin: 75px 20px 15px"
          ></div>
          <div v-bind:class="arrSelectTarif == 2 ? 'priceSelected' : ''">
            $29.99
          </div>
        </div>
      </div>

      <!--MOBILE-->

      <div class="mobile" style="flex-direction: column;">
        <v-toolbar flat style="height: 45px">
          <template>
            <v-tabs v-model="arrSelectTarif2" centered fixed-tabs>
              <v-tabs-slider color="#ffffff"></v-tabs-slider>
              <v-tab :href="'#0'">
                6 months
              </v-tab>
              <v-tab :href="'#1'">
                12 months
              </v-tab>
              <v-tab :href="'#2'">
                1 month
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="arrSelectTarif2">
          <v-tab-item
            :value="'0'"
            style="display: block; margin: 0 auto; width: 100%;"
          >
            <v-card flat>
              <div style="padding: 0 20px">
                <div
                  class="tarif tarifSelected"
                  style="padding: 20px;"
                  @click="selectTarif(0)"
                >
                  <div style="font-size: 20px">6</div>
                  <div>months</div>
                  <div>$15.00/mo</div>
                  <div style="color: #fe6900; font-weight: bold">
                    SAVE 50%
                  </div>
                  <div class="line-tab1"></div>
                  <div
                    v-bind:class="arrSelectTarif == 0 ? 'priceSelected' : ''"
                  >
                    $89.99
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item
            :value="'1'"
            style="display: block; margin: 0 auto; width: 100%;"
          >
            <v-card flat>
              <div style="padding: 0 20px">
                <div
                  class="tarif tarifSelected"
                  style="padding: 5px 10px;"
                  @click="selectTarif(1)"
                >
                  <div
                    style="background-color: #659c34; color: #ffffff; text-align: center; border-radius: 10px;
                  width: 100%; padding: 3px; font-weight: bold;"
                  >
                    BEST VALUE
                  </div>
                  <div style="font-size: 20px">12</div>
                  <div>months</div>
                  <div>$10.00/mo</div>
                  <div style="color: #fe6900; font-weight: bold">
                    SAVE 67%
                  </div>
                  <div
                    v-bind:class="
                      arrSelectTarif == 1 ? '' : 'smallLineUnSelected'
                    "
                    class="smallLine"
                  ></div>
                  <div
                    v-bind:class="arrSelectTarif == 1 ? 'priceSelected' : ''"
                  >
                    $119.99
                  </div>
                  <div
                    v-bind:class="arrSelectTarif == 1 ? 'lineSelected' : ''"
                    class="line"
                  ></div>
                  <div style="color: #fe6900; font-weight: bold">
                    Add Promo Code
                  </div>
                  <input
                    v-model="promo"
                    style="width: 120px; border-bottom: 1px solid #000000; outline:none; font-size: 12px; text-align: center;"
                  />
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item
            :value="'2'"
            style="display: block; margin: 0 auto; width: 100%;"
          >
            <v-card flat>
              <div style="padding: 0 20px">
                <div
                  class="tarif tarifSelected"
                  style="padding: 20px;"
                  @click="selectTarif(2)"
                >
                  <div style="font-size: 20px">1</div>
                  <div>month</div>
                  <div style="margin-top: 52px;">
                    $29.99
                  </div>
                  <div
                    style="width: 100%; height: 1px; background-color: #c1c1c1; margin: 36px 20px 15px"
                  ></div>
                  <div
                    v-bind:class="arrSelectTarif == 2 ? 'priceSelected' : ''"
                  >
                    $29.99
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <v-card-actions class="pa-4 btn">
        <v-spacer></v-spacer>
        <v-btn class="mr-4 main-btn success-green" text @click="subscribe()">
          Subscribe
        </v-btn>
        <v-btn class="mr-4 main-btn" outlined color="gray" text @click="hide()">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-alert v-if="error" type="error">
        {{ error }}
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-debugger */
import API from '../../api';

/* eslint-disable-next-line  */
var stripe;

function initStripe(stripePublicKey) {
  if (!stripe) {
    stripe = window.Stripe(stripePublicKey);
  }
}

export default {
  name: 'Subscribe',

  props: {
    categoryIds: {
      type: Array,
    },
  },
  data: () => ({
    //    tags: [],
    error: null,
    dialog: true,
    subscriptions: [],
    selectedSubscription: 0,
    arrSelectTarif: 1,
    arrSelectTarif2: '1',
    promo: '',
  }),
  watch: {
    arrSelectTarif2: {
      immediate: true,
      handler(value) {
        this.arrSelectTarif = parseInt(value);
      },
    },
  },
  async created() {
    this.arrSelectTarif = 1;
    let res = await API.get('category/subscriptionTypes');
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      this.subscriptions = res.data.subscriptions;
      initStripe(res.data.stripePublicKey);
    }
  },
  computed: {},
  methods: {
    subscriptionById(id) {
      for (let index = 0; index < this.subscriptions.length; index++) {
        const subscription = this.subscriptions[index];
        if (subscription.id == id) {
          return subscription;
        }
      }
      return {};
    },
    async subscribe() {
      var req = {
        stripeSubscriptionTypeId: this.findSubscriptionId(),
      };
      if (
        this.arrSelectTarif == 1 &&
        this.promo !== null &&
        this.promo !== ''
      ) {
        req.promo = this.promo;
      }
      let res = await API.post('category/pay', req);
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        await stripe.redirectToCheckout({
          sessionId: res.data.sessionId,
        });
        this.dialog = false;
        /*
      if (res.data && res.data.userCategory) {
        this.userCategoryByCategory[category.id] = res.data.userCategory;
        this.userCategoryByCategory = {
          ...this.userCategoryByCategory,
        };
      }*/
      }
    },
    hide() {
      this.dialog = false;
      this.$emit('dialogHidden');
    },
    selectTarif(data) {
      this.arrSelectTarif = data;
    },
    findSubscriptionId() {
      for (let index = 0; index < this.subscriptions.length; index++) {
        const element = this.subscriptions[index];
        if (element.duration < 50 && this.arrSelectTarif == 2) {
          return element.id;
        }
        if (element.duration > 360 && this.arrSelectTarif == 1) {
          return element.id;
        }
        if (
          element.duration >= 50 &&
          element.duration <= 360 &&
          this.arrSelectTarif == 0
        ) {
          return element.id;
        }
      }
      return null;
    },
  },
};
</script>

<style>
.v-list__group__header:hover {
  background-color: blue !important;
}
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.tarif {
  height: 270px;
  width: 180px;
  margin: 0 10px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tarifSelected {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 0.3s;
  margin: 0 25px;
  border: 2px solid #606060;
  color: #000000;
  height: 300px;
  width: 200px;
  margin-top: 40px;
}
.tarifUnSelected {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: 0.3s;
  border: 2px solid #828282;
  color: #919191;
}
.priceSelected {
  font-weight: bold;
  color: #606060;
  transition: 0.3s;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
  margin: 5px;
  transition: 0.3s;
}
.lineSelected {
  margin-top: 13px;
  transition: 0.3s;
}
.smallLine {
  margin: 25px 0 10px;
  width: 90px;
  height: 1px;
  background-color: #c1c1c1;
  transition: 0.3s;
}
.smallLineUnSelected {
  margin-top: 5px;
  transition: 0.3s;
}
.line-tab1 {
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
  margin: 53px 20px 15px;
}
.top-text {
  padding-bottom: 5px !important;
}
.middle-text {
  color: #828282;
  font-size: 18px;
  margin-bottom: 50px;
}
.btn {
  margin-top: 30px;
}
.desktop {
  display: flex;
}
.mobile {
  display: none;
}
@media (max-width: 992px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .tarif {
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
  }
  .tarifSelected {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    transition: none;
    height: 250px;
  }
  .tarifUnSelected {
    transition: none;
  }
  .priceSelected {
    transition: none;
  }
  .line {
    transition: none;
  }
  .lineSelected {
    transition: none;
  }
  .smallLine {
    transition: none;
  }
  .smallLineUnSelected {
    transition: none;
  }
  .line-tab1 {
    margin: 64px 20px 15px;
  }
  .lineSelected {
    margin-top: 15px;
  }
  .smallLine {
    margin: 0;
    margin-top: 12px;
  }
  .top-text {
    padding-top: 0px !important;
  }
  .middle-text {
    margin-bottom: 20px;
  }
  .btn {
    margin-top: 8px;
  }
  .v-tab {
    padding: 0 5px;
  }
}
</style>
