<template>
  <v-container>
    <v-row class="main-container">
      <v-col>
        <v-card-text>
          <h2
            class="title mb-2"
            style="font-size: 24px !important; font-weight: 700;"
          >
            Edit {{ isProfessional ? 'Professional' : 'Member' }} Account
          </h2>

          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="name"
              :rules="nameRules"
              placeholder="Name"
              required
            ></v-text-field>

            <v-text-field
              v-model="username"
              :rules="usernameRules"
              placeholder="Username"
              required
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="emailRules"
              placeholder="E-mail"
              required
            ></v-text-field>
            <v-card flat>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  v-if="!sent"
                  class="mr-4 main-btn success-green"
                  @click="register"
                >
                  Save
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-card-text>
        <v-alert v-if="error" type="error">
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */

import API from '../../api';
import loginStore from '@/util/loginStore';

export default {
  name: 'EditAccount',
  props: {
    registrationType: {
      type: [String],
    },
  },
  mounted() {
    document.title = 'Edit Account';
  },
  data: () => ({
    //    tags: [],
    sent: false,
    error: null,
    valid: false,
    name: '',
    nameRules: [(v) => !!v || 'Name is required'],
    username: '',
    usernameRules: [
      (v) => !!v || 'Username is required',
      (v) => /^[a-zA-Z0-9/._]+$/.test(v) || 'Username must be valid',
    ],
    email: '',
    isProfessional: false,
    questions: [],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
  }),
  methods: {
    async register() {
      let res = await API.post('edit-account', {
        email: this.email,
        username: this.username,

        name: this.name,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        this.sent = false;
      } else {
        loginStore.loginWithData(res.data);
        this.error = null;
        this.$emit('nextSection');
      }
    },
  },
  async created() {
    this.error = null;
    let res = await API.get('profile');
    if (!res) {
      return;
    }
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      this.isProfessional = res.data.user.isProfessional;
      this.username = res.data.user.username;
      this.email = res.data.user.email;
      this.name = res.data.user.name;
    }
  },
};
</script>
<style>
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
</style>
